import React, { useState } from 'react';
import { Box, IconButton, Typography, TextField, Button, Alert, Modal } from '@mui/material';
import { Close as CloseIcon } from '@mui/icons-material';
import VigenciaGrupoClienteTable from './VigenciaGrupoClienteTable';

const GrupoClienteModal = ({ open, onClose, modalType, grupoForm, setGrupoForm, onSubmit, errorMessage }) => {
  const [vigencias, setVigencias] = useState([]);

  return (
    <Modal open={open} onClose={onClose}>
      <Box sx={{
        padding: 4,
        backgroundColor: 'white',
        margin: 'auto',
        width: '60%',
        marginTop: '5%',
        maxHeight: '90vh',
        overflowY: 'auto',
        position: 'relative',
      }}>
        <IconButton sx={{ position: 'absolute', top: 8, right: 8 }} onClick={onClose}>
          <CloseIcon />
        </IconButton>

        <Typography variant="h6" sx={{ mb: 2 }}>
          {modalType === 'add' ? 'Adicionar Grupo de Cliente' : 'Editar Grupo de Cliente'}
        </Typography>

        {errorMessage && <Alert severity="error" sx={{ mb: 2 }}>{errorMessage}</Alert>}

        <TextField
          label="Nome do Grupo"
          fullWidth
          value={grupoForm.nome}
          onChange={(e) => setGrupoForm({ ...grupoForm, nome: e.target.value })}
          sx={{ mb: 2 }}
        />

        <Typography variant="h6" sx={{ mt: 3, mb: 1 }}>
          Vigência Grupo Cliente
        </Typography>

        <VigenciaGrupoClienteTable vigencias={vigencias} setVigencias={setVigencias} />

        <Button variant="contained" color="primary" sx={{ mt: 3 }} onClick={onSubmit}>
          {modalType === 'add' ? 'Adicionar' : 'Salvar'}
        </Button>
      </Box>
    </Modal>
  );
};

export default GrupoClienteModal;
