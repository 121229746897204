import React, { useState, useEffect } from 'react';
import { AppBar, Toolbar, IconButton, Box, Typography, Avatar, Badge, Menu, MenuItem, Divider, Modal, Button } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import NotificationsIcon from '@mui/icons-material/Notifications';
import ChatIcon from '@mui/icons-material/Chat';
import SupportIcon from '@mui/icons-material/HeadsetMic';
import logo from './bg_logo.png';
import { useNavigate } from 'react-router-dom';

const Header = ({ toggleSidebar }) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [userName, setUserName] = useState('');
  const [userRole, setUserRole] = useState('');
  const [isLogoutModalOpen, setLogoutModalOpen] = useState(false);
  const open = Boolean(anchorEl);
  const navigate = useNavigate();
  const [sessionTimeLeft, setSessionTimeLeft] = useState(null);



  useEffect(() => {
    const fetchTokenExpiration = async () => {
      try {
        const response = await fetch('/api/rota-protegida', {
          method: 'GET',
          headers: {
            'Authorization': `Bearer ${localStorage.getItem('token')}`,
          },
        });
  
        const tokenExpiration = response.headers.get('X-Token-Expiration');
  
        if (tokenExpiration) {
          localStorage.setItem('expiresAt', tokenExpiration);
          const timeLeft = parseInt(tokenExpiration, 10) - Date.now();
          setSessionTimeLeft(timeLeft > 0 ? timeLeft : 0);
        }
      } catch (error) {
        console.error('Erro ao buscar expiração do token:', error);
        setSessionTimeLeft(0);
      }
    };
  
    fetchTokenExpiration();
  
    const interval = setInterval(fetchTokenExpiration, 30000); // Atualiza a cada 30s
  
    return () => clearInterval(interval);
  }, []);

  useEffect(() => {
    const usuario = JSON.parse(localStorage.getItem('usuario')); // Recupera os dados do usuário do localStorage
    if (usuario) {
      setUserName(usuario.nome); // Define o nome do usuário no estado
      setUserRole(usuario.tipo_usuario); // Define o tipo de usuário no estado
    }
  }, []);
  
  useEffect(() => {
    const fetchUserData = async () => {
      try {
        const response = await fetch('/api/user', {
          method: 'GET',
          headers: {
            'Authorization': `Bearer ${localStorage.getItem('token')}`,
          },
        });
  
        if (response.ok) {
          const userData = await response.json();
          setUserName(userData.name); // Assumindo que o backend retorna um campo "name"
          setUserRole(userData.role); // Assumindo que o backend retorna um campo "role"
        } else {
          console.error('Erro ao buscar dados do usuário');
        }
      } catch (error) {
        console.error('Erro ao buscar dados do usuário:', error);
      }
    };
  
    fetchUserData();
  }, []);
  
  useEffect(() => {
    const expiresAt = localStorage.getItem('expiresAt');

    const updateSessionTime = () => {
      if (expiresAt) {
        const expirationTime = parseInt(expiresAt, 10);
        if (!isNaN(expirationTime)) {
          const timeLeft = expirationTime - Date.now();
          setSessionTimeLeft(timeLeft > 0 ? timeLeft : 0);

          if (timeLeft <= 0) {
            handleLogout();
          }
        } else {
          console.error('Valor inválido para expiresAt no localStorage.');
          setSessionTimeLeft(0);  // Define como expirado se for inválido
        }
      } else {
        setSessionTimeLeft(0);  // Expirado se não houver expiresAt
      }
    };




    
    // Atualiza imediatamente e depois a cada segundo
    updateSessionTime();
    const interval = setInterval(updateSessionTime, 1000);

    return () => clearInterval(interval);
  }, []);

  const handleMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };
  const modalStyle = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    boxShadow: 24,
    p: 4,
    textAlign: 'center',
  };
  

  const handleNavigate = (path) => {
    navigate(path);
    handleMenuClose();
  };

  const handleLogoutClick = () => {
    setLogoutModalOpen(true);
  };

  const handleLogout = () => {
    localStorage.removeItem('token');
    localStorage.removeItem('usuario');
    localStorage.removeItem('expiresAt');
    setLogoutModalOpen(false);
    navigate('/');
  };

  const handleCloseLogoutModal = () => {
    setLogoutModalOpen(false);
  };

  return (
    <AppBar position="fixed" sx={{ backgroundColor: 'white', color: '#8c52ff', zIndex: 1300 }}>
      <Toolbar sx={{ display: 'flex', justifyContent: 'space-between' }}>
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <IconButton edge="start" color="inherit" aria-label="menu" onClick={toggleSidebar} sx={{ mr: 2 }}>
            <MenuIcon />
          </IconButton>
          <Box component="img" src={logo} alt="Logo" sx={{ height: 40, mr: 2 }} />
         
        </Box>

        <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
          

        <IconButton color="inherit">
  <NotificationsIcon sx={{ color: 'orange' }} />
</IconButton>

          <IconButton color="inherit">
            <ChatIcon sx={{ color: 'orange' }} />
          </IconButton>

          <IconButton color="inherit">
            <SupportIcon sx={{ color: 'orange' }} />
          </IconButton>

          <IconButton onClick={handleMenuOpen}>
            <Avatar sx={{ bgcolor: 'orange' }}>{userName.charAt(0).toUpperCase()}</Avatar>
          </IconButton>
        </Box>

        <Menu anchorEl={anchorEl} open={open} onClose={handleMenuClose}>
  {/* Exibe o nome do usuário acima do menu "Perfil" */}
  <Box sx={{ p: 2, borderBottom: '1px solid #e0e0e0' }}>
    <Typography variant="subtitle1" sx={{ fontWeight: 'bold' }}>
      {userName} {/* Exibe o nome do usuário */}
    </Typography>
    <Typography variant="body2" sx={{ color: 'text.secondary' }}>
      {userRole} {/* Exibe o tipo de usuário */}
    </Typography>
  </Box>

  <MenuItem onClick={() => handleNavigate('/perfil')}>Perfil</MenuItem>
  {userRole === 'superadmin' && <MenuItem onClick={() => handleNavigate('/empresas')}>Empresas</MenuItem>}
  <Divider />
  <MenuItem onClick={handleLogoutClick}>Sair</MenuItem>
</Menu>

        <Modal open={isLogoutModalOpen} onClose={handleCloseLogoutModal}>
          <Box sx={{ ...modalStyle }}>
            <Typography variant="h6">Encerrando sessão</Typography>
            <Typography sx={{ mt: 2 }}>Você está prestes a encerrar sua sessão. Deseja continuar?</Typography>
            <Box sx={{ mt: 4, display: 'flex', justifyContent: 'center', gap: 2 }}>
              <Button variant="contained" onClick={handleLogout}>Sim</Button>
              <Button variant="outlined" onClick={handleCloseLogoutModal}>Cancelar</Button>
            </Box>
          </Box>
        </Modal>
      </Toolbar>
    </AppBar>
  );
};

export default Header;
