import React, { useMemo, useState, useEffect } from 'react';
import axios from 'axios';
import {
  Box,
  Button,
  Menu,
  MenuItem,
  Snackbar,
  Alert,
  CircularProgress,
  IconButton,
  Tooltip,
  Typography,
} from '@mui/material';
import { MaterialReactTable } from 'material-react-table';
import {
  NearMeOutlined,
  Print,
  MoreVert,
  Edit,
  Visibility,
  Delete,
} from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';

const API_URL = `${process.env.REACT_APP_API_URL}/api/ordens-servico`;

const Servicos = () => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [rowSelection, setRowSelection] = useState({});
  const [anchorEl, setAnchorEl] = useState(null);
  const [successMessage, setSuccessMessage] = useState('');
  const [showSnackbar, setShowSnackbar] = useState(false);
  const [isPrinting, setIsPrinting] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(API_URL, {
          headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
        });
        setData(Array.isArray(response.data) ? response.data : []);
        setLoading(false);
      } catch (error) {
        console.error('Erro ao buscar dados:', error);
        setData([]);
        setLoading(false);
        if (error.response?.status === 401) {
          navigate('/login');
        }
      }
    };
    fetchData();
  }, [navigate]);

  const columns = useMemo(() => [
    { accessorKey: 'codigo', header: 'Código', size: 100 },
    { accessorKey: 'nome', header: 'Nome do Serviço', size: 200 },
    { accessorKey: 'descricao', header: 'Descrição', size: 300 },
    { accessorKey: 'departamento_id', header: 'Departamento', size: 150 },
    { accessorKey: 'tipo_servico_id', header: 'Tipo de Serviço', size: 150 },
    {
      accessorKey: 'acoes',
      header: 'Ações',
      enableSorting: false,
      muiTableHeadCellProps: { sx: { flexGrow: 1, textAlign: 'center' } },
      Cell: ({ row }) => (
        <Box sx={{ display: 'flex', gap: 1 }}>
          <Tooltip title="Visualizar">
            <IconButton onClick={() => handleView(row.original)} color="primary">
              <Visibility />
            </IconButton>
          </Tooltip>
          <Tooltip title="Editar">
            <IconButton onClick={() => handleEdit(row.original)} color="secondary">
              <Edit />
            </IconButton>
          </Tooltip>
          <Tooltip title="Remover">
            <IconButton onClick={() => handleRemove(row.original)} color="error">
              <Delete />
            </IconButton>
          </Tooltip>
        </Box>
      ),
    },
  ], []);

  const handlePrint = () => {
    setIsPrinting(true);
    setTimeout(() => {
      window.print();
      setIsPrinting(false);
    }, 500);
  };

  const handleView = (servico) => navigate(`/Servico/${servico.id}`);
  const handleEdit = (servico) => navigate(`/EditarServico/${servico.id}`);

  const handleRemove = async (servico) => {
    try {
      await axios.delete(`${API_URL}/${servico.id}`, {
        headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
      });
      setSuccessMessage('Serviço removido com sucesso!');
      setShowSnackbar(true);
      const response = await axios.get(API_URL, {
        headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
      });
      setData(Array.isArray(response.data) ? response.data : []);
    } catch (error) {
      console.error('Erro ao remover serviço:', error);
    }
  };

  return (
    <Box sx={{ minHeight: '100vh', width: '100%' }}>
      {/* Estilo para impressão */}
      <style>
  {`
    @media print {
      /* Remove tudo da tela, exceto o título e a tabela */
      body * {
        visibility: hidden;
      }

      /* Exibir apenas o título e a tabela na impressão */
      #print-title, #print-title *,
      #printable-table, #printable-table * {
        visibility: visible;
      }

      /* Centralizar título na impressão */
      #print-title {
        text-align: center;
        font-size: 16px;
        font-weight: bold;
        margin-bottom: 10px;
      }

      #printable-table {
        position: absolute;
        left: 0;
        top: 50px; /* Espaço para o título */
        width: 100%;
        max-width: 100%;
        font-size: 9px;
      }

      /* Ajustar largura das colunas */
      #printable-table .MuiTableCell-root {
        padding: 1px;
        font-size: 9px;
        white-space: nowrap;
      }

      /* Remover barra de rolagem */
      #printable-table .MuiTableContainer-root {
        overflow: hidden !important;
        height: auto !important;
      }

      /* Ajusta altura das linhas */
      #printable-table .MuiTableRow-root {
        height: auto !important;
      }

      /* Ocultar coluna "Ações" na impressão */
      #printable-table th:nth-last-child(1), /* Oculta o cabeçalho da última coluna (Ações) */
      #printable-table td:nth-last-child(1) { /* Oculta os dados da última coluna (Ações) */
        display: none !important;
      }

      /* Ocultar paginação, filtros e botões desnecessários */
      .MuiTablePagination-root, 
      .MuiToolbar-root, 
      .MuiIconButton-root, 
      .MuiButtonBase-root {
        display: none !important;
      }

      /* Impedir quebra de página e margens excessivas */
      @page {
        size: A4 landscape;
        margin: 5mm;
      }

      /* Prevenir que uma segunda página seja gerada */
      html, body {
        width: 100%;
        height: 100%;
        overflow: hidden;
      }
    }
  `}
</style>





      {/* Cabeçalho */}
      <Typography variant="h6" sx={{ mb: 2, fontWeight: 'bold', color: '#6a11cb', textAlign: 'left' }}>
        Central de Atividades
      </Typography>

      {/* Botões de Ação */}
      <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 3 }}>
        <Button variant="contained" color="primary" startIcon={<NearMeOutlined />} onClick={() => navigate('/NovoServico')}
          sx={{ backgroundColor: '#4caf50', ':hover': { backgroundColor: '#45a045' }, textTransform: 'none' }}>
          Adicionar
        </Button>

        <Box>
          <Button variant="contained" color="secondary" startIcon={<Print />} sx={{ textTransform: 'none', ml: 2 }} onClick={handlePrint}>
            Imprimir
          </Button>
        </Box>
      </Box>

      {/* Tabela */}
      <Box id="printable-table" sx={{ borderRadius: '8px', boxShadow: '0 4px 16px rgba(0, 0, 0, 0.2)' }}>
        <MaterialReactTable
          columns={columns}
          data={data}
          enableRowSelection
          onRowSelectionChange={setRowSelection}
          state={{ rowSelection }}
          enableGlobalFilter
          enableColumnFilters
          enableSorting
          enablePagination
          initialState={{ pagination: { pageSize: 10 } }}
          muiTableBodyRowProps={{ sx: { '&:nth-of-type(odd)': { backgroundColor: '#f9f9f9' }, '&:hover': { backgroundColor: '#f1f1f1' } } }}
          muiTableContainerProps={{ sx: { maxHeight: '70vh', overflow: 'auto' } }}
        />
      </Box>

      {/* Snackbar de Sucesso */}
      <Snackbar open={showSnackbar} autoHideDuration={6000} onClose={() => setShowSnackbar(false)}>
        <Alert onClose={() => setShowSnackbar(false)} severity="success" sx={{ width: '100%' }}>
          {successMessage}
        </Alert>
      </Snackbar>
    </Box>
  );
};

export default Servicos;
