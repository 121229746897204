import React, { useState, useMemo } from 'react';
import {
  Box,
  Button,
  Modal,
  Typography,
  List,
  ListItem,
  ListItemText,
  Chip,
  TextField,
  Autocomplete,
} from '@mui/material';
import { MaterialReactTable } from 'material-react-table';
import { SwapHoriz as TransferIcon } from '@mui/icons-material';

// Simulação de dados para tarefas e usuários
const generateObligationsData = (numClientes) => {
  const obligations = ['IRPF', 'ISS', 'ICMS', 'Folha de Pagamento', 'Contribuição'];
  const statusOptions = ['Em andamento', 'Pendente', 'Concluído', 'Cancelado'];
  const users = ['Ana Silva', 'Carlos Santos', 'Mariana Souza', 'Roberto Lima'];
  const departments = ['Financeiro', 'RH', 'Fiscal', 'Contábil'];

  return Array.from({ length: numClientes }).map((_, i) => ({
    id: i + 1,
    cliente: `Cliente ${i + 1}`,
    obrigacao: obligations[i % obligations.length],
    vencimento: `2024-12-${(i % 30) + 1}`,
    status: statusOptions[i % statusOptions.length],
    usuario: users[i % users.length],
    departamento: departments[i % departments.length],
  }));
};

const usuariosSimulados = [
  { nome: 'Ana Silva', departamento: 'Financeiro' },
  { nome: 'Carlos Santos', departamento: 'RH' },
  { nome: 'Mariana Souza', departamento: 'Fiscal' },
  { nome: 'Roberto Lima', departamento: 'Contábil' },
];

// Formata a data para dd/mm/yyyy
const formatDate = (dateString) => {
  const [year, month, day] = dateString.split('-');
  return `${day}/${month}/${year}`;
};

// Retorna tags de status com cores
const getStatusChip = (status) => {
  switch (status) {
    case 'Pendente':
      return <Chip label="Pendente" color="warning" />;
    case 'Em andamento':
      return <Chip label="Em andamento" color="primary" />;
    case 'Concluído':
      return <Chip label="Concluído" color="success" />;
    case 'Cancelado':
      return <Chip label="Cancelado" color="error" />;
    default:
      return <Chip label="Desconhecido" color="default" />;
  }
};

const TransferirResponsavel = () => {
  const [data] = useState(generateObligationsData(15));  // Simula dados para 15 clientes
  const [modalOpen, setModalOpen] = useState(false);
  const [selectedRows, setSelectedRows] = useState([]);
  const [rowSelection, setRowSelection] = useState({});
  const [responsavel, setResponsavel] = useState(null);

  const selectedCount = useMemo(() => Object.keys(rowSelection).length, [rowSelection]);

  // Abre a modal e captura as linhas selecionadas
  const handleOpenModal = () => {
    const selected = Object.keys(rowSelection).map((key) => data[key]);
    setSelectedRows(selected);
    setModalOpen(true);
  };

  const handleCloseModal = () => {
    setModalOpen(false);
    setSelectedRows([]);
    setResponsavel(null);
  };

  const columns = useMemo(() => [
    { accessorKey: 'id', header: 'ID' },
    { accessorKey: 'cliente', header: 'Cliente' },
    { accessorKey: 'obrigacao', header: 'Obrigação' },
    {
      accessorKey: 'vencimento',
      header: 'Vencimento',
      Cell: ({ cell }) => formatDate(cell.getValue()),
    },
    {
      accessorKey: 'status',
      header: 'Status',
      Cell: ({ cell }) => getStatusChip(cell.getValue()),
    },
    { accessorKey: 'usuario', header: 'Usuário' },
    { accessorKey: 'departamento', header: 'Departamento' },
  ], []);

  return (
    <Box sx={{ padding: 3, backgroundColor: '#f9f9f9', minHeight: '100vh' }}>
      {/* Botão Transferir Responsável */}
      <Box sx={{ display: 'flex', justifyContent: 'flex-end', mb: 3 }}>
        <Button
          variant="contained"
          color="primary"
          onClick={handleOpenModal}
          startIcon={<TransferIcon />}
          disabled={selectedCount === 0}
          sx={{
            textTransform: 'none',
            fontWeight: 'bold',
          }}
        >
          Transferir Responsável
        </Button>
      </Box>

      {/* Tabela de Obrigações */}
      <MaterialReactTable
        columns={columns}
        data={data}
        enableRowSelection
        onRowSelectionChange={setRowSelection}
        state={{ rowSelection }}
      />

      {/* Modal de Transferência */}
      <Modal open={modalOpen} onClose={handleCloseModal}>
        <Box
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: '90%',
            maxWidth: 600,
            bgcolor: 'background.paper',
            boxShadow: 24,
            p: 4,
            borderRadius: 2,
          }}
        >
          <Typography variant="h6" mb={3}>
            Transferir Responsável
          </Typography>

          <Typography variant="body1" mb={2}>
            Você selecionou <strong>{selectedRows.length}</strong> tarefa(s) para transferência:
          </Typography>

          <List sx={{ maxHeight: 200, overflowY: 'auto', mb: 3 }}>
            {selectedRows.map((row) => (
              <ListItem key={row.id}>
                <ListItemText
                  primary={row.cliente}
                  secondary={`Obrigação: ${row.obrigacao} - Vencimento: ${formatDate(row.vencimento)} - ${row.status}`}
                />
              </ListItem>
            ))}
          </List>

          {/* Campo de seleção com autocomplete */}
          <Autocomplete
            options={usuariosSimulados}
            getOptionLabel={(option) => `${option.nome} - ${option.departamento}`}
            value={responsavel}
            onChange={(event, newValue) => setResponsavel(newValue)}
            renderInput={(params) => (
              <TextField {...params} label="Selecione um novo responsável" fullWidth />
            )}
            sx={{ mb: 3 }}
          />

          <Box sx={{ display: 'flex', justifyContent: 'flex-end', gap: 2 }}>
            <Button variant="outlined" color="secondary" onClick={handleCloseModal}>
              Fechar
            </Button>
            <Button
              variant="contained"
              color="primary"
              onClick={() => {
                console.log('Transferido para:', responsavel);
                handleCloseModal();
              }}
              disabled={!responsavel}
            >
              Confirmar
            </Button>
          </Box>
        </Box>
      </Modal>
    </Box>
  );
};

export default TransferirResponsavel;
