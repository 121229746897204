import React, { useEffect, useState } from "react";
import { useParams, Link as RouterLink } from "react-router-dom";
import axios from "axios";
import NovoCliente from "./NovoCliente"; // Reutiliza o formulário de NovoCliente
import { Breadcrumbs, Link, Typography } from "@mui/material";

const VisualizarCliente = () => {
  const { id } = useParams(); // Captura o ID do cliente da URL
  const [formData, setFormData] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchClienteData = async () => {
      try {
        const token = localStorage.getItem("token");
        const response = await axios.get(
          `${process.env.REACT_APP_API_URL}/api/clientes/${id}`,
          {
            headers: { Authorization: `Bearer ${token}` },
          }
        );

        const cliente = response.data.cliente;
        const classificacao = response.data.classificacao;
        const inscricoes = response.data.inscricoes;
        const contatos = response.data.contatos;
        const anotacoes = response.data.anotacoes;
        const redesSociais = response.data.redesSociais;
        const responsaveis = response.data.responsaveis;

        setFormData({
          ...cliente,
          nomeFantasia: cliente.nome_fantasia || '', // Mapeamento correto

          classificacao: classificacao || {
            statusCliente: cliente.status_cliente || '',
            tipoCliente: cliente.tipo_cliente || [],
            categoriaCliente: cliente.categoria_cliente || '',
            grupoCliente: cliente.grupo_cliente || '',
            segmento: cliente.segmento || [],
            escritorio: cliente.escritorio || '',
          },
          inscricoes: inscricoes || [],
          contatos: contatos || [],
          anotacoes: anotacoes || [],
          redesSociais: redesSociais || [],
          responsaveis: responsaveis || [],
        });
        setIsLoading(false);
      } catch (err) {
        console.error("Erro ao carregar cliente:", err);
        alert("Erro ao carregar cliente.");
        setIsLoading(false);
      }
    };
    fetchClienteData();
  }, [id]);

  if (isLoading) return <div>Carregando...</div>;
  if (error) return <div>{error}</div>;

  return (
    <div>
      {/* Breadcrumb Navigation */}
      <Breadcrumbs aria-label="breadcrumb" sx={{ marginBottom: 2 }}>
        <Link
          underline="hover"
          color="inherit"
          component={RouterLink}
          to="/clientes"
        >
          Clientes
        </Link>
        <Typography color="text.primary">Visualizar Cliente</Typography>
      </Breadcrumbs>

      {/* Formulário de Visualização */}
      <NovoCliente
        isEdit={true} // Para manter o formulário funcional e aberto para edição (apenas para copiar)
        formData={formData} // Dados carregados do cliente
        setFormData={setFormData} // Mantém o estado atualizado para navegar entre as tabs
        handleSave={null} // Não fornece funcionalidade de salvar
        handleBack={() => console.log("Navegue para onde desejar")} // Exemplo de navegação
      />
    </div>
  );
};

export default VisualizarCliente;
