import React, { useEffect, useState } from "react";
import { useParams, Link as RouterLink } from "react-router-dom";
import axios from "axios";
import NovoCliente from "./NovoCliente";
import { Breadcrumbs, Link, Typography } from "@mui/material";

const EditarCliente = () => {
  const { id } = useParams();
  const [formData, setFormData] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const [statusOptions, setStatusOptions] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const token = localStorage.getItem("token");
        if (!token) throw new Error("Token não encontrado. Faça login novamente.");

        // Busca dados principais do cliente
        const clienteResponse = await axios.get(
          `${process.env.REACT_APP_API_URL}/api/clientes/${id}`,
          { headers: { Authorization: `Bearer ${token}` } }
        );

        const { 
          cliente,
          classificacao,
          inscricoes,
          contatos = [],
          anotacoes,
          redesSociais,
          responsaveis,
          termos = []
        } = clienteResponse.data;

        // Estrutura inicial dos dados
        const initialData = {
          ...cliente,
          nomeFantasia: cliente.nome_fantasia || '',
          classificacao: {
            statusCliente: cliente.status_cliente || '',
            tipoCliente: cliente.tipo_cliente || [],
            categoriaCliente: cliente.categoria_cliente || '',
            grupoCliente: cliente.grupo_cliente || '',
            segmento: cliente.segmento || [],
            escritorio: cliente.escritorio || '',
          },
          contatos: Array.isArray(contatos) ? contatos : [],
          inscricoes: (inscricoes || []).filter(insc => insc.tipo && insc.numero),

          anotacoes: (anotacoes || []).map(anotacao => ({
            id: anotacao.id,
            descricao: anotacao.descricao,
            data_registro: anotacao.data_registro || new Date().toISOString(),
            arquivo_url: anotacao.arquivo_url,
            usuarioNome: anotacao.usuarioNome || "Usuário não identificado"
          })),
                    redesSociais: redesSociais || [],
          responsaveis: responsaveis || [],
          termos: termos.map(termo => ({ id: termo.id, termo: termo.termo }))
        };

        setFormData(initialData);
        setIsLoading(false);
      } catch (err) {
        console.error("Erro ao carregar dados:", err);
        setError("Erro ao carregar dados do cliente.");
        setIsLoading(false);
      }
    };

    const fetchStatusOptions = async () => {
      try {
        const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/opcoes-status`);
        setStatusOptions(response.data);
      } catch (error) {
        console.error("Erro ao buscar opções de status:", error);
      }
    };

    fetchData();
    fetchStatusOptions();
  }, [id]);



  // Dentro do componente EditarCliente, adicione estas funções:

const handleSaveAnotacao = async (anotacao, index) => {
  try {
    const token = localStorage.getItem("token");
    if (!token) throw new Error("Token não encontrado.");
    
    const clienteId = formData?.id;
    if (!clienteId) throw new Error("ID do cliente não definido.");

    const method = anotacao.id ? 'put' : 'post';
    const url = anotacao.id 
      ? `${process.env.REACT_APP_API_URL}/api/clientes/anotacoes/${anotacao.id}`
      : `${process.env.REACT_APP_API_URL}/api/clientes/${clienteId}/anotacoes`;

    const response = await axios[method](url, anotacao, {
      headers: { Authorization: `Bearer ${token}` }
    });

    setFormData(prev => ({
      ...prev,
      anotacoes: prev.anotacoes.map((item, i) => 
        i === index ? response.data : item
      )
    }));

    alert("Anotação salva com sucesso!");
  } catch (error) {
    console.error("Erro ao salvar anotação:", error);
    alert("Erro ao salvar anotação.");
  }
};

const handleAddAnotacao = () => {
  setFormData(prev => ({
    ...prev,
    anotacoes: [
      ...prev.anotacoes,
      { 
        id: null, 
        titulo: '', 
        descricao: '', 
        data_registro: new Date().toISOString(),
        usuarioNome: "Usuário Atual" // Preencher com dados do token
      }
    ]
  }));
};

const handleRemoveAnotacao = async (index) => {
  try {
    const anotacao = formData.anotacoes[index];
    
    if (anotacao.id) {
      const token = localStorage.getItem("token");
      await axios.delete(
        `${process.env.REACT_APP_API_URL}/api/clientes/anotacoes/${anotacao.id}`,
        { headers: { Authorization: `Bearer ${token}` } }
      );
    }

    setFormData(prev => ({
      ...prev,
      anotacoes: prev.anotacoes.filter((_, i) => i !== index)
    }));

    alert("Anotação removida!");
  } catch (error) {
    console.error("Erro ao remover anotação:", error);
    alert("Erro ao remover anotação.");
  }
};

// Função para atualizar anotações no estado
const handleAnotacaoChange = (index, field, value) => {
  setFormData(prev => ({
    ...prev,
    anotacoes: prev.anotacoes.map((item, i) =>
      i === index ? { ...item, [field]: value } : item
    )
  }));
};

// Função para upload de arquivo
const handleUploadArquivo = async (index, file) => {
  try {
    const formData = new FormData();
    formData.append('arquivo', file);

    const response = await axios.post(
      `${process.env.REACT_APP_API_URL}/api/upload`,
      formData,
      { headers: { 'Content-Type': 'multipart/form-data' } }
    );

    setFormData(prev => ({
      ...prev,
      anotacoes: prev.anotacoes.map((item, i) =>
        i === index ? { ...item, arquivo_url: response.data.url } : item
      )
    }));
  } catch (error) {
    console.error("Erro no upload:", error);
  }
};

// Adicione estas funções ANTES do return do componente
  const handleSaveInscricao = async (inscricao, index) => {
    try {
      const token = localStorage.getItem("token");
      if (!token) throw new Error("Token não encontrado.");
      
      const clienteId = formData?.id;
      if (!clienteId) throw new Error("ID do cliente não definido.");

      const method = inscricao.id ? 'put' : 'post';
      const url = inscricao.id 
        ? `${process.env.REACT_APP_API_URL}/api/clientes/inscricoes/${inscricao.id}`
        : `${process.env.REACT_APP_API_URL}/api/clientes/${clienteId}/inscricoes`;

      const response = await axios[method](url, inscricao, {
        headers: { Authorization: `Bearer ${token}` }
      });

      setFormData(prev => ({
        ...prev,
        inscricoes: prev.inscricoes.map((item, i) => 
          i === index ? response.data : item
        )
      }));

      alert("Inscrição salva com sucesso!");
    } catch (error) {
      console.error("Erro ao salvar inscrição:", error);
      alert("Erro ao salvar inscrição.");
    }
  };

  const handleAddInscricao = () => {
    setFormData(prev => ({
      ...prev,
      inscricoes: [
        ...prev.inscricoes,
        { tipo: '', numero: '', identificacao: '', status: 'ATIVO' }
      ]
    }));
  };

  const handleRemoveInscricao = async (index) => {
    try {
      const inscricao = formData.inscricoes[index];
      
      if (inscricao.id) {
        const token = localStorage.getItem("token");
        await axios.delete(
          `${process.env.REACT_APP_API_URL}/api/clientes/inscricoes/${inscricao.id}`,
          { headers: { Authorization: `Bearer ${token}` } }
        );
      }

      setFormData(prev => ({
        ...prev,
        inscricoes: prev.inscricoes.filter((_, i) => i !== index)
      }));

      alert("Inscrição removida!");
    } catch (error) {
      console.error("Erro ao remover inscrição:", error);
      alert("Erro ao remover inscrição.");
    }
  };

  const handleSave = async (updatedData) => {
    try {
      const token = localStorage.getItem("token");
      if (!token) throw new Error("Token não encontrado.");

      const payload = {
        cliente: {
          ...updatedData,
          contatos: updatedData.contatos.map(contato => ({
            ...contato,
            id: contato.id || null
          })),
          inscricoes: updatedData.inscricoes
            .filter(insc => insc.tipo && insc.numero)
            .map(insc => ({ ...insc, id: insc.id || null }))
        }
      };

      await axios.put(
        `${process.env.REACT_APP_API_URL}/api/clientes/${id}`,
        payload,
        { headers: { Authorization: `Bearer ${token}` } }
      );

      alert("Cliente atualizado com sucesso!");
    } catch (error) {
      console.error("Erro ao atualizar cliente:", error);
      alert(`Erro ao atualizar: ${error.response?.data?.message || error.message}`);
    }
  };

  if (isLoading) return <div>Carregando...</div>;
  if (error) return <div>{error}</div>;

  return (
    <div>
      <Breadcrumbs sx={{ marginBottom: 2 }}>
        <Link component={RouterLink} to="/" color="inherit">Início</Link>
        <Link component={RouterLink} to="/clientes" color="inherit">Clientes</Link>
        <Typography color="text.primary">Editar Cliente</Typography>
      </Breadcrumbs>

      <NovoCliente
        isEdit={true}
        formData={formData}
        setFormData={setFormData}
        handleSave={handleSave}
        statusOptions={statusOptions}
        onInscricaoSave={handleSaveInscricao}
        onInscricaoAdd={handleAddInscricao}
        onInscricaoRemove={handleRemoveInscricao}
         // Adicione estas linhas
  onAnotacaoSave={handleSaveAnotacao}
  onAnotacaoRemove={handleRemoveAnotacao}
  onAnotacaoAdd={handleAddAnotacao}
  
  // Mantenha estas se já existirem
  onInscricaoSave={handleSaveInscricao}
  onInscricaoRemove={handleRemoveInscricao}
  onInscricaoAdd={handleAddInscricao}
      />
    </div>
  );
};

export default EditarCliente;