import React, { useState, useEffect } from 'react';
import {
  Box, IconButton, Typography, TextField, Button, Modal,
  RadioGroup, FormControlLabel, Radio, Table, TableHead,
  TableRow, TableCell, TableBody, Checkbox
} from '@mui/material';
import { Close as CloseIcon } from '@mui/icons-material';

const mockTarefas = [
  { id: 1, nome: 'Elaboração de Relatório Financeiro' },
  { id: 2, nome: 'Auditoria de Documentos' },
  { id: 3, nome: 'Análise de Balanço Patrimonial' },
  { id: 4, nome: 'Conciliação Bancária' },
  { id: 5, nome: 'Declaração de Imposto de Renda' }
];

const VigenciaModal = ({ open, onClose, vigencia, onSave }) => {
  const [form, setForm] = useState({
    dataInicial: '',
    dataFinal: '',
    status: 'Ativo',
    tarefasSelecionadas: []
  });

  useEffect(() => {
    if (vigencia) {
      setForm(vigencia);
    } else {
      setForm({
        dataInicial: '',
        dataFinal: '',
        status: 'Ativo',
        tarefasSelecionadas: []
      });
    }
  }, [vigencia]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setForm({ ...form, [name]: value });
  };

  const handleToggleTarefa = (id) => {
    setForm((prevForm) => {
      const isSelected = prevForm.tarefasSelecionadas.includes(id);
      return {
        ...prevForm,
        tarefasSelecionadas: isSelected
          ? prevForm.tarefasSelecionadas.filter((tarefaId) => tarefaId !== id)
          : [...prevForm.tarefasSelecionadas, id]
      };
    });
  };

  const handleSubmit = () => {
    onSave(form);
    onClose();
  };

  return (
    <Modal open={open} onClose={onClose}>
      <Box sx={{
        padding: 4,
        backgroundColor: 'white',
        margin: 'auto',
        width: '50%',
        marginTop: '5%',
        maxHeight: '90vh',
        overflowY: 'auto',
        position: 'relative',
      }}>
        <IconButton sx={{ position: 'absolute', top: 8, right: 8 }} onClick={onClose}>
          <CloseIcon />
        </IconButton>

        <Typography variant="h6" sx={{ mb: 2 }}>
          {vigencia ? 'Editar Vigência Tarefas Grupo Clientes' : 'Adicionar Vigência Tarefas Grupo Clientes'}
        </Typography>

        <TextField
          label="Data Inicial"
          type="date"
          name="dataInicial"
          value={form.dataInicial}
          onChange={handleChange}
          fullWidth
          sx={{ mb: 2 }}
          InputLabelProps={{ shrink: true }}
        />

        <TextField
          label="Data Final"
          type="date"
          name="dataFinal"
          value={form.dataFinal}
          onChange={handleChange}
          fullWidth
          sx={{ mb: 2 }}
          InputLabelProps={{ shrink: true }}
        />

        <Typography>Status:</Typography>
        <RadioGroup
          row
          name="status"
          value={form.status}
          onChange={handleChange}
          sx={{ mb: 2 }}
        >
          <FormControlLabel value="Ativo" control={<Radio />} label="Ativo" />
          <FormControlLabel value="Inativo" control={<Radio />} label="Inativo" />
        </RadioGroup>

        <Typography variant="h6" sx={{ mt: 2, mb: 1 }}>
          Tarefas Associadas
        </Typography>

        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Selecionar</TableCell>
              <TableCell>Nome da Tarefa</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {mockTarefas.map((tarefa) => (
              <TableRow key={tarefa.id}>
                <TableCell>
                  <Checkbox
                    checked={form.tarefasSelecionadas.includes(tarefa.id)}
                    onChange={() => handleToggleTarefa(tarefa.id)}
                  />
                </TableCell>
                <TableCell>{tarefa.nome}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>

        <Button variant="contained" color="primary" onClick={handleSubmit} sx={{ mt: 3 }}>
          Salvar
        </Button>
      </Box>
    </Modal>
  );
};

export default VigenciaModal;
