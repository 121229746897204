// src/App.js
import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { ThemeProvider, createTheme, CssBaseline } from '@mui/material';
import MainLayout from './layouts/MainLayout';
import Login from './pages/Login';
import Dashboard from './pages/Dashboard';
import NotFound from './pages/NotFound';
import Clientes from './pages/Clientes/Clientes';
import NovoCliente from './pages/Clientes/NovoCliente';
import EmpresasList from './pages/Empresas/EmpresasList';
import EmpresaForm from './pages/Empresas/EmpresaForm';
import RedefinirSenha from './pages/RedefinirSenha';
import Perfil from './pages/Perfil';
import ProtectedAdminRoute from './components/ProtectedAdminRoute'; // Rota protegida para admin
import { Navigate } from 'react-router-dom'; // Import necessário para redirecionamento
import GrupoCliente from './pages/Configuracoes/GrupoCliente/GrupoCliente'; // Página de Grupo de Cliente
import GrupoServico from './pages/Configuracoes/GrupoServico'; // Página de Grupo de Cliente
import StatusCliente from './pages/Configuracoes/StatusCliente'; // Página de Status Cliente
import StatusComplementar from './pages/Configuracoes/StatusComplementar'; // Página de Status Complementar
import CategoriaCliente from './pages/Configuracoes/CategoriaCliente'; // Página de Categoria de Clientes
import SegmentoEmpresa from './pages/Configuracoes/SegmentoEmpresa'; // Página de Segmento empresa
import TipoCliente from './pages/Configuracoes/TipoCliente'; // Página do tipo do cliene
import Escritorio from './pages/Configuracoes/Empresa/Escritorio'; // Página de Escritório
import Departamento from './pages/Configuracoes/Departamento'; // Página do departamento
import TipoServico from './pages/Configuracoes/TipoServico'; // Página tipo do serviço
import Feriados from './pages/Configuracoes/Extras/CalendarioFeriados'; // Página feriados
import EmailTemplates from './pages/Configuracoes/Email'; // Página email
import EmailPersonalizado from './pages/Configuracoes/Email/Personalizado'; // Página email

import WhatsappTemplates from './pages/Configuracoes/Whatsapp/Templates'; // Página whatsapp
import TemplanteDocumentos from './pages/Configuracoes/TemplanteDocumentos'; // Página whatsapp
import HorarioAcesso from './pages/Configuracoes/Usuarios/HorarioAcessos'; // Página whatsapp
import UsuariosPermissoes from './pages/Configuracoes/Usuarios/UsuariosPermissoes'; // Página whatsapp
import Integracoes from './pages/Configuracoes/Integracoes'; // Página Integracoes
import RegimeTributario from './pages/Configuracoes/RegimeTributario'; 
import ModeloRegimeTributario from './pages/Configuracoes/ModeloRegimeTributario'; 
import VisualizarCliente from './pages/Clientes/VisualizarCliente'; 
import Configuracoes from './pages/Configuracoes/Configuracoes'; 
import Servicos from './pages/Servicos/Servicos'; // Página servicos
import NovoServico from './pages/Servicos/NovoServico'; // Página novo servicos
import Obrigacoes from './pages/Obrigacoes/Obrigacoes'; // Página Obrigações
import NovaObrigacoes from './pages/Obrigacoes/NovaObrigacoes'; // Página Obrigações
import Controles from './pages/Controles/Controles'; // Página Obrigações
import OrdemServicos from './pages/OrdemServico/OrdemServico'; // Página Obrigações
import NovaOrdemServico from './pages/OrdemServico/NovaOrdemServico'; // Página Obrigações
import GerarRecorrente  from './pages/Atividades/GerarRecorrente'; // Página gerar recorrente
import AlterarVencimento  from './pages/Atividades/AlterarVencimento'; // Página alterar vencimento
import RemoverTarefaN from './pages/Atividades/RemoverTarefaN'; // tarefa não iniciada
import RemoverTarefa_Iniciada from './pages/Atividades/RemoverTarefa_Iniciada'; // remvoer tarefa iniciada mais não concluida
import RemoverTodas from './pages/Atividades/RemoverTodas'; // remvoer tarefas todas
import TransferirResponsavel from './pages/Atividades/TransferirResponsavel'; // remvoer tarefa iniciada mais não concluida
import Relatorios from './pages/Atividades/Relatorios'; // Relatorios
import EditarCliente  from './pages/Clientes/EditarCliente'; // Página Obrigações
import PainelAtividade from './pages/PainelAtividade/PainelAtividade'; // Página atividade
import ProtectedRoute from './components/ProtectedRoute'; // Importa o componente de Rota Protegida
import EmpresaPerfil from './pages/Configuracoes/Empresa/DadosCadastrais'; // rota de empresa perfil de configurações

// Criação do tema personalizado
const theme = createTheme({
  palette: {
    primary: {
      main: '#6A1B9A', // Cor roxa similar ao design
    },
    secondary: {
      main: '#ff9800', // Cor laranja
    },
  },
});

function App() {
  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Router>
        <Routes>


          {/* Rota de Login */}
          <Route path="/" element={<Login />} />
          <Route path="/login" element={<Navigate to="/" replace />} />


          {/* Rotas protegidas (somente acessíveis após login) */}
          <Route
            path="/dashboard"
            element={
              <ProtectedRoute>
                <MainLayout>
                  <Dashboard />
                </MainLayout>
              </ProtectedRoute>
            }
          />

<Route
            path="/NovoCliente"
            element={
              <ProtectedRoute>
                <MainLayout>
                  <NovoCliente />
                </MainLayout>
              </ProtectedRoute>
            }
          />

<Route
  path="/EditarCliente/:id"
  element={
    <ProtectedRoute>
      <MainLayout>
        <EditarCliente />
      </MainLayout>
    </ProtectedRoute>
  }
/>



<Route
            path="/NovoServico"
            element={
              <ProtectedRoute>
                <MainLayout>
                  <NovoServico />
                </MainLayout>
              </ProtectedRoute>
            }
          />


          
<Route
            path="/GerarRecorrente"
            element={
              <ProtectedRoute>
                <MainLayout>
                  <GerarRecorrente />
                </MainLayout>
              </ProtectedRoute>
            }
          />


<Route
            path="/AlterarVencimento"
            element={
              <ProtectedRoute>
                <MainLayout>
                  <AlterarVencimento />
                </MainLayout>
              </ProtectedRoute>
            }
          />


<Route
            path="/RemoverTarefaN"
            element={
              <ProtectedRoute>
                <MainLayout>
                  <RemoverTarefaN />
                </MainLayout>
              </ProtectedRoute>
            }
          />


<Route
            path="/RemoverTarefa_Iniciada"
            element={
              <ProtectedRoute>
                <MainLayout>
                  <RemoverTarefa_Iniciada />
                </MainLayout>
              </ProtectedRoute>
            }
          />


<Route
            path="/RemoverTodas"
            element={
              <ProtectedRoute>
                <MainLayout>
                  <RemoverTodas />
                </MainLayout>
              </ProtectedRoute>
            }
          />


<Route
            path="/TransferirResponsavel"
            element={
              <ProtectedRoute>
                <MainLayout>
                  <TransferirResponsavel />
                </MainLayout>
              </ProtectedRoute>
            }
          />

<Route
            path="/Relatorios"
            element={
              <ProtectedRoute>
                <MainLayout>
                  <Relatorios />
                </MainLayout>
              </ProtectedRoute>
            }
          />
<Route
            path="/painel-atividades"
            element={
              <ProtectedRoute>
                <MainLayout>
                  <PainelAtividade />
                </MainLayout>
              </ProtectedRoute>
            }
          />


<Route
            path="/servicos"
            element={
              <ProtectedRoute>
                <MainLayout>
                  <Servicos />
                </MainLayout>
              </ProtectedRoute>
            }
          />


<Route
            path="/obrigacoes"
            element={
              <ProtectedRoute>
                <MainLayout>
                  <Obrigacoes />
                </MainLayout>
              </ProtectedRoute>
            }
          />

<Route
            path="/nova-obrigacoes"
            element={
              <ProtectedRoute>
                <MainLayout>
                  <NovaObrigacoes />
                </MainLayout>
              </ProtectedRoute>
            }
          />



<Route
            path="/configuracoes/empresa/dados-cadastrais"
            element={
              <ProtectedRoute>
                <MainLayout>
                  <EmpresaPerfil />
                </MainLayout>
              </ProtectedRoute>
            }
          />
          <Route
            path="/ico"
            element={
              <ProtectedRoute>
                <MainLayout>
                  <OrdemServicos />
                </MainLayout>
              </ProtectedRoute>
            }
          />


<Route
  path="/VisualizarCliente/:id"
  element={
    <ProtectedRoute>
      <MainLayout>
        <VisualizarCliente />
      </MainLayout>
    </ProtectedRoute>
  }
/>

<Route
            path="/nova-ordem-servico"
            element={
              <ProtectedRoute>
                <MainLayout>
                  <NovaOrdemServico />
                </MainLayout>
              </ProtectedRoute>
            }
          />

<Route
            path="/controles"
            element={
              <ProtectedRoute>
                <MainLayout>
                  <Controles />
                </MainLayout>
              </ProtectedRoute>
            }
          />

          <Route
            path="/clientes"
            element={
              <ProtectedRoute>
                <MainLayout>
                  <Clientes />
                </MainLayout>
              </ProtectedRoute>
            }
          />

          {/* Rotas de Empresas protegidas */}
          <Route
            path="/empresas"
            element={
              <ProtectedRoute>
                <MainLayout>
                  <EmpresasList />
                </MainLayout>
              </ProtectedRoute>
            }
          />
          <Route
            path="/empresas/novo"
            element={
              <ProtectedRoute>
                <MainLayout>
                  <EmpresaForm />
                </MainLayout>
              </ProtectedRoute>
            }
          />
          <Route
            path="/empresas/editar/:id"
            element={
              <ProtectedRoute>
                <MainLayout>
                  <EmpresaForm />
                </MainLayout>
              </ProtectedRoute>
            }
          />

           {/* Rota para grupo de cliente (acessível apenas por admin) */}
           <Route
              path="/grupo-cliente"
              element={
                <ProtectedAdminRoute>
                  <MainLayout>
                    <GrupoCliente />
                  </MainLayout>
                </ProtectedAdminRoute>
              }
            />


<Route
  path="/grupo-servico"
  element={
    <ProtectedAdminRoute>
      <MainLayout>
        <GrupoServico />
      </MainLayout>
    </ProtectedAdminRoute>
  }
/>

<Route
  path="/regime-tributario"
  element={
    <ProtectedAdminRoute>
      <MainLayout>
        <RegimeTributario />
      </MainLayout>
    </ProtectedAdminRoute>
  }
/>


<Route
  path="/modelo-regime-tributario"
  element={
    <ProtectedAdminRoute>
      <MainLayout>
        <ModeloRegimeTributario />
      </MainLayout>
    </ProtectedAdminRoute>
  }
/>


<Route
  path="/status-cliente"
  element={
    <ProtectedAdminRoute>
      <MainLayout>
        <StatusCliente />
      </MainLayout>
    </ProtectedAdminRoute>
  }
/>

<Route
  path="/status-complementar"
  element={
    <ProtectedAdminRoute>
      <MainLayout>
        <StatusComplementar />
      </MainLayout>
    </ProtectedAdminRoute>
  }
/>
<Route
  path="/categoria-cliente"
  element={
    <ProtectedAdminRoute>
      <MainLayout>
        <CategoriaCliente />
      </MainLayout>
    </ProtectedAdminRoute>
  }
/>

<Route
  path="/segmento-empresa"
  element={
    <ProtectedAdminRoute>
      <MainLayout>
        <SegmentoEmpresa />
      </MainLayout>
    </ProtectedAdminRoute>
  }
/>



<Route
  path="/tipo-cliente"
  element={
    <ProtectedAdminRoute>
      <MainLayout>
        <TipoCliente />
      </MainLayout>
    </ProtectedAdminRoute>
  }
/>


<Route
  path="/configuracoes/empresa/escritorio"
  element={
    <ProtectedAdminRoute>
      <MainLayout>
        <Escritorio />
      </MainLayout>
    </ProtectedAdminRoute>
  }
/>





<Route
  path="/departamento"
  element={
    <ProtectedAdminRoute>
      <MainLayout>
        <Departamento />
      </MainLayout>
    </ProtectedAdminRoute>
  }
/>


<Route
  path="/tipo-servico"
  element={
    <ProtectedAdminRoute>
      <MainLayout>
        <TipoServico />
      </MainLayout>
    </ProtectedAdminRoute>
  }
/>

<Route
  path="/configuracoes/extras/calendario"
  element={
    <ProtectedAdminRoute>
      <MainLayout>
        <Feriados />
      </MainLayout>
    </ProtectedAdminRoute>
  }
/>


<Route
  path="/configuracoes/usuarios/usuarios-permissoes"
  element={
    <ProtectedAdminRoute>
      <MainLayout>
        <UsuariosPermissoes />
      </MainLayout>
    </ProtectedAdminRoute>
  }
/>

<Route
  path="/configuracoes"
  element={
    <ProtectedAdminRoute>
      <MainLayout>
        <Configuracoes />
      </MainLayout>
    </ProtectedAdminRoute>
  }
/>




<Route
  path="/template-documento"
  element={
    <ProtectedAdminRoute>
      <MainLayout>
        <TemplanteDocumentos />
      </MainLayout>
    </ProtectedAdminRoute>
  }
/>

<Route
  path="/configuracoes/email/templates"
  element={
    <ProtectedAdminRoute>
      <MainLayout>
        <EmailTemplates />
      </MainLayout>
    </ProtectedAdminRoute>
  }
/>


<Route
  path="/configuracoes/email/personalizado"
  element={
    <ProtectedAdminRoute>
      <MainLayout>
        <EmailPersonalizado />
      </MainLayout>
    </ProtectedAdminRoute>
  }
/>

<Route
  path="/configuracoes/whatsapp/templates"
  element={
    <ProtectedAdminRoute>
      <MainLayout>
        <WhatsappTemplates />
      </MainLayout>
    </ProtectedAdminRoute>
  }
/>



<Route
  path="/integracoes"
  element={
    <ProtectedAdminRoute>
      <MainLayout>
        <Integracoes />
      </MainLayout>
    </ProtectedAdminRoute>
  }
/>

<Route
  path="/configuracoes/usuarios/horarios-acesso"
  element={
    <ProtectedAdminRoute>
      <MainLayout>
        <HorarioAcesso />
      </MainLayout>
    </ProtectedAdminRoute>
  }
/>





          <Route
            path="/empresas/:id"
            element={
              <ProtectedRoute>
                <MainLayout>
                  <EmpresaForm />
                </MainLayout>
              </ProtectedRoute>
            }
          />
          {/* Rota de Perfil (protegida) */}
          <Route
            path="/perfil"
            element={
              <ProtectedRoute>
                <MainLayout>
                  <Perfil />
                </MainLayout>
              </ProtectedRoute>
            }
          />

          {/* Rota para redefinir senha (acessível sem login) */}
          <Route path="/redefinir-senha/:token" element={<RedefinirSenha />} />

          {/* Página 404 (Not Found) */}
          <Route path="*" element={<NotFound />} />
        </Routes>
      </Router>
    </ThemeProvider>
  );
}

export default App;
