import React, { useState, useMemo } from 'react';
import {
  Box,
  Button,
  Modal,
  Typography,
  TextField,
} from '@mui/material';
import { MaterialReactTable } from 'material-react-table';
import { Event as EventIcon } from '@mui/icons-material';

// Simulação de dados com datas de vencimento
const generateObligationsData = (numClientes) => {
  const obligations = ['Imposto de Renda', 'ISS', 'ICMS', 'Folha de Pagamento', 'Contribuições'];
  return Array.from({ length: numClientes }).map((_, i) => ({
    id: i + 1,
    cliente: `Cliente ${i + 1}`,
    obrigacao: obligations[i % obligations.length],
    vencimento: `2024-11-${(i % 30) + 1}`,  // Datas de vencimento simuladas
    status: i % 2 === 0 ? 'Pendente' : 'Concluído',
  }));
};

const AlterarVencimento = () => {
  const [data] = useState(generateObligationsData(10));  // Simula dados para 10 clientes
  const [modalOpen, setModalOpen] = useState(false);
  const [selectedDate, setSelectedDate] = useState('');
  const [selectedVencimento, setSelectedVencimento] = useState('');
  const [rowSelection, setRowSelection] = useState({});

  const selectedCount = useMemo(() => Object.keys(rowSelection).length, [rowSelection]);

  // Função para abrir a modal e capturar a data de vencimento
  const handleOpenModal = () => {
    const selectedRows = Object.keys(rowSelection).map((key) => data[key]);
    if (selectedRows.length > 0) {
      setSelectedVencimento(selectedRows[0].vencimento);  // Pega a data da primeira linha selecionada
    }
    setModalOpen(true);
  };

  const handleCloseModal = () => {
    setModalOpen(false);
    setSelectedDate('');
    setSelectedVencimento('');
  };

  const columns = useMemo(() => [
    { accessorKey: 'id', header: 'ID' },
    { accessorKey: 'cliente', header: 'Cliente' },
    { accessorKey: 'obrigacao', header: 'Obrigação' },
    { accessorKey: 'vencimento', header: 'Vencimento' },  // Coluna de vencimento
    { accessorKey: 'status', header: 'Status' },
  ], []);

  return (
    <Box sx={{ padding: 3, backgroundColor: '#f9f9f9', minHeight: '100vh' }}>
      {/* Botão Alterar Vencimento */}
      <Box sx={{ display: 'flex', justifyContent: 'flex-end', mb: 3 }}>
        <Button
          variant="contained"
          color="primary"
          onClick={handleOpenModal}
          startIcon={<EventIcon />}
          disabled={selectedCount === 0}  // Desativa se nenhuma linha for selecionada
          sx={{
            textTransform: 'none',
            fontWeight: 'bold',
          }}
        >
          Alterar Vencimento
        </Button>
      </Box>

      {/* Tabela de Obrigações */}
      <MaterialReactTable
        columns={columns}
        data={data}
        enableRowSelection
        onRowSelectionChange={setRowSelection}
        state={{ rowSelection }}
      />

      {/* Modal de Alteração de Vencimento */}
      <Modal open={modalOpen} onClose={handleCloseModal}>
        <Box
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: '90%',
            maxWidth: 600,
            bgcolor: 'background.paper',
            boxShadow: 24,
            p: 4,
            borderRadius: 2,
          }}
        >
          <Typography variant="h6" mb={3}>
            Alterar Vencimento
          </Typography>

          <Typography variant="body1" mb={2}>
            Data de vencimento atual: <strong>{selectedVencimento || 'Não selecionado'}</strong>
          </Typography>

          <TextField
            fullWidth
            label="Nova Data de Vencimento"
            type="date"
            value={selectedDate}
            onChange={(e) => setSelectedDate(e.target.value)}
            InputLabelProps={{ shrink: true }}
            sx={{ mb: 3 }}
          />

          <Box sx={{ display: 'flex', justifyContent: 'flex-end', gap: 2 }}>
            <Button variant="outlined" color="secondary" onClick={handleCloseModal}>
              Fechar
            </Button>
            <Button
              variant="contained"
              color="primary"
              onClick={() => {
                console.log('Nova Data de Vencimento:', selectedDate);
                handleCloseModal();
              }}
              disabled={!selectedDate}  // Desativa o botão se a data não for selecionada
            >
              Confirmar
            </Button>
          </Box>
        </Box>
      </Modal>
    </Box>
  );
};

export default AlterarVencimento;
