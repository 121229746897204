import React, { useState } from 'react';
import {
  Box, Grid, TextField, Select, MenuItem, Typography, Paper,
  FormControl, InputLabel, FormControlLabel, Modal, Button
} from '@mui/material';
import { RadioGroup, Radio, Tabs, Tab } from '@mui/material';
import { Person, Info, Contacts, ListAlt } from '@mui/icons-material';
import Checkbox from '@mui/material/Checkbox';
import { Card } from '@mui/material';
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { v4 as uuidv4 } from "uuid";
import { SortableContainer, SortableElement } from "react-sortable-hoc";
import InputMask from 'react-input-mask';
import Switch from '@mui/material/Switch';
import { Autocomplete } from '@mui/material';

import {
  
  Event,
  Settings,
  Description,
  CheckCircle,
  Folder,
  Notifications,
  AttachMoney,
} from '@mui/icons-material';
import axios from 'axios';

const NovaTarefa = () => {
  const [selectedOption, setSelectedOption] = useState('obrigacoes'); // Opção atual
  const [pendingOption, setPendingOption] = useState(null); // Nova opção pendente
  const [openModal, setOpenModal] = useState(false); // Controle do modal
  const [checklistItems, setChecklistItems] = useState([]);
  const [dataEntrega, setDataEntrega] = useState('');
  const [atendeNaoAtende, setAtendeNaoAtende] = useState(false);
  const [selectedDocumentos, setSelectedDocumentos] = useState([]); // Lista de documentos selecionados

  const checklistId = uuidv4();

  const [exigeRobo, setExigeRobo] = useState(false);
  const [passivelMulta, setPassivelMulta] = useState(false);
  const [alertaGuiaNaoLida, setAlertaGuiaNaoLida] = useState(false);
  const [notificarCliente, setNotificarCliente] = useState(false);
  const [servicoLiberadoNoApp, setServicoLiberadoNoApp] = useState(false);
  const [gerarPara, setGerarPara] = useState('');
  const [observacao, setObservacao] = useState('');
  const token = localStorage.getItem('token');
  
  const [activeTabControle, setActiveTabControle] = useState(0);




const [newStep, setNewStep] = useState(""); // Estado para um novo passo
const [folders, setFolders] = useState([
  { id: "root", name: "Root", files: [], subfolders: [] },
]); 

// Estrutura de pastas
const [currentFolderId, setCurrentFolderId] = useState("root"); // Navegação atual
const [newFolderName, setNewFolderName] = useState(""); // Para adicionar nova pasta
const [newFileName, setNewFileName] = useState(""); // Para upload de novo arquivo
const [pdfData, setPdfData] = useState(null); // Armazena os dados extraídos do PDF
const [loading, setLoading] = useState(false); // Controle de carregamento
const [selectedEntregaOption, setSelectedEntregaOption] = useState('opcao1');  // Estado para controlar a opção selecionada
const [intervalo, setIntervalo] = useState('dias');  // Controle do seletor de intervalo
const [quantidade, setQuantidade] = useState('');  // Controle para o valor numérico
const documentosCadastrados = [
  { id: 1, nome: "Nota Fiscal 123", tipoDocumento: "PDF" },
  { id: 2, nome: "Guia da Previdência 2025", tipoDocumento: "PDF"  },
  { id: 3, nome: "Outro Documento", tipoDocumento: "PDF" },
];





  const handleRadioChange = (event) => {
    const newValue = event.target.value; // Captura a nova seleção
    setOpenModal(true); // Abre o modal para confirmar
    setSelectedOption(newValue);  // Atualiza diretamente sem modal

  };

  
  // Componente para cada item do checklist
const SortableItem = SortableElement(({ item, onRemove }) => (
  <Box
    sx={{
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
      mb: 1,
      p: 2,
      backgroundColor: "#fff",
      borderRadius: 1,
      boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.1)",
    }}
  >
    <Typography>{item.text}</Typography>
    <Button
      variant="outlined"
      color="secondary"
      size="small"
      onClick={() => onRemove(item.id)}
    >
      Remover
    </Button>
  </Box>
));



const SortableList = SortableContainer(({ items, onRemove }) => (
  <Box sx={{ maxHeight: 400, overflowY: "auto", backgroundColor: "#f9f9f9", borderRadius: 1, p: 2 }}>
    {items.map((item, index) => (
      <SortableItem key={item.id} index={index} item={item} onRemove={onRemove} />
    ))}
  </Box>
));

  const handleEntregaOptionChange = (event) => {
    setSelectedEntregaOption(event.target.value);
    setQuantidade('');  // Reseta o valor quando muda a opção
  };

  const handleAddStep = () => {
    if (newStep.trim()) {
      const newItem = {
        id: uuidv4(), // Gera uma ID única para o item
        text: newStep,
      };
      setChecklistItems([...checklistItems, newItem]);
      setNewStep("");
   }
  };
  
  
  
  
  console.log("Checklist Items:", checklistItems);
  console.log("Droppable ID:", "checklist");


  const handleDragEnd = (result) => {
    if (!result.destination) return;
  
    const reorderedItems = Array.from(checklistItems);
    const [movedItem] = reorderedItems.splice(result.source.index, 1);
    reorderedItems.splice(result.destination.index, 0, movedItem);
  
    setChecklistItems(reorderedItems);
  };
  



// Adicionar nova pasta
const handleAddFolder = () => {
  if (newFolderName.trim()) {
    const folder = findFolderById(currentFolderId, folders);
    folder.subfolders.push({
      id: Date.now().toString(),
      name: newFolderName.trim(),
      files: [],
      subfolders: [],
    });
    setFolders([...folders]);
    setNewFolderName("");
  }
};

// Adicionar novo arquivo
const handleAddFile = () => {
  if (newFileName.trim()) {
    const folder = findFolderById(currentFolderId, folders);
    folder.files.push({ id: Date.now().toString(), name: newFileName.trim() });
    setFolders([...folders]);
    setNewFileName("");
  }
};

const handleDocumentoSelecionado = (selectedDocumento) => {
  if (selectedDocumento) {
    // Lógica ao selecionar um documento
    console.log("Documento selecionado:", selectedDocumento);
    // Atualize os dados conforme necessário
  }
};



const salvarObrigacao = async () => {
  try {
    const obrigacao = {
      codigo: formData.codigo,
      nome_obrigacao: formData.nomeObrigacao,
      departamento: formData.departamento,
      tipo_servico: formData.tipoServico,
      grupo_servico: formData.grupoServico,
      esfera: formData.esfera,
      exige_robo: exigeRobo,
      passivel_multa: passivelMulta,
      alerta_guia_nao_lida: alertaGuiaNaoLida,
      notificar_cliente: notificarCliente,
      servico_liberado_no_app: servicoLiberadoNoApp,
      gerar_para: gerarPara,
      observacao: observacao,
    };

    const response = await axios.post(`${process.env.REACT_APP_API_URL}/api/obrigacoes`, obrigacao, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    if (response.status === 201) {
      alert('Obrigação criada com sucesso!');
    }
  } catch (error) {
    console.error(error);
    alert('Erro ao criar a obrigação.');
  }
};




 

const handleTestFileUpload = async (event) => {
  const file = event.target.files[0];
  if (!file) {
    alert("Por favor, selecione um arquivo.");
    return;
  }

  const formData = new FormData();
  formData.append("file", file);

  setLoading(true);

  try {
    const response = await fetch("https://ai.conttalk.com.br/api/upload-pdf", {
      method: "POST",
      body: formData,
    });

    if (!response.ok) {
      throw new Error("Erro ao processar o arquivo.");
    }

    const result = await response.json();
    setPdfData(result); // Salva os dados no estado
  } catch (error) {
    console.error("Erro ao fazer upload:", error);
    alert("Erro ao processar o arquivo.");
  } finally {
    setLoading(false);
  }
};




// Remover pasta ou arquivo
const handleRemoveItem = (itemId, type) => {
  const folder = findFolderById(currentFolderId, folders);
  if (type === "folder") {
    folder.subfolders = folder.subfolders.filter((sub) => sub.id !== itemId);
  } else {
    folder.files = folder.files.filter((file) => file.id !== itemId);
  }
  setFolders([...folders]);
};

// Função para encontrar uma pasta pelo ID
const findFolderById = (id, folders) => {
  for (const folder of folders) {
    if (folder.id === id) return folder; // Retorna a pasta encontrada
    const found = findFolderById(id, folder.subfolders); // Busca recursiva em subpastas
    if (found) return found; // Retorna a subpasta encontrada
  }
  return null; // Retorna null se não encontrar
};

// Navegar para dentro de uma pasta
const handleNavigateToFolder = (folderId) => {
  setCurrentFolderId(folderId);
};

// Navegar para a pasta pai
const handleNavigateBack = () => {
  if (currentFolderId === "root") return;
  const parentFolderId = folders
    .reduce((acc, folder) => [...acc, ...folder.subfolders], [])
    .find((sub) => sub.id === currentFolderId).parentId;
  setCurrentFolderId(parentFolderId || "root");
};

const handleSubmit = async () => {
  try {
    const response = await axios.post(
      `${process.env.REACT_APP_API_URL}/api/obrigacoes`, 
      formData, 
      {
        headers: {
          'Content-Type': 'application/json',
        },
      }
    );

    if (response.status === 200 || response.status === 201) {
      alert('Obrigação criada com sucesso!');
      console.log(response.data); // Para depuração
    } else {
      throw new Error('Erro ao criar obrigação');
    }
  } catch (error) {
    console.error(error);
    alert('Ocorreu um erro ao criar a obrigação.');
  }
};

// Remove um passo do checklist
const handleRemoveStep = (id) => {
  const updatedItems = checklistItems.filter(item => item.id !== id);
  setChecklistItems(updatedItems);
};

const [uploadedFiles, setUploadedFiles] = useState([]);
const [documentName, setDocumentName] = useState("");

const handleFileUpload = (event) => {
  const file = event.target.files[0];
  if (file && documentName.trim()) {
    setUploadedFiles([
      ...uploadedFiles,
      { name: documentName.trim(), file },
    ]);
    setDocumentName(""); // Limpar o campo do nome do documento
    event.target.value = ""; // Resetar o input de arquivo
  } else {
    alert("Por favor, insira o nome do documento antes de fazer o upload.");
  }
};
const [atende, setAtende] = useState(false);

  const renderTabContent = () => {
    switch (activeTab) {
      case 0:
        return renderInformacoesGerais();
      case 1:
        return renderEntregasMensais();
      case 2:
        return renderPrazosConfiguracoes();
      case 3:
        return renderChecklist();
      case 4:
        return renderDocumentos();
      case 5:
        return renderNotificacoes();
      case 6:
        return renderFinanceiro();  // Chama a nova aba de configuração de entregas
    default:
      return null;
    }
  };

  const categoriasSecundarias = [
    { id: '1', nome: 'Secundária 1' },
    { id: '2', nome: 'Secundária 2' },
    { id: '3', nome: 'Secundária 3' },
  ];

  const categoriasTerciarias = [
    { id: '1', nome: 'Terciária 1' },
    { id: '2', nome: 'Terciária 2' },
    { id: '3', nome: 'Terciária 3' },
  ];
  



  const [formData, setFormData] = useState({
    codigo: '',
    classificacao: '',
    nomeObrigacao: '',
    minimome: '',
    departamento: '',
    tipoServico: '',
    grupoServico: '', // Inicializado como string vazia
  });
  
  const meses = [
    'Janeiro', 'Fevereiro', 'Março', 'Abril', 'Maio', 'Junho',
    'Julho', 'Agosto', 'Setembro', 'Outubro', 'Novembro', 'Dezembro',
  ];

  const departamentos = [
    { id: '1', nome: 'Departamento A' },
    { id: '2', nome: 'Departamento B' },
  ];

  const tiposServico = [
    { id: '1', nome: 'Serviço X' },
    { id: '2', nome: 'Serviço Y' },
  ];

  const gruposServico = [
    { id: '1', nome: 'Grupo 1' },
    { id: '2', nome: 'Grupo 2' },
  ];

  const categoriasPrincipais = [
    { id: '1', nome: 'Financeiro' },
    { id: '2', nome: 'Recursos Humanos' },
  ];
  
  const subcategorias = {
    '1': [
      { id: '1.1', nome: 'Contas a Pagar' },
      { id: '1.2', nome: 'Contas a Receber' },
    ],
    '2': [
      { id: '2.1', nome: 'Recrutamento' },
      { id: '2.2', nome: 'Treinamento' },
    ],
  };

  const handleTabChange = (event, newValue) => setActiveTab(newValue);
  const [activeTab, setActiveTab] = useState(0); // Estado inicial da aba


  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormData((prev) => ({
      ...prev,
      [name]: value !== undefined ? value : '', // Previne valores indefinidos
    }));
  };
  const handleFormat = (valor) => {
    const valorSemPontos = valor.replace(/\./g, '');
    const valorFormatado = valorSemPontos.match(/.{1,2}/g).join('.');
    return valorFormatado;
  };
  

 
  

  const renderInformacoesGerais = () => (
    <Box sx={{ p: 3 }}>
      <Typography
        variant="h6"
        sx={{ mb: 2, color: '#1976d2', fontWeight: 'bold' }}
      >
        Informações Gerais
      </Typography>
      <Grid container spacing={2}>
        {/* Campos Existentes */}
        <Grid item xs={12} sm={4}>
          <TextField
            fullWidth
            label="Código"
            name="codigo"
            value={formData.codigo}
            onChange={handleChange}
            type="text"
            autoComplete="new-password"
            autofill="off"
            spellCheck="false"
            readOnly={true}
            onFocus={(e) => e.target.removeAttribute('readonly')}
            pattern="[0-9]*"
            inputMode="numeric"
            onKeyPress={(e) => {
              if (!/[0-9]/.test(e.key)) {
                e.preventDefault();
              }
            }}
          />
        </Grid>
        <Grid item xs={12} sm={4}>
          <TextField
            fullWidth
            label="Classificação"
            name="classificacao"
            value={formData.classificacao}
            onChange={(e) => {
              const valor = e.target.value;
              setFormData({ ...formData, classificacao: valor });
            }}
            inputComponent={InputMask}
            mask="99.99.99"
            maskChar=" "
            placeholder="00.00.00"
            guide={true}
            onInput={(e) => {
              const valor = e.target.value;
              const valorFormatado = handleFormat(valor);
              e.target.value = valorFormatado;
            }}
            onKeyPress={(e) => {
              if (!/[0-9]/.test(e.key)) {
                e.preventDefault();
              }
            }}
          />
        </Grid>
        <Grid item xs={12} sm={4}>
          <TextField
            fullWidth
            label="Mininome"
            name="minimome"
            value={formData.minimome}
            onChange={handleChange}
          />
        </Grid>
        <Grid item xs={12} sm={12}>
          <TextField
            fullWidth
            label="Nome da Obrigação"
            name="nomeObrigacao"
            value={formData.nomeObrigacao}
            onChange={handleChange}
          />
        </Grid>
  
        {/* Categorias Exclusivas */}
        <Grid item xs={12}>
          <Grid container spacing={2}>
            <Grid item>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={formData.isCategoriaPrincipal}
                    onChange={() => {
                      const isChecked = !formData.isCategoriaPrincipal; // Permite desmarcar
                      setFormData({
                        ...formData,
                        isCategoriaPrincipal: isChecked,
                        isCategoriaSecundaria: false,
                        isCategoriaTerciaria: false,
                        categoriaSecundariaSelecionada: [],
                        categoriaTerciariaSelecionada: [],
                      });
                    }}
                  />
                }
                label="Categoria Principal"
              />
            </Grid>
            <Grid item>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={formData.isCategoriaSecundaria}
                    onChange={() => {
                      const isChecked = !formData.isCategoriaSecundaria; // Permite desmarcar
                      setFormData({
                        ...formData,
                        isCategoriaSecundaria: isChecked,
                        isCategoriaPrincipal: false,
                        isCategoriaTerciaria: false,
                        categoriaSecundariaSelecionada: isChecked ? formData.categoriaSecundariaSelecionada : [],
                      });
                    }}
                  />
                }
                label="Categoria Secundária"
              />
            </Grid>
            <Grid item>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={formData.isCategoriaTerciaria}
                    onChange={() => {
                      const isChecked = !formData.isCategoriaTerciaria; // Permite desmarcar
                      setFormData({
                        ...formData,
                        isCategoriaTerciaria: isChecked,
                        isCategoriaPrincipal: false,
                        isCategoriaSecundaria: false,
                        categoriaTerciariaSelecionada: isChecked ? formData.categoriaTerciariaSelecionada : [],
                      });
                    }}
                  />
                }
                label="Categoria Terciária"
              />
            </Grid>
          </Grid>
        </Grid>
  
        {/* Campos para Categoria Secundária */}
        {formData.isCategoriaSecundaria && (
          <Grid item xs={12}>
            <FormControl fullWidth>
              <InputLabel>Selecione Categorias Secundárias</InputLabel>
              <Select
                name="categoriaSecundariaSelecionada"
                value={formData.categoriaSecundariaSelecionada || []}
                onChange={(e) =>
                  setFormData({
                    ...formData,
                    categoriaSecundariaSelecionada: e.target.value,
                  })
                }
                multiple
              >
                {categoriasSecundarias.map((categoria) => (
                  <MenuItem key={categoria.id} value={categoria.id}>
                    {categoria.nome}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
        )}
  
        {/* Campos para Categoria Terciária */}
        {formData.isCategoriaTerciaria && (
          <Grid item xs={12}>
            <FormControl fullWidth>
              <InputLabel>Selecione Categorias Terciárias</InputLabel>
              <Select
                name="categoriaTerciariaSelecionada"
                value={formData.categoriaTerciariaSelecionada || []}
                onChange={(e) =>
                  setFormData({
                    ...formData,
                    categoriaTerciariaSelecionada: e.target.value,
                  })
                }
                multiple
              >
                {categoriasTerciarias.map((categoria) => (
                  <MenuItem key={categoria.id} value={categoria.id}>
                    {categoria.nome}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
        )}
  
        {/* Outros Campos */}
        <Grid item xs={12} sm={4}>
          <FormControl fullWidth>
            <InputLabel>Departamento</InputLabel>
            <Select
              name="departamento"
              value={formData.departamento}
              onChange={handleChange}
            >
              {departamentos.map((dep) => (
                <MenuItem key={dep.id} value={dep.id}>
                  {dep.nome}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={12} sm={4}>
          <FormControl fullWidth>
            <InputLabel>Tipo do Serviço</InputLabel>
            <Select
              name="tipoServico"
              value={formData.tipoServico}
              onChange={handleChange}
            >
              {tiposServico.map((tipo) => (
                <MenuItem key={tipo.id} value={tipo.id}>
                  {tipo.nome}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={12} sm={4}>
          <FormControl fullWidth>
            <InputLabel>Grupo do Serviço</InputLabel>
            <Select
              name="grupoServico"
              value={formData.grupoServico || ''}
              onChange={handleChange}
            ></Select>
          </FormControl>
        </Grid>
      </Grid>
    </Box>
  );
  
  
  

  const renderEntregasMensais = () => (
    <Box sx={{ p: 3 }}>
      <Typography variant="h6" sx={{ mb: 2, color: '#1976d2', fontWeight: 'bold' }}>
        Entregas Mensais
      </Typography>
      <Grid container spacing={2}>
        {meses.map((mes, index) => (
          <Grid item xs={12} sm={6} md={4} key={index}>
            <FormControl fullWidth>
              <InputLabel>{`Entrega ${mes}`}</InputLabel>
              <Select>
                <MenuItem value="Não tem">Não tem</MenuItem>
                {Array.from({ length: 31 }).map((_, day) => (
                  <MenuItem value={day + 1} key={day}>
                    Todo dia {day + 1}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
        ))}
      </Grid>
    </Box>
  );
  const renderEntregas = () => (
    <Box sx={{ p: 3 }}>
      <Typography variant="h5" sx={{ mb: 3, color: '#1976d2', fontWeight: 'bold' }}>
        Configuração de Entregas
      </Typography>
  
      <Card variant="outlined" sx={{ p: 3, mb: 2, borderRadius: 2 }}>
        
  
        <FormControl component="fieldset" fullWidth>
          <Grid container spacing={2} alignItems="center">
            {/* Opção Recorrente */}
            <Grid item xs={3}>
              <FormControlLabel
                control={
                  <Switch
                    checked={selectedEntregaOption === "recorrente"}
                    onChange={() =>
                      setSelectedEntregaOption((prev) =>
                        prev === "recorrente" ? "" : "recorrente"
                      )
                    }
                  />
                }
                label="Recorrente"
              />
            </Grid>
  
            {/* Opção Não Recorrente */}
            <Grid item xs={3}>
              <FormControlLabel
                control={
                  <Switch
                    checked={selectedEntregaOption === "naoRecorrente"}
                    onChange={() =>
                      setSelectedEntregaOption((prev) =>
                        prev === "naoRecorrente" ? "" : "naoRecorrente"
                      )
                    }
                  />
                }
                label="Não Recorrente"
              />
            </Grid>
  
            {/* Opção Atende */}
            <Grid item xs={3}>
              <FormControlLabel
                control={
                  <Switch
                    checked={selectedEntregaOption === "atende"}
                    onChange={() =>
                      setSelectedEntregaOption((prev) =>
                        prev === "atende" ? "" : "atende"
                      )
                    }
                  />
                }
                label="Atende/Não Atende"
              />
            </Grid>
  
            {/* Opção Não Atende */}
            <Grid item xs={3}>
            
            </Grid>
          </Grid>
  
          {/* Configuração adicional para Recorrente */}
          {selectedEntregaOption === "recorrente" && (
            <Box
              sx={{
                mt: 2,
                p: 2,
                border: "1px solid #ddd",
                borderRadius: 1,
              }}
            >
              <Typography variant="body1" sx={{ mb: 2 }}>
                Defina o intervalo do controle:
              </Typography>
              <Grid container spacing={2}>
                <Grid item xs={6}>
                  <FormControl fullWidth>
                    <InputLabel>Intervalo</InputLabel>
                    <Select
                      value={intervalo}
                      onChange={(e) => setIntervalo(e.target.value)}
                    >
                      <MenuItem value="dias">Dias</MenuItem>
                      <MenuItem value="semanas">Semanas</MenuItem>
                      <MenuItem value="meses">Meses</MenuItem>
                      <MenuItem value="anos">Anos</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    fullWidth
                    label="Quantidade"
                    type="number"
                    value={quantidade}
                    onChange={(e) => setQuantidade(e.target.value)}
                    InputProps={{ inputProps: { min: 1 } }}
                  />
                </Grid>
              </Grid>
            </Box>
          )}
        </FormControl>
      </Card>
    </Box>
  );
  

  const renderChecklist = () => (
    <Box sx={{ p: 3 }}>
      <Typography variant="h6" sx={{ mb: 2, color: "#1976d2", fontWeight: "bold" }}>
        Checklist
      </Typography>
  
      {/* Campo para adicionar novo passo */}
      <Box sx={{ display: "flex", alignItems: "center", mb: 2 }}>
        <TextField
          fullWidth
          label="Adicionar novo passo"
          value={newStep}
          onChange={(e) => setNewStep(e.target.value)}
          placeholder="Descreva o passo a ser adicionado"
          sx={{ mr: 2 }}
        />
        <Button
          variant="contained"
          color="primary"
          onClick={handleAddStep}
          disabled={!newStep.trim()}
        >
          Adicionar
        </Button>
      </Box>
  
      {/* Lista Reordenável */}
      <DragDropContext
        onDragEnd={(result) => {
          if (!result.destination) return;
  
          // Reordenar itens
          const reorderedItems = Array.from(checklistItems);
          const [movedItem] = reorderedItems.splice(result.source.index, 1);
          reorderedItems.splice(result.destination.index, 0, movedItem);
  
          // Atualizar IDs de acordo com a nova ordem
          const updatedItems = reorderedItems.map((item, index) => ({
            ...item,
            displayId: index + 1, // Define o ID exibido na ordem atual
          }));
  
          setChecklistItems(updatedItems);
        }}
      >
        <Droppable droppableId={checklistId}>
          {(provided) => (
            <Box
              {...provided.droppableProps}
              ref={provided.innerRef}
              sx={{
                maxHeight: 400,
                overflowY: "auto",
                backgroundColor: "#f9f9f9",
                border: "1px solid #ddd",
                borderRadius: 1,
                p: 2,
              }}
            >
              {checklistItems.length > 0 ? (
                checklistItems.map((item, index) => (
                  <Draggable key={item.id} draggableId={item.id} index={index}>
                    {(provided) => (
                      <Box
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "space-between",
                          mb: 1,
                          p: 1,
                          backgroundColor: "#fff",
                          borderRadius: 1,
                          boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.1)",
                        }}
                      >
                        {/* Exibe o ID da tarefa */}
                        <Typography sx={{ mr: 2, color: "#1976d2", fontWeight: "bold" }}>
                          {item.displayId}.
                        </Typography>
                        {/* Texto da tarefa */}
                        <Typography sx={{ flex: 1 }}>{item.text}</Typography>
                        {/* Botão de remover tarefa */}
                        <Button
                          variant="outlined"
                          color="secondary"
                          size="small"
                          onClick={() => handleRemoveStep(item.id)}
                        >
                          Remover
                        </Button>
                      </Box>
                    )}
                  </Draggable>
                ))
              ) : (
                <Typography>Nenhum passo adicionado ainda.</Typography>
              )}
              {provided.placeholder}
            </Box>
          )}
        </Droppable>
      </DragDropContext>
    </Box>
  );
  
  

  
  const renderDocumentos = () => {
  
    return (
      <Box sx={{ p: 3 }}>
        <Typography
          variant="h6"
          sx={{ mb: 2, color: "#1976d2", fontWeight: "bold" }}
        >
          Documentos
        </Typography>
  
        {/* Campo Autocomplete com seleção múltipla */}
        <Box sx={{ mb: 3 }}>
          <Autocomplete
            multiple // Habilita a seleção de múltiplos documentos
            options={documentosCadastrados} // Lista de documentos disponíveis
            getOptionLabel={(option) => option.nome || "Documento Desconhecido"} // Nome do documento
            onChange={(event, documentos) => setSelectedDocumentos(documentos)} // Atualiza a lista de documentos selecionados
            renderInput={(params) => (
              <TextField
                {...params}
                label="Pesquisar e Selecionar Documentos"
                variant="outlined"
              />
            )}
            sx={{ width: "100%" }}
          />
        </Box>
  
        {/* Exibição dos documentos selecionados */}
        {selectedDocumentos.length > 0 ? (
          <Box sx={{ mt: 3 }}>
            <Typography variant="subtitle1">
              <strong>Documentos Selecionados:</strong>
            </Typography>
            {selectedDocumentos.map((doc, index) => (
              <Box
                key={doc.id || index}
                sx={{
                  mt: 2,
                  p: 2,
                  border: "1px solid #ddd",
                  borderRadius: 2,
                  backgroundColor: "#f9f9f9",
                }}
              >
                <Typography variant="subtitle1">
                  <strong>Documento:</strong> {doc.nome}
                </Typography>
  
                <Typography variant="subtitle1" sx={{ mt: 1 }}>
                  <strong>Tipo de Documento:</strong> {doc.tipoDocumento || "Desconhecido"}
                </Typography>
  
                {doc.textoExtraido && (
                  <>
                    <Typography variant="subtitle1" sx={{ mt: 1 }}>
                      <strong>Texto Extraído:</strong>
                    </Typography>
                    <Typography sx={{ whiteSpace: "pre-wrap", mt: 1 }}>
                      {doc.textoExtraido.slice(0, 300)}...
                    </Typography>
                  </>
                )}
              </Box>
            ))}
          </Box>
        ) : (
          <Typography sx={{ mt: 3 }}>
            Nenhum documento selecionado ainda.
          </Typography>
        )}
      </Box>
    );
  };
  


  const renderNotificacoes = () => (
    <Box sx={{ p: 3 }}>
      <Typography variant="h6" sx={{ mb: 2, color: '#1976d2', fontWeight: 'bold' }}>
        Notificações
      </Typography>
      <FormControl fullWidth sx={{ mb: 2 }}>
        <InputLabel>Canal de Notificação</InputLabel>
        <Select>
          <MenuItem value="whatsapp">WhatsApp</MenuItem>
          <MenuItem value="email">E-mail</MenuItem>
        </Select>
      </FormControl>
      <FormControl fullWidth>
        <InputLabel>Template</InputLabel>
        <Select>
          <MenuItem value="template1">Template 1</MenuItem>
          <MenuItem value="template2">Template 2</MenuItem>
        </Select>
      </FormControl>
    </Box>
  );
  
  const renderFinanceiro = () => (
    <Box sx={{ p: 3 }}>
      <Typography variant="h6" sx={{ mb: 2, color: '#1976d2', fontWeight: 'bold' }}>
        Financeiro
      </Typography>
      <TextField
        fullWidth
        label="Valor do Serviço"
        type="number"
        sx={{ mb: 2 }}
      />
      <FormControl fullWidth>
        <InputLabel>Tipo de Pagamento</InputLabel>
        <Select>
          <MenuItem value="mensal">Mensal</MenuItem>
          <MenuItem value="unico">Único</MenuItem>
          <MenuItem value="anual">Anual</MenuItem>
        </Select>
      </FormControl>
    </Box>
  );
  

  const renderPrazosConfiguracoes = () => (
    <Box sx={{ p: 3 }}>
      <Typography variant="h6" sx={{ mb: 2, color: '#1976d2', fontWeight: 'bold' }}>
        Prazos e Configurações
      </Typography>
      <Grid container spacing={3} sx={{ mt: 1 }}>
         
        <Grid item xs={12} sm={6} md={4}>
                                        <TextField fullWidth label="Quantidade de dias para antecipar a entrega ao cliente" variant="outlined" />
                                    </Grid>

                                    <Grid item xs={12} sm={6} md={4}>
                                        <TextField fullWidth label="Quantidade de dias para iniciar o serviço" variant="outlined" />
                                    </Grid>

                                    <Grid item xs={12} sm={6} md={4}>
                                        <FormControl fullWidth variant="outlined">
                                            <InputLabel>Tipo dos dias Antes</InputLabel>
                                            <Select label="Tipo dos dias Antes">
                                                <MenuItem value="Dias úteis">Dias úteis</MenuItem>
                                                <MenuItem value="Dias corridos">Dias corridos</MenuItem>
                                            </Select>
                                        </FormControl>
                                    </Grid>

                                    <Grid item xs={12} sm={6} md={4}>
                                        <FormControl fullWidth variant="outlined">
                                            <InputLabel>Prazos fixos em dias não úteis</InputLabel>
                                            <Select label="Prazos fixos em dias não úteis">
                                                <MenuItem value="Antecipar para o dia útil anterior">Antecipar para o dia útil anterior</MenuItem>
                                                <MenuItem value="Postergar para o próximo dia útil">Postergar para o próximo dia útil</MenuItem>
                                                <MenuItem value="Manter o dia exato">Manter o dia exato</MenuItem>
                                            </Select>
                                        </FormControl>
                                    </Grid>

                                    <Grid item xs={12} sm={6} md={2}>
                                        <FormControl fullWidth variant="outlined">
                                            <InputLabel>Sábado é útil?</InputLabel>
                                            <Select label="Sábado é útil?">
                                                <MenuItem value="N">Não</MenuItem>
                                                <MenuItem value="S">Sim</MenuItem>
                                            </Select>
                                        </FormControl>
                                    </Grid>

                                    <Grid item xs={12} sm={6} md={4}>
                                        <FormControl fullWidth variant="outlined">
                                            <InputLabel>Competência referente a</InputLabel>
                                            <Select label="Competência referente a">
                                                <MenuItem value="Mês anterior">Mês anterior</MenuItem>
                                                <MenuItem value="2 meses antes">2 meses antes</MenuItem>
                                                <MenuItem value="3 meses antes">3 meses antes</MenuItem>
                                                <MenuItem value="Ano anterior">Ano anterior</MenuItem>
                                                <MenuItem value="Ano atual">Ano atual</MenuItem>
                                                <MenuItem value="Mês atual">Mês atual</MenuItem>
                                                <MenuItem value="Mês seguinte">Mês seguinte</MenuItem>
                                            </Select>
                                        </FormControl>
                                    </Grid>

                                    <Grid item xs={12} sm={6} md={2}>
                                        <FormControl fullWidth variant="outlined">
                                            <InputLabel>Exigir Robô?</InputLabel>
                                            <Select label="Exigir Robô?">
                                                <MenuItem value="N">Não</MenuItem>
                                                <MenuItem value="S">Sim</MenuItem>
                                            </Select>
                                        </FormControl>
                                    </Grid>

                                    <Grid item xs={12} sm={6} md={2}>
                                        <FormControl fullWidth variant="outlined">
                                            <InputLabel>Passível de multa?</InputLabel>
                                            <Select label="Passível de multa?">
                                                <MenuItem value="N">Não</MenuItem>
                                                <MenuItem value="S">Sim</MenuItem>
                                            </Select>
                                        </FormControl>
                                    </Grid>

                                    <Grid item xs={12} sm={6} md={2}>
                                        <FormControl fullWidth variant="outlined">
                                            <InputLabel>Alerta guia não lida?</InputLabel>
                                            <Select label="Alerta guia não lida?">
                                                <MenuItem value="S">Sim</MenuItem>
                                                <MenuItem value="N">Não</MenuItem>
                                            </Select>
                                        </FormControl>
                                    </Grid>

                                    <Grid item xs={12} sm={6} md={4}>
                                        <FormControl fullWidth variant="outlined">
                                            <InputLabel>Obrigatório Check List?</InputLabel>
                                            <Select label="Obrigatório Check List?">
                                                <MenuItem value="S">Sim</MenuItem>
                                                <MenuItem value="N">Não</MenuItem>
                                            </Select>
                                        </FormControl>
                                    </Grid>

                                    <Grid item xs={12} sm={6} md={4}>
                                        <FormControl fullWidth variant="outlined">
                                            <InputLabel>Esfera</InputLabel>
                                            <Select label="Esfera">
                                                <MenuItem value="Federal">Federal</MenuItem>
                                                <MenuItem value="Estadual">Estadual</MenuItem>
                                                <MenuItem value="Municipal">Municipal</MenuItem>
                                                <MenuItem value="Interno">Interno</MenuItem>
                                                <MenuItem value="Outros">Outros</MenuItem>
                                            </Select>
                                        </FormControl>
                                    </Grid>

                                    <Grid item xs={12} sm={6} md={4}>
                                        <FormControl fullWidth variant="outlined">
                                            <InputLabel>Notificar o Cliente?</InputLabel>
                                            <Select label="Notificar o Cliente?">
                                                <MenuItem value="S">Sim</MenuItem>
                                                <MenuItem value="N">Não</MenuItem>
                                            </Select>
                                        </FormControl>
                                    </Grid>

                                    <Grid item xs={12} sm={6} md={4}>
                                        <FormControl fullWidth variant="outlined">
                                            <InputLabel>Serviço liberado no APP do cliente?</InputLabel>
                                            <Select label="Serviço liberado no APP do cliente?">
                                                <MenuItem value="S">Sim</MenuItem>
                                                <MenuItem value="N">Não</MenuItem>
                                            </Select>
                                        </FormControl>
                                    </Grid>

                                    <Grid item xs={12} sm={6} md={4}>
                                        <FormControl fullWidth variant="outlined">
                                            <InputLabel>Gerar para</InputLabel>
                                            <Select label="Gerar para">
                                                <MenuItem value="Matriz/Filial">Matriz/Filial</MenuItem>
                                                <MenuItem value="Apenas Matriz">Apenas Matriz</MenuItem>
                                                <MenuItem value="Apenas Filial">Apenas Filial</MenuItem>
                                            </Select>
                                        </FormControl>
                                    </Grid>

                                    <Grid item xs={12} sm={6} md={4}>
                                        <FormControl fullWidth variant="outlined">
                                            <InputLabel>Ativa</InputLabel>
                                            <Select label="Ativa">
                                                <MenuItem value="Sim">Sim</MenuItem>
                                                <MenuItem value="Não">Não</MenuItem>
                                            </Select>
                                        </FormControl>
                                    </Grid>

                                    <Grid item xs={12} sm={6} md={8}>
                                        <FormControl fullWidth variant="outlined">
                                            <InputLabel>Baixar automaticamente a tarefa ao concluir as atividades</InputLabel>
                                            <Select label="Ativa">
                                                <MenuItem value="Sim">Sim</MenuItem>
                                                <MenuItem value="Não">Não</MenuItem>
                                            </Select>
                                        </FormControl>
                                    </Grid>

         

        </Grid>
    </Box>
  );

const renderControleTabs = () => (
  <Tabs
    orientation="vertical"
    value={activeTabControle}
    onChange={(e, newValue) => setActiveTabControle(newValue)}
    sx={{
      '.MuiTab-root': {
        textTransform: 'none',
        padding: '12px 16px',
        justifyContent: 'flex-start',
        '&:hover': {
          backgroundColor: '#eee',
        },
      },
      '.Mui-selected': {
        backgroundColor: '#fff',
        color: '#8c52ff',
        fontWeight: 'bold',
      },
    }}
  >
    <Tab icon={<ListAlt />} iconPosition="start" label="Informações Gerais" />
    <Tab icon={<Event />} iconPosition="start" label="Entregas" />
    <Tab icon={<CheckCircle />} iconPosition="start" label="Checklist" />
    <Tab icon={<Folder />} iconPosition="start" label="Documentos" />
    <Tab icon={<Notifications />} iconPosition="start" label="Notificações" />
    <Tab icon={<AttachMoney />} iconPosition="start" label="Financeiro" />

  </Tabs>
);


<Modal
  open={openModal}
  onClose={() => setOpenModal(false)} // Fecha o modal sem confirmar
  aria-labelledby="modal-title"
  aria-describedby="modal-description"
>
  <Box
    sx={{
      position: 'absolute',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
      width: 400,
      bgcolor: 'background.paper',
      boxShadow: 24,
      p: 4,
      borderRadius: 2,
    }}
  >
    <Typography id="modal-title" variant="h6" component="h2" sx={{ mb: 2 }}>
      Atenção!
    </Typography>
    <Typography id="modal-description" sx={{ mb: 3 }}>
      Ao mudar a tarefa, os dados atuais serão perdidos. Deseja continuar?
    </Typography>
    <Box sx={{ display: 'flex', justifyContent: 'flex-end', gap: 2 }}>
      <Button onClick={() => setOpenModal(false)} variant="outlined">
        Cancelar
      </Button>
      <Button
        onClick={() => {
          setSelectedOption(pendingOption); // Atualiza a seleção
          setPendingOption(null); // Limpa a seleção pendente
          setOpenModal(false); // Fecha o modal
        }}
        variant="contained"
        color="error"
      >
        Confirmar
      </Button>
    </Box>
  </Box>
</Modal>





  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Paper sx={{ p: 3, mb: 2, borderRadius: 2 }}>
            <Typography variant="h6" sx={{ mb: 2 }}>
                      Escolha a Tarefa
                    </Typography>
                    <RadioGroup
          row
          value={selectedOption}
          onChange={handleRadioChange}
        >
          <FormControlLabel value="obrigacoes" control={<Radio />} label="Obrigações Recorrentes" />
          <FormControlLabel value="ordem-servico" control={<Radio />} label="Obrigações não recorrentes" />
          <FormControlLabel value="controle" control={<Radio />} label="Controle" />
          
          <FormControlLabel value="parcelamento" control={<Radio />} label="Parcelamento" />
        </RadioGroup>







          </Paper>
        </Grid>

        {selectedOption && (
  <Grid item xs={12}>
    <Box
      sx={{
        display: 'flex',
        boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.1)',
        borderRadius: 2,
        backgroundColor: '#fff',
      }}
    >
      <Box
        sx={{
          minWidth: 240,
          borderRight: '1px solid #ddd',
          backgroundColor: '#f9f9f9',
        }}
      >
        {selectedOption === 'controle' ? (
          renderControleTabs()  // Renderiza abas de Controle
        ) : (
          <Tabs
            orientation="vertical"
            value={activeTab}
            onChange={handleTabChange}
            sx={{
              '.MuiTab-root': {
                textTransform: 'none',
                padding: '12px 16px',
                justifyContent: 'flex-start',
                '&:hover': {
                  backgroundColor: '#eee',
                },
              },
              '.Mui-selected': {
                backgroundColor: '#fff',
                color: '#8c52ff',
                fontWeight: 'bold',
              },
            }}
          >
            <Tab icon={<Person />} iconPosition="start" label="Informações Gerais" />
            <Tab icon={<Event />} iconPosition="start" label="Entregas Mensais" />
            <Tab icon={<Settings />} iconPosition="start" label="Prazos e Configurações" />
            <Tab icon={<CheckCircle />} iconPosition="start" label="Checklist" />
            <Tab icon={<Folder />} iconPosition="start" label="Documentos" />
            <Tab icon={<Notifications />} iconPosition="start" label="Notificações" />
            <Tab icon={<AttachMoney />} iconPosition="start" label="Financeiro" />
          </Tabs>
        )}
      </Box>

      <Box sx={{ flex: 1, p: 3 }}>
        {selectedOption === 'controle' ? (
          <>
          {activeTabControle === 0 && renderInformacoesGerais()}

          {activeTabControle === 1 && renderEntregas()}  {/* Exibe Entregas corretamente */}
          {activeTabControle === 2 && renderChecklist()}  {/* Mantém a consistência com checklist */}
          {activeTabControle === 3 && renderDocumentos()}
          {activeTabControle === 4 && renderNotificacoes()}
          {activeTabControle === 5 && renderFinanceiro()}
        </>
        
        ) : (
          renderTabContent()
        )}
      </Box>
    </Box>
  </Grid>
)}




      </Grid>

      <Grid container justifyContent="flex-end" sx={{ mt: 2 }}>
  <Button variant="contained" color="primary" onClick={handleSubmit}>
    Salvar Obrigação
  </Button>
</Grid>

    </Box>

    
  );
};

export default NovaTarefa;

