import React, { useState, useEffect  } from 'react';
import {
  Box, Tabs, Tab, TextField, Button, Chip, Typography, Grid, MenuItem, Select, IconButton, Dialog, DialogActions, DialogContent, Table, TableBody, TableCell, 
  TableContainer, TableHead, TableRow, DialogContentText, Paper, Tooltip, DialogTitle
} from '@mui/material';
import { Person, Info, Contacts, Search, Note, Share, People, ListAlt, Add } from '@mui/icons-material';
import axios from 'axios'; // Importar Axios

import InputMask from 'react-input-mask'; // Importação da máscara
import { Add as AddIcon } from '@mui/icons-material';
import { Snackbar, Alert } from '@mui/material';
import { Checkbox, TablePagination } from '@mui/material';
import { FilterList as FilterListIcon } from '@mui/icons-material';
import { Edit as EditIcon, Visibility as VisibilityIcon, Delete as DeleteIcon } from '@mui/icons-material';
import { Breadcrumbs, Link } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { Close } from '@mui/icons-material';
import { useParams } from "react-router-dom";

import AttachFileIcon from '@mui/icons-material/AttachFile';
import DescriptionIcon from '@mui/icons-material/Description';
import SaveIcon from '@mui/icons-material/Save';
// Adicione esta linha junto com os outros imports do Material-UI


// Defina a URL base da API

const usuarioNome = JSON.parse(localStorage.getItem('usuario'))?.nome || 'Usuário Desconhecido';

const API_BASE_URL = process.env.REACT_APP_API_URL; // sem '/api' no final
const NovoCliente = ({ 
  
  isEdit = false, 
  formData: externalFormData = {}, 
  setFormData: externalSetFormData = null, 
  handleSave: externalHandleSave = null, 
  readOnly = false,
  onAnotacaoSave,
  onAnotacaoRemove,
  onAnotacaoAdd,
  onInscricaoSave,
  onInscricaoRemove,
  onInscricaoAdd
}) => {

  
  const [formData, setFormData] = useState(() => ({
    nome: externalFormData.nome || '',
    nomeFantasia: externalFormData.nomeFantasia || '',
    apelido: externalFormData.apelido || '',
    cpfCnpj: externalFormData.cpfCnpj || '',
    inscricaoEstadual: externalFormData.inscricaoEstadual || '',
    inscricaoMunicipal: externalFormData.inscricaoMunicipal || '',
    cei: externalFormData.cei || '',
    cep: externalFormData.cep || '',
    logradouro: externalFormData.logradouro || '',
    numero: externalFormData.numero || '',
    complemento: externalFormData.complemento || '',
    bairro: externalFormData.bairro || '',
    uf: externalFormData.uf || '',  // Inicializa como string vazia
    cidade: externalFormData.cidade || '',
    website: externalFormData.website || '',
    honorario: externalFormData.honorario || '',
    classificacao: externalFormData.classificacao || [],
 
    codigoErp: externalFormData.codigoErp || '', // Inicializa com externalFormData
    contatos: Array.isArray(externalFormData.contatos) ? externalFormData.contatos : [],
    termos: [], 

    responsaveis: externalFormData.responsaveis || [{ usuarioId: '', departamentoId: '' }], 
    inscricoes: externalFormData.inscricoes || [], 
    redesSociais: externalFormData.redesSociais || [],
    anotacoes: [], // Inicialize como um array vazio
 
  }));

const resetForm = () => {
  setFormData({
      nome: '',
      nomeFantasia: '',
      apelido: '',
      cpfCnpj: '',
      inscricaoEstadual: '',
      inscricaoMunicipal: '',
      cei: '',
      cep: '',
      logradouro: '',
      numero: '',
      complemento: '',
      bairro: '',
      uf: '',
      cidade: '',
      website: '',
      honorario: '',
      codigoErp: '',
      responsaveis: [{ usuarioId: '', departamentoId: '' }],
      inscricoes: [],
      redesSociais: [],
      contatos: [],
  });

  setTags([]); // Limpa as tags
  setContatos([{ nome: '', cargo: '', celular: '', email: '', tipoEmail: '', anotacoes: '' }]); // Reseta os contatos
};


const showSnackbar = (message, severity = 'success') => {
  setSnackbarMessage(message);
  setSnackbarSeverity(severity);
  setSnackbarOpen(true);
};


  const [isLoading, setIsLoading] = useState(false);
  const [openAlert, setOpenAlert] = useState(true);

  const [activeTab, setActiveTab] = useState(0); // Controle da aba ativa
  const navigate = useNavigate();

  
  const handleBack = () => {
    navigate('/clientes');
  };


  
  useEffect(() => {
    if (isEdit && externalFormData) {
      setFormData((prev) => ({
        ...prev, // Mantém os valores anteriores
        ...externalFormData,
        cpfCnpj: externalFormData.cpfCnpj || externalFormData.cpf_cnpj || '',
        nomeFantasia: externalFormData.nomeFantasia !== undefined 
          ? externalFormData.nomeFantasia 
          : (prev.nomeFantasia || ''), // Adicionados parênteses
        codigoErp: externalFormData.codigoErp || externalFormData.codigo_erp || '',
        website: externalFormData.website !== undefined 
          ? externalFormData.website 
          : (prev.website || ''), // Adicionados parênteses
        contatos: Array.isArray(externalFormData.contatos)
          ? externalFormData.contatos
          : (prev.contatos || []), // Adicionados parênteses
        anotacoes: externalFormData.anotacoes || prev.anotacoes || [
          { descricao: '', departamento: '', arquivoUrl: '' },
        ],
        inscricoes: externalFormData.inscricoes || prev.inscricoes || [
          { tipo: '', numero: '', identificacao: '', status: '' },
        ],
        redesSociais: externalFormData.redesSociais || prev.redesSociais || [
          { nome: '', url: '' },
        ],
        responsaveis: externalFormData.responsaveis || prev.responsaveis || [
          { usuarioId: '', departamentoId: '' },
        ],
        classificacao: externalFormData.classificacao || prev.classificacao || {
          statusCliente: '',
          statusComplementar: '',
          tipoCliente: [],
          categoriaCliente: '',
          grupoCliente: '',
          segmento: [],
          escritorio: '',
        },
        camposPesquisa: externalFormData.camposPesquisa || prev.camposPesquisa || [],
      }));
    }
  }, [isEdit, externalFormData]);
  
  

  
  

  const validateFormData = () => {
    const { nome, cpfCnpj, codigoErp, cep, cidade, uf } = formData;
  
    if (!nome || !cpfCnpj || !codigoErp) {
      showSnackbar("Nome, CPF/CNPJ e Código ERP são obrigatórios.", "error");
      return false;
    }
  
    if (!cep || !cidade || !uf) {
      showSnackbar("CEP, Cidade e UF são obrigatórios.", "error");

      return false;
    }
  
    if (cpfCnpj.replace(/\D/g, '').length < 11) {
      showSnackbar("O CPF/CNPJ deve ser válido.", "error");

      return false;
    }
  
    return true;
  }; 

  // Estado para armazenar os dados selecionados e a tabela visível
  const [snackbarOpen, setSnackbarOpen] = useState(false);
const [snackbarMessage, setSnackbarMessage] = useState('');
const [snackbarSeverity, setSnackbarSeverity] = useState('success'); // 'success', 'error', 'warning', 'info'

const [atividadeSelecionada, setAtividadeSelecionada] = useState('');
const [page, setPage] = useState(0);
const [rowsPerPage, setRowsPerPage] = useState(5);
const [rows, setRows] = useState([
  {
    dataCadastro: '22/10/2024',
    competencia: '10/2024',
    regimeFederal: 'Simples Nacional',
    regimeEstadual: 'Regime Normal',
    regimeMunicipal: 'ISS Fixo',
  },
  {
    dataCadastro: '23/11/2024',
    competencia: '11/2024',
    regimeFederal: 'Lucro Presumido',
    regimeEstadual: 'Simples Nacional',
    regimeMunicipal: 'ISS Variável',
  },
]);
const [usuarios, setUsuarios] = useState([]); // Armazena usuários
const [departamentos, setDepartamentos] = useState([]); // Armazena departamentos

const handleResponsavelChange = (index, field, value) => {
  const novosResponsaveis = [...formData.responsaveis];
  novosResponsaveis[index][field] = value;
  setFormData((prev) => ({
    ...prev,
    responsaveis: novosResponsaveis,
  }));
};


const handleAddResponsavel = () => {
  setFormData((prevFormData) => ({
    ...prevFormData,
    responsaveis: [
      ...prevFormData.responsaveis,
      { usuarioId: '', departamentoId: '' },
    ],
  }));
};

const fetchUsuariosEDepartamentos = async () => {
  try {
    const token = localStorage.getItem('token');
    const response = await axios.get(`${API_BASE_URL}/api/departamento/usuarios-departamentos`, {
      headers: { Authorization: `Bearer ${token}` },
    });

    setUsuarios(response.data.usuarios); // Atualiza usuários
    setDepartamentos(response.data.departamentos); // Atualiza departamentos
  } catch (error) {
    console.error('Erro ao buscar usuários e departamentos:', error);
  }
};

// Chame a função no useEffect
useEffect(() => {
  fetchUsuariosEDepartamentos();
}, []);



const handleEditRow = (index) => {
  console.log('Editar linha:', index);
  // Implemente a lógica de edição
};

const handleViewRow = (index) => {
  console.log('Visualizar linha:', index);
  // Implemente a lógica de visualização
};

const handleDeleteRow = (index) => {
  console.log('Excluir linha:', index);
  // Implemente a lógica de exclusão
};


// Dados fictícios para a tabela
const dadosTabela = [
  {
    codigo: '001',
    classificacao: 'Classificação 1',
    nome: 'Obrigação 1',
    mininome: 'Obr1',
    departamento: 'Departamento A',
    tipo: 'Tipo A',
    grupo: 'Grupo A',
  },
  {
    codigo: '002',
    classificacao: 'Classificação 2',
    nome: 'Obrigação 2',
    mininome: 'Obr2',
    departamento: 'Departamento B',
    tipo: 'Tipo B',
    grupo: 'Grupo B',
  },
];

useEffect(() => {
  const token = localStorage.getItem("token");
  if (!token) {
    showSnackbar("Token não encontrado. Faça login novamente.", "error");
    return;
  }
  
}, []);

useEffect(() => {
  const fetchData = async () => {
    try {
      const response = await axios.get(`${API_BASE_URL}/api/opcoes-status`);
      setOpcoesStatus(response.data); // Supondo que o backend retorne um objeto com as opções
    } catch (error) {
      console.error("Erro ao buscar opções do backend:", error);
    }
  };

  fetchData();
}, []);




const handleSaveContato = async (contato, index) => {
  try {
    const token = localStorage.getItem("token");
    if (!token) throw new Error("Token não encontrado.");

    const clienteId = formData?.id;
    if (!clienteId) throw new Error("ID do cliente não definido.");

    const url = contato.id
      ? `${process.env.REACT_APP_API_URL}/api/clientes/contatos/${contato.id}` // Atualizar contato
      : `${process.env.REACT_APP_API_URL}/api/clientes/${clienteId}/contatos`; // Criar novo contato

    const method = contato.id ? "put" : "post";

    console.log(`Enviando ${method.toUpperCase()} para:`, url);

    // Envia a requisição ao backend
    const response = await axios[method](url, contato, {
      headers: { Authorization: `Bearer ${token}` },
    });

    const atualizado = response.data;

    // Atualiza o estado local
    setFormData((prevFormData) => {
      const novosContatos = [...prevFormData.contatos];
      novosContatos[index] = atualizado;
      return { ...prevFormData, contatos: novosContatos };
    });

    alert("Contato salvo com sucesso!");
  } catch (error) {
    console.error("Erro ao salvar contato:", error);
    alert("Erro ao salvar contato. Verifique os dados e tente novamente.");
  }
};







const handleAddTermo = () => {
  if (!tagInput.trim()) {
    alert("O termo não pode estar vazio!");
    return;
  }
  
  setFormData((prevFormData) => ({
    ...prevFormData,
    termos: [...prevFormData.termos, { termo: tagInput }],
  }));
  setTagInput("");
};

const handleDeleteTermo = (id, index) => {
  const updatedTermos = [...formData.termos];
  updatedTermos.splice(index, 1);
  setFormData((prevFormData) => ({
    ...prevFormData,
    termos: updatedTermos,
  }));
};






const handleDeleteAnotacao = async (anotacaoId) => {
  try {
      await axios.delete(`${process.env.REACT_APP_API_URL}/api/anotacoes/${anotacaoId}`, {
          headers: { Authorization: `Bearer ${token}` },
      });

      alert("Anotação removida com sucesso!");
      // Atualizar estado local
  } catch (error) {
      console.error("Erro ao remover anotação:", error);
      alert("Erro ao remover anotação.");
  }
};


const handleRemoveAnotacao = async (index) => {
  try {
    const anotacaoId = formData.anotacoes[index]?.id;
    if (anotacaoId) {
      const token = localStorage.getItem("token");
      await axios.delete(`${process.env.REACT_APP_API_URL}/api/anotacoes/${anotacaoId}`, {
        headers: { Authorization: `Bearer ${token}` },
      });
    }
    setFormData((prevFormData) => {
      const updatedAnotacoes = [...prevFormData.anotacoes];
      updatedAnotacoes.splice(index, 1);
      return { ...prevFormData, anotacoes: updatedAnotacoes };
    });
    alert("Anotação removida com sucesso!");
  } catch (error) {
    console.error("Erro ao remover anotação:", error.message);
    alert("Erro ao remover anotação.");
  }
};

const handleUploadArquivo = async (index, file) => {
  try {
    const token = localStorage.getItem("token");
    const clienteId = formData?.id;

    if (!clienteId) {
      alert("Salve o cliente antes de anexar arquivos.");
      return;
    }

    const formDataFile = new FormData();
    formDataFile.append("file", file);
    formDataFile.append("descricao", formData.anotacoes[index].descricao); // Adicione campos necessários
    formDataFile.append("departamento", formData.anotacoes[index].departamento);

    const response = await axios.post(
      `${process.env.REACT_APP_API_URL}/api/clientes/${clienteId}/anotacoes`,
      formDataFile,
      {
        headers: { 
          Authorization: `Bearer ${token}`,
          "Content-Type": "multipart/form-data",
        },
      }
    );

    // Atualize o estado conforme necessário
  } catch (error) {
    console.error("Erro ao anexar arquivo:", error);
  }
};

const handleAddAnotacao = () => {
  setFormData((prevFormData) => {
    const novasAnotacoes = [
      ...(Array.isArray(prevFormData.anotacoes) ? prevFormData.anotacoes : []),
      { descricao: "", departamento: "", usuario_id: "", arquivoUrl: "" }, // Nova anotação vazia
    ];

    console.log("Novas anotações:", novasAnotacoes); // 🔥 Debug para garantir atualização

    return {
      ...prevFormData,
      anotacoes: novasAnotacoes,
    };
  });
};








const handleAnotacaoChange = (index, field, value) => {
  const novasAnotacoes = [...formData.anotacoes];
  novasAnotacoes[index][field] = value;

  setFormData((prevFormData) => ({
    ...prevFormData,
    anotacoes: novasAnotacoes,
  }));
};


const handleSaveAnotacoes = async () => {
  try {
    const token = localStorage.getItem('token');
    const payload = { anotacoes: formData.anotacoes };

    await axios.post(`${API_BASE_URL}/api/anotacoes`, payload, {
      headers: { Authorization: `Bearer ${token}` },
    });

    alert('Anotações salvas com sucesso!');
  } catch (error) {
    console.error('Erro ao salvar anotações:', error);
    alert('Erro ao salvar anotações.');
  }
};



// Handlers para paginação
const handleChangePage = (event, newPage) => setPage(newPage);
const handleChangeRowsPerPage = (event) =>
  setRowsPerPage(parseInt(event.target.value, 10));

// Handler para mostrar a tabela ao selecionar
const handleAtividadeSelecionada = (event) => {
  const value = event.target.value;
  setAtividadeSelecionada(value);
  setRows(dadosTabela); // Preenche a tabela com os dados fictícios ao selecionar
};
const handleSaveCliente = async () => {
  try {
    const token = localStorage.getItem('token');
    if (!token) {
      setSnackbarMessage('Token não encontrado. Faça login novamente.');
      setSnackbarSeverity('error');
      setSnackbarOpen(true);
      return;
    }

    // Filtrar objetos vazios
    const filterEmptyObjects = (data) =>
      data.filter((item) =>
        Object.values(item).some((value) => value !== null && value !== undefined && value !== '')
      );

    // Montar o payload completo
    const payload = {
      cliente: {
        nome: formData.nome,
        cpf_cnpj: formData.cpfCnpj.replace(/\D/g, ''),
        codigo_erp: formData.codigoErp,
        nome_fantasia: formData.nomeFantasia,
        apelido: formData.apelido,
        cep: formData.cep,
        logradouro: formData.logradouro,
        numero: formData.numero,
        complemento: formData.complemento,
        bairro: formData.bairro,
        uf: formData.uf,
        cidade: formData.cidade,
        website: formData.website,
        honorario: formData.honorario?.trim()
          ? parseFloat(formData.honorario.replace(/[^0-9,]/g, '').replace(',', '.'))
          : null,
        inscricao_estadual: formData.inscricaoEstadual,
        inscricao_municipal: formData.inscricaoMunicipal,
        cei: formData.cei,
      },
      classificacao: {
        status_cliente: formData.classificacao?.statusCliente || null,
        status_complementar: formData.classificacao?.statusComplementar || null,
        tipo_cliente: formData.classificacao?.tipoCliente || null,
        categoria_cliente: formData.classificacao?.categoriaCliente || null,
        grupo_cliente: formData.classificacao?.grupoCliente || null,
        segmento: formData.classificacao?.segmento || null,
        escritorio: formData.classificacao?.escritorio || null,
      },
      inscricoes: filterEmptyObjects(formData.inscricoes || []),
      contatos: filterEmptyObjects(
        formData.contatos?.map((contato) => ({
          nome: contato.nome || '',
          cargo: contato.cargo || null,
          celular: contato.celular || null,
          email: contato.email || null,
          departamento: contato.departamento || 'Outro',
          anotacoes: contato.anotacoes || null,
        })) || []
      ),
      termos: formData.termos.map((termo) => ({
        termo: termo.termo,
      })),
      
      
      anotacoes: formData.anotacoes.map((anotacao) => ({
        descricao: anotacao.descricao,
        departamento: anotacao.departamento,
        usuario_id: anotacao.usuario_id,
        arquivoUrl: anotacao.arquivoUrl,
      })),


      redes: filterEmptyObjects(
        formData.redesSociais?.map((rede) => ({
          opcao: rede.nome || 'Outros',
          url: rede.url || '',
        })) || []
      ),
      responsaveis: filterEmptyObjects(
        formData.responsaveis?.map((responsavel) => ({
          usuario_id: responsavel.usuarioId || null,
          departamento_id: responsavel.departamentoId || null,
          nome: responsavel.nome || '',
          cargo: responsavel.cargo || null,
        })) || []
      ),
    };

    console.log('Payload enviado ao backend:', payload);

    // Enviar payload ao back-end
    const response = await axios.post(`${process.env.REACT_APP_API_URL}/api/clientes`, payload, {
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
    });

    const clienteId = response.data.clienteId;

    // Atualizar o estado com o clienteId
    setFormData((prevFormData) => ({
      ...prevFormData,
      id: clienteId,
    }));

    setSnackbarMessage('Cliente salvo com sucesso!');
    setSnackbarSeverity('success');
    setSnackbarOpen(true);
    console.log('Resposta do backend:', response.data);

    resetForm(); // Reseta o formulário após salvar
  } catch (error) {
    console.error('Erro ao salvar cliente:', error.response?.data || error.message);
    setSnackbarMessage('Erro ao salvar cliente.');
    setSnackbarSeverity('error');
    setSnackbarOpen(true);
  }
};

const handleSave = async () => {
  if (!validateFormData()) return;

  setIsLoading(true);

  try {
    const token = localStorage.getItem("token");
    if (!token) {
      alert("Token não encontrado. Faça login novamente.");
      return;
    }

    const filterEmptyObjects = (data) =>
      data.filter((item) =>
        Object.values(item).some((value) => value !== null && value !== undefined && value !== "")
      );

    const payload = {
      cliente: {
        nome: formData.nome,
        cpf_cnpj: formData.cpfCnpj.replace(/\D/g, ""),
        codigo_erp: formData.codigoErp,
        nome_fantasia: formData.nomeFantasia,
        apelido: formData.apelido,
        cep: formData.cep,
        logradouro: formData.logradouro,
        numero: formData.numero,
        complemento: formData.complemento,
        bairro: formData.bairro,
        uf: formData.uf,
        cidade: formData.cidade,
        website: formData.website,
        honorario: formData.honorario?.trim()
          ? parseFloat(formData.honorario.replace(/[^0-9,]/g, "").replace(",", "."))
          : null,
      },
      classificacao: {
        status_cliente: formData.classificacao?.statusCliente || null,
        status_complementar: formData.classificacao?.statusComplementar || null,
        tipo_cliente: formData.classificacao?.tipoCliente?.join(",") || null,
        categoria_cliente: formData.classificacao?.categoriaCliente || null,
        grupo_cliente: formData.classificacao?.grupoCliente || null,
        segmento: formData.classificacao?.segmento?.join(",") || null,
        escritorio: formData.classificacao?.escritorio || null,
      },
      inscricoes: filterEmptyObjects(formData.inscricoes || []),
      contatos: filterEmptyObjects(formData.contatos || []),
      termos: filterEmptyObjects(formData.termos || []),
      anotacoes: filterEmptyObjects(formData.anotacoes || []),
      redes: filterEmptyObjects(formData.redesSociais || []),
      responsaveis: filterEmptyObjects(formData.responsaveis || []),
    };

    const response = await axios.post(`${process.env.REACT_APP_API_URL}/api/clientes`, payload, {
      headers: { Authorization: `Bearer ${token}`, "Content-Type": "application/json" },
    });

    const clienteId = response.data.clienteId;

    setFormData((prevFormData) => ({
      ...prevFormData,
      id: clienteId,
    }));

    alert("Cliente salvo com sucesso!");
  } catch (error) {
    console.error("Erro ao salvar cliente:", error);
    alert("Erro ao salvar cliente: " + error.message);
  } finally {
    setIsLoading(false);
  }
};





const token = localStorage.getItem("token");
if (!token) {
  alert("Token não encontrado. Faça login novamente.");
  window.location.href = "/login";
}



   // Definindo o estado de contatos
   const [contatos, setContatos] = useState([
    { nome: '', cargo: '', celular: '', email: '', tipoEmail: '', anotacoes: '' }
  ]);

  const handleAddContato = () => {
    setFormData((prev) => ({
      ...prev,
      contatos: [
        ...prev.contatos,
        { nome: '', cargo: '', celular: '', email: '', tipoEmail: '', anotacoes: '' },
      ],
    }));
  };
  
  
  const handleRemoveContato = async (index) => {
    try {
        const contatoId = formData.contatos[index]?.id;

        if (!contatoId) {
            alert("Contato não possui ID para exclusão.");
            return;
        }

        const token = localStorage.getItem("token");
        await axios.delete(`${process.env.REACT_APP_API_URL}/api/clientes/contatos/${contatoId}`, {
          headers: { Authorization: `Bearer ${token}` },
        });

        // Atualize a lista de contatos localmente
        setFormData((prevFormData) => ({
            ...prevFormData,
            contatos: prevFormData.contatos.filter((_, i) => i !== index),
        }));

        alert("Contato removido com sucesso!");
    } catch (error) {
        console.error("Erro ao excluir contato:", error);
        alert("Erro ao excluir contato.");
    }
};



const handleSaveCampoPesquisa = async (campo, index) => {
  try {
    const token = localStorage.getItem("token");
    if (!token) throw new Error("Token não encontrado.");

    const clienteId = formData?.id;
    if (!clienteId) throw new Error("ID do cliente não definido.");

    const url = campo.id
      ? `${process.env.REACT_APP_API_URL}/api/clientes/termos/${campo.id}` // Atualizar termo existente
      : `${process.env.REACT_APP_API_URL}/api/clientes/${clienteId}/termos`; // Adicionar novo termo

    const method = campo.id ? "put" : "post";

    const response = await axios[method](url, { termo: campo.termo }, {
      headers: { Authorization: `Bearer ${token}` },
    });

    const atualizado = response.data;

    setFormData((prevFormData) => {
      const novosCampos = [...prevFormData.termos];
      novosCampos[index] = atualizado;
      return { ...prevFormData, termos: novosCampos };
    });

    alert("Campo salvo com sucesso!");
  } catch (error) {
    console.error("Erro ao salvar campo:", error);
    alert("Erro ao salvar campo. Verifique os dados e tente novamente.");
  }
};

const handleAddTag = async () => {
  try {
    const token = localStorage.getItem("token");
    if (!token) throw new Error("Token não encontrado.");

    const clienteId = formData?.id;
    if (!clienteId) throw new Error("ID do cliente não definido.");

    const response = await axios.post(
      `${process.env.REACT_APP_API_URL}/api/clientes/${clienteId}/termos`,
      { termo: tagInput.trim() },
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    );

    // Atualiza o estado local
    setFormData((prevFormData) => ({
      ...prevFormData,
      termos: [...prevFormData.termos, response.data],
    }));

    setTagInput(''); // Limpa o campo de entrada
  } catch (error) {
    console.error("Erro ao adicionar termo:", error);
    alert("Erro ao adicionar termo. Tente novamente.");
  }
};

const handleDeleteTag = async (id, index) => {
  try {
    if (!id) {
      setFormData((prevFormData) => ({
        ...prevFormData,
        termos: prevFormData.termos.filter((_, i) => i !== index),
      }));
      return;
    }

    const token = localStorage.getItem("token");
    if (!token) throw new Error("Token não encontrado.");

    await axios.delete(
      `${process.env.REACT_APP_API_URL}/api/clientes/termos/${id}`,
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    );

    setFormData((prevFormData) => ({
      ...prevFormData,
      termos: prevFormData.termos.filter((_, i) => i !== index),
    }));

    alert("Termo removido com sucesso!");
  } catch (error) {
    console.error("Erro ao remover termo:", error);
    alert("Erro ao remover termo. Tente novamente.");
  }
};






  
  const [openModal, setOpenModal] = useState(false); // Estado para controlar a abertura do modal
  const [cnpjTimeout, setCnpjTimeout] = useState(null); // Timeout para CNPJ
  const estadosBrasileiros = [
    { sigla: 'AC', nome: 'Acre' },
    { sigla: 'AL', nome: 'Alagoas' },
    { sigla: 'AP', nome: 'Amapá' },
    { sigla: 'AM', nome: 'Amazonas' },
    { sigla: 'BA', nome: 'Bahia' },
    { sigla: 'CE', nome: 'Ceará' },
    { sigla: 'DF', nome: 'Distrito Federal' },
    { sigla: 'ES', nome: 'Espírito Santo' },
    { sigla: 'GO', nome: 'Goiás' },
    { sigla: 'MA', nome: 'Maranhão' },
    { sigla: 'MT', nome: 'Mato Grosso' },
    { sigla: 'MS', nome: 'Mato Grosso do Sul' },
    { sigla: 'MG', nome: 'Minas Gerais' },
    { sigla: 'PA', nome: 'Pará' },
    { sigla: 'PB', nome: 'Paraíba' },
    { sigla: 'PR', nome: 'Paraná' },
    { sigla: 'PE', nome: 'Pernambuco' },
    { sigla: 'PI', nome: 'Piauí' },
    { sigla: 'RJ', nome: 'Rio de Janeiro' },
    { sigla: 'RN', nome: 'Rio Grande do Norte' },
    { sigla: 'RS', nome: 'Rio Grande do Sul' },
    { sigla: 'RO', nome: 'Rondônia' },
    { sigla: 'RR', nome: 'Roraima' },
    { sigla: 'SC', nome: 'Santa Catarina' },
    { sigla: 'SP', nome: 'São Paulo' },
    { sigla: 'SE', nome: 'Sergipe' },
    { sigla: 'TO', nome: 'Tocantins' },
  ];


  const [tags, setTags] = useState([]); // Estado para tags
  const [tagInput, setTagInput] = useState(''); // Estado para input de tags

   // Função para adicionar tag ao pressionar Enter
   const handleKeyDown = (e) => {
    if (e.key === 'Enter' && tagInput.trim() !== '') {
      e.preventDefault();
      setTags([...tags, tagInput.trim()]);
      setTagInput('');
    }
  };

 
// Função para adicionar uma nova rede social
const handleAddRedeSocial = () => {
  setFormData((prevFormData) => ({
    ...prevFormData,
    redesSociais: [...(prevFormData.redesSociais || []), { nome: '', url: '' }]
  }));
};

// Função para alterar os valores das redes sociais
const handleRedeSocialChange = (index, field, value) => {
  const novasRedes = [...formData.redesSociais];
  novasRedes[index][field] = value;
  setFormData((prev) => ({
    ...prev,
    redesSociais: novasRedes,
  }));
};


// Função para remover uma rede social
const handleRemoveRedeSocial = (index) => {
  setFormData((prevFormData) => ({
    ...prevFormData,
    redesSociais: prevFormData.redesSociais.filter((_, i) => i !== index)
  }));
};

  

  const [opcoesStatus, setOpcoesStatus] = useState({
    tipoCliente: [],
    statusCliente: [],
    segmento: [],
    grupoCliente: [],
    statusComplementar: [],
    escritorio: [],
    categoriaCliente: []
  });
  const [campoSelecionado, setCampoSelecionado] = useState(''); // Guarda o campo a ser adicionado
  const [novoCampo, setNovoCampo] = useState(''); // Guarda o valor do novo campo
  


  
  const handleContatoChange = (index, field, value) => {
    setFormData((prevFormData) => {
      const novosContatos = [...prevFormData.contatos];
      novosContatos[index] = { ...novosContatos[index], [field]: value };
      return { ...prevFormData, contatos: novosContatos };
    });
  };
  
  
  
  
  
  useEffect(() => {
    if (activeTab === 1) {
      fetchStatusData(); // Chama a função apenas quando a aba Status for ativada
    }
  }, [activeTab]);

  
  // useEffect para buscar o CNPJ quando o campo tiver exatamente 14 dígitos
 useEffect(() => {
  if (!isEdit && formData.cpfCnpj.length === 14) {
    fetchCnpjData(formData.cpfCnpj);  // Faz a requisição quando o CNPJ está completo
    }
  }, [formData.cpfCnpj, isEdit]);
  
  // Função para lidar com mudanças no campo de texto
  const handleChange = (e) => {
    const { name, value } = e.target;
  
    // Verifica se o campo é "tipoCliente" ou "segmento" para permitir múltipla escolha
    if (name === 'tipoCliente' || name === 'segmento') {
      setFormData((prevFormData) => ({
        ...prevFormData,
        [name]: typeof value === 'string' ? value.split(',') : value, // Garante array
      }));
    } else {
      // Comportamento padrão para outros campos
      setFormData({
        ...formData,
        [name]: value,
      });
    }
  };
  

  

  const fetchStatusData = async () => {
    try {
      const token = localStorage.getItem('token'); // Obter o token JWT
  
       // Fazer múltiplas requisições simultaneamente usando Promise.all
    const [
      tipoCliente, statusCliente, segmento, grupoCliente,
      statusComplementar, escritorio, categoriaCliente
    ] = await Promise.all([
      axios.get(`${process.env.REACT_APP_API_URL}/api/tipo-cliente`, { headers: { Authorization: `Bearer ${token}` } }),
axios.get(`${process.env.REACT_APP_API_URL}/api/status-clientes`, { headers: { Authorization: `Bearer ${token}` } }),
axios.get(`${process.env.REACT_APP_API_URL}/api/segmento-empresa`, { headers: { Authorization: `Bearer ${token}` } }),
axios.get(`${process.env.REACT_APP_API_URL}/api/grupo-clientes`, { headers: { Authorization: `Bearer ${token}` } }),
axios.get(`${process.env.REACT_APP_API_URL}/api/status-complementares`, { headers: { Authorization: `Bearer ${token}` } }),
axios.get(`${process.env.REACT_APP_API_URL}/api/escritorios`, { headers: { Authorization: `Bearer ${token}` } }),
axios.get(`${process.env.REACT_APP_API_URL}/api/categorias-clientes`, { headers: { Authorization: `Bearer ${token}` } }),

    ]);
      
  
      // Atualizar o estado com os dados recebidos
      setOpcoesStatus({
        tipoCliente: tipoCliente.data,
        statusCliente: statusCliente.data,
        segmento: segmento.data,
        grupoCliente: grupoCliente.data,
        statusComplementar: statusComplementar.data,
        escritorio: escritorio.data,
        categoriaCliente: categoriaCliente.data,
      });
    } catch (error) {
      console.error('Erro ao buscar dados de status:', error);
    }
  };
  
  // Função para formatar o valor em moeda brasileira
const formatCurrency = (value) => {
  const numberValue = Number(value) / 100; // Divide por 100 para ajustar casas decimais
  return new Intl.NumberFormat('pt-BR', {
    style: 'currency',
    currency: 'BRL',
  }).format(numberValue || 0);
};
  




const [openNovaVigencia, setOpenNovaVigencia] = useState(false);

  // Lida com a formatação de moeda em tempo real para o campo "Honorário"
  const handleCurrencyChange = (e) => {
    const { value } = e.target;
  
    // Remove tudo que não for número
    const numericValue = value.replace(/\D/g, '');
  
    // Formata o número em moeda brasileira
    const formattedValue = new Intl.NumberFormat('pt-BR', {
      style: 'currency',
      currency: 'BRL',
    }).format(numericValue / 100);
  
    // Atualiza o estado com o valor bruto e formatado
    setFormData({
      ...formData,
      honorario: formattedValue,
    });
  };
  

  // Função para buscar o CNPJ usando JSONP
  const fetchCnpjData = (cnpj) => {
    const script = document.createElement('script');
    const callbackName = `jsonp_callback_${Date.now()}`;

    // Define a função de callback globalmente
    window[callbackName] = (data) => {
      if (data.status === 'ERROR') {
        alert('CNPJ não encontrado ou inválido!');
        return;
      }

      // Atualiza os campos do formulário com os dados retornados
      setFormData({
        ...formData,
        nomeFantasia: data.fantasia || '',
        nome: data.nome || '',
        logradouro: data.logradouro || '',
        numero: data.numero || '',
        complemento: data.complemento || '',
        bairro: data.bairro || '',
        cep: data.cep ? data.cep.replace(/\D/g, '') : '',
        cidade: data.municipio || '',
        uf: data.uf || '',  // Atualiza o estado automaticamente
        website: data.site || '',
        inscricaoEstadual: data.inscricao_estadual || ''
      });

      // Remove o script e o callback global após o uso
      document.body.removeChild(script);
      delete window[callbackName];
    };

    // Cria o script com a URL da API e o parâmetro `callback`
    script.src = `https://www.receitaws.com.br/v1/cnpj/${cnpj}?callback=${callbackName}`;
    script.async = true;

    // Adiciona o script ao DOM para executar a requisição JSONP
    document.body.appendChild(script);
  };

  // Muda a aba ativa
  const handleTabChange = (event, newValue) => {
    setActiveTab(newValue);
  };

  // Função para lidar com o envio do formulário
  const handleSubmit = () => {
    console.log('Form data:', formData);
  };




  
 

  // Controla a abertura e fechamento do modal
  const handleOpenModal = (campo) => {
    setCampoSelecionado(campo);
    setOpenModal(true); // Abre o modal
    setNovoCampo(''); // Limpa o campo anterior
    setEscritorioForm({ nome: '', endereco: '', telefone: '', numero_do_escritorio: '' }); // Reset para o formulário de escritórios
  };
  
  const [escritorioForm, setEscritorioForm] = useState({
    nome: '',
    endereco: '',
    telefone: '',
    numero_do_escritorio: '',
  });
  

  
 
  
  const handleRemoveResponsavel = (index) => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      responsaveis: prevFormData.responsaveis.filter((_, i) => i !== index)
    }));
  };
  
  
// Função para adicionar uma nova inscrição
const handleAddInscricao = () => {
  setFormData((prevFormData) => ({
    ...prevFormData,
    inscricoes: [
      ...prevFormData.inscricoes,
      { tipo: '', numero: '', identificacao: '', status: 'ATIVO' }, // Inicialização padrão
    ],
  }));
};




// Função para alterar os valores das inscrições
const handleInscricaoChange = (index, field, value) => {
  const novasInscricoes = [...formData.inscricoes];
  novasInscricoes[index][field] = value;
  setFormData({ ...formData, inscricoes: novasInscricoes });
};


// Função para remover uma inscrição
const handleRemoveInscricao = async (id) => {
  if (!id) return; // Ignora inscrições sem ID

  try {
    const token = localStorage.getItem("token");
    if (!token) throw new Error("Token não encontrado.");

    const url = `${process.env.REACT_APP_API_URL}/api/clientes/inscricoes/${id}`;
    console.log("URL de exclusão:", url);

    await axios.delete(url, {
      headers: { Authorization: `Bearer ${token}` },
    });

    // Remove a inscrição do estado local
    setFormData((prevFormData) => ({
      ...prevFormData,
      inscricoes: prevFormData.inscricoes.filter((inscricao) => inscricao.id !== id),
    }));

    alert("Inscrição removida com sucesso!");
  } catch (error) {
    console.error("Erro ao excluir inscrição:", error);
    alert("Erro ao excluir inscrição.");
  }
};





const handleSaveInscricao = async (inscricao, index) => {
  try {
    const token = localStorage.getItem("token");
    if (!token) throw new Error("Token não encontrado.");

    const clienteId = formData?.id;
    if (!clienteId) throw new Error("ID do cliente não definido.");

    const url = inscricao.id
      ? `${process.env.REACT_APP_API_URL}/api/clientes/inscricoes/${inscricao.id}`
      : `${process.env.REACT_APP_API_URL}/api/clientes/${clienteId}/inscricoes`;

    const method = inscricao.id ? "put" : "post";

    console.log(`Salvando inscrição com ${method.toUpperCase()} para:`, url);

    const response = await axios[method](url, {
      ...inscricao, // Não envie manualmente empresa_id
    }, {
      headers: { Authorization: `Bearer ${token}` },
    });

    const atualizada = response.data;

    setFormData((prevFormData) => {
      const novasInscricoes = [...prevFormData.inscricoes];
      novasInscricoes[index] = atualizada;
      return { ...prevFormData, inscricoes: novasInscricoes };
    });

    alert("Inscrição salva com sucesso!");
  } catch (error) {
    console.error("Erro ao salvar inscrição:", error);
    alert("Erro ao salvar inscrição. Verifique os dados e tente novamente.");
  }
};






const handleSelectChange = (field, value) => {
  setFormData((prevFormData) => ({
    ...prevFormData,
    [field]: value, // Atualiza o campo com os valores selecionados
  }));
};



  
  const handleAddCampo = async () => {
    try {
      const token = localStorage.getItem('token');
  
      if (campoSelecionado === 'escritorio' && !escritorioForm.nome) {
        console.error('O nome do escritório é obrigatório.');
        return;
      } else if (!novoCampo && campoSelecionado !== 'escritorio') {
        console.error('O nome do campo é obrigatório.');
        return;
      }
  
      // Definir o endpoint e os dados corretamente
      let endpoint = '';
      let formData = { nome: novoCampo }; // Dados padrão
  
      switch (campoSelecionado) {
        case 'tipoCliente':
          endpoint = 'tipo-cliente';
          break;
        case 'statusCliente':
          endpoint = 'status-clientes';
          break;
        case 'segmento':
          endpoint = 'segmento-empresa';
          break;
        case 'grupoCliente':
          endpoint = 'grupo-clientes';
          break;
        case 'statusComplementar':
          endpoint = 'status-complementares';
          break;
        case 'escritorio':
          endpoint = 'escritorios';
          formData = { 
            ...escritorioForm, 
            empresa_id: JSON.parse(localStorage.getItem('empresa_id')) 
          };
          break;
        case 'categoriaCliente':
          endpoint = 'categorias-clientes';
          break;
        default:
          console.error('Campo selecionado inválido.');
          return;
      }
  
      const url = `${API_BASE_URL}/api/${endpoint}`;
      console.log('Enviando para a URL:', url);
      console.log('Dados enviados:', formData);
  
      const response = await axios.post(url, formData, {
        headers: { Authorization: `Bearer ${token}` },
      });
  
      console.log('Campo adicionado com sucesso:', response.data);
  
      fetchStatusData();
      setOpenModal(false);
      setNovoCampo('');
      setEscritorioForm({ nome: '', endereco: '', telefone: '', numero_do_escritorio: '' });
    } catch (error) {
      console.error('Erro ao adicionar novo campo:', error);
      console.error('Detalhes do erro:', error.response?.data || error.message);
    }
  };
  
  
  
  

  return (

    
    <Box sx={{ display: 'flex', height: '100%' }}>
      <Grid container spacing={2}>
      {/* Título da página e caminho de navegação */}
      <Grid item xs={12}>
        <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: 2 }}>
         <Typography
  variant="h6"
  sx={{
    mb: 2,
    fontWeight: 'bold',
    color: '#6a11cb',
    textAlign: 'left',
  }}
>
  {isEdit ? "Editar Cliente" : "Novo Cliente"}
</Typography>

          <Breadcrumbs aria-label="breadcrumb">
            <Link underline="hover" color="inherit" href="/">
              Início
            </Link>
            <Link underline="hover" color="inherit" href="/clientes">
              Clientes
            </Link>
            <Typography color="text.primary">Novo Cliente</Typography>
          </Breadcrumbs>
          
        </Box>
        {openAlert && (
          <Alert
            severity="info"
            sx={{ marginBottom: 2 }}
            action={
              <IconButton
              aria-label="close"
              color="inherit"
              size="small"
              onClick={() => setOpenAlert(false)}
            >
              <Close fontSize="inherit" />
            </IconButton>
            }
          >
            Os campos <strong>Nome</strong>, <strong>CPF/CNPJ</strong>, <strong>Código ERP</strong>, <strong>CEP</strong>, <strong>Cidade</strong> e <strong>UF</strong> são obrigatórios.
          </Alert>
        )}
      </Grid>
        
        
         {/* Coluna das Abas */}
      <Grid item xs={3}>
        <Tabs
          orientation="vertical"
          value={activeTab}
          onChange={handleTabChange}
          sx={{
            borderRight: 1,
            borderColor: 'divider',
            backgroundColor: '#f5f5f5', // Fundo cinza claro
            borderRadius: '8px', // Cantos arredondados
            boxShadow: '0px 4px 12px rgba(0, 0, 10, 0.1)', // Efeito de sombra
            '& .MuiTab-root': {
              textTransform: 'none', // Manter o texto normal
              justifyContent: 'flex-start', // Alinhar texto à esquerda
              alignItems: 'center', // Centralizar conteúdo verticalmente
              padding: '12px 24px', // Espaçamento interno das abas
              '&:hover': {
                backgroundColor: '#e0e0e0', // Mudança de cor ao passar o mouse
              },
            },
            '& .Mui-selected': {
              backgroundColor: '#f5f5f5', // Cor de fundo da aba ativa
              color: '#8c52ff', // Texto branco na aba ativa
              fontWeight: 'bold', // Negrito na aba ativa
            }
          }}
        >
          <Tab icon={<Person />} iconPosition="start" label="Dados Básicos" />
          <Tab icon={<Info />} iconPosition="start" label="Classificação" />
          <Tab icon={<Info />} iconPosition="start" label="Inscrições" />
          <Tab icon={<Contacts />} iconPosition="start" label="Contatos" />
          <Tab icon={<Search />} iconPosition="start" label="Campos de pesquisa" />
          <Tab icon={<Share />} iconPosition="start" label="Rede Social" />
          <Tab icon={<People />} iconPosition="start" label="Responsável" />
          <Tab icon={<ListAlt />} iconPosition="start" label="Tarefas" />
        </Tabs>
      </Grid>

        {/* Conteúdo das Abas */}

        
        <Grid item xs={9}>

          
          {/* Dados Básicos */}
          {activeTab === 0 && (
            <Box sx={{ padding: 3 }}>
              <Typography variant="h6" gutterBottom>Dados Básicos</Typography>
              <Grid container spacing={2}>
              <Grid item xs={12} sm={6}>
               
              <TextField
  fullWidth
  label="Código ERP"
  name="codigoErp" // Mantém um nome fixo para não gerar re-renders desnecessários
  value={formData.codigoErp || ''}
  onChange={handleChange}
  autoComplete="off"
  inputProps={{
    autoComplete: "new-password", // Evita preenchimento automático
    form: {
      autoComplete: "off", // Remove sugestões do formulário
    },
    "data-form-type": "other", // Faz o navegador ignorar
    "aria-autocomplete": "none", // Melhora a acessibilidade e desativa sugestões
  }}
  sx={{
    '& input': {
      backgroundColor: 'transparent !important', // Remove fundo amarelo do Chrome
      WebkitBoxShadow: '0 0 0px 1000px white inset !important', // Força a remoção de preenchimento
      WebkitTextFillColor: 'black !important', // Mantém o texto normal
    },
  }}
/>







                    </Grid>


              <Grid item xs={12} sm={6}>
                  <TextField
                    fullWidth
                    label="CPF/CNPJ"
                    name="cpfCnpj"
                    value={formData.cpfCnpj}
                    onChange={handleChange}
                    required
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    fullWidth
                    label="Nome"
                    name="nome"
                    value={formData.nome}
                    onChange={handleChange}
                    required
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    fullWidth
                    label="Nome Fantasia"
                    name="nomeFantasia"
                    value={formData.nomeFantasia}
                    onChange={handleChange}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    fullWidth
                    label="Apelido"
                    name="apelido"
                    value={formData.apelido}
                    onChange={handleChange}
                  />
                </Grid>
              
                             

                {/* Campo de CEP que dispara a busca do endereço */}
                <Grid item xs={12} sm={6}>
                  <TextField
                    fullWidth
                    label="CEP"
                    name="cep"
                    value={formData.cep}
                    onChange={handleChange}
                  />
                </Grid>

                {/* Campos de endereço que serão preenchidos automaticamente */}
                <Grid item xs={12} sm={6}>
                  <TextField
                    fullWidth
                    label="Logradouro"
                    name="logradouro"
                    value={formData.logradouro}
                    onChange={handleChange}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    fullWidth
                    label="Número"
                    name="numero"
                    value={formData.numero}
                    onChange={handleChange}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    fullWidth
                    label="Complemento"
                    name="complemento"
                    value={formData.complemento}
                    onChange={handleChange}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    fullWidth
                    label="Bairro"
                    name="bairro"
                    value={formData.bairro}
                    onChange={handleChange}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                <TextField
                    fullWidth
                    label="UF"
                    name="uf"
                    value={formData.uf}
                    onChange={handleChange}
                    select
                >
                    {estadosBrasileiros.map((estado) => (
                    <MenuItem key={estado.sigla} value={estado.sigla}>
                        {estado.nome} ({estado.sigla})
                    </MenuItem>
                    ))}
                </TextField>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    fullWidth
                    label="Cidade"
                    name="cidade"
                    value={formData.cidade}
                    onChange={handleChange}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                <TextField
                    fullWidth
                    label="Website"
                    name="website"
                    value={formData.website || ''}
                    onChange={handleChange}
                    placeholder="https://conttalk.com.br"
                />

                </Grid>
                <Grid item xs={12} sm={6}>
  <TextField
    fullWidth
    label="Honorário"
    name="honorario"
    value={formData.honorario} // Exibe o valor formatado
    onChange={handleCurrencyChange} // Atualiza o estado ao digitar
    placeholder="Digite o valor do honorário"
  />
</Grid>

              </Grid>
            </Box>
          )}

          {/* Status */}
          
          
          {activeTab === 1 && (
  <Box sx={{ padding: 3 }}>
    <Typography variant="h6" gutterBottom>
      Classificação
    </Typography>

    <Grid container spacing={3}>
      {[
        { label: 'Status Cliente', field: 'statusCliente' },
        { label: 'Status Complementar', field: 'statusComplementar' },
        { label: 'Tipo do Cliente', field: 'tipoCliente', multiple: true },
        { label: 'Categoria Cliente', field: 'categoriaCliente' },
        { label: 'Grupo Cliente', field: 'grupoCliente' },
        { label: 'Segmento', field: 'segmento', multiple: true },
        { label: 'Escritório', field: 'escritorio' },
      ].map(({ label, field, multiple }) => (
        <Grid item xs={12} sm={6} key={field} sx={{ marginBottom: 4 }}>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              marginBottom: 1,
            }}
          >
            <Typography variant="body1">{label}</Typography>
            <IconButton
              color="primary"
              size="small"
              onClick={() => {
                setCampoSelecionado(field);
                setOpenModal(true);
              }}
            >
              <Add fontSize="small" />
            </IconButton>
          </Box>

          <Select
  fullWidth
  multiple={multiple}
  value={
    multiple
      ? Array.isArray(formData.classificacao?.[field]) ? formData.classificacao?.[field] : [formData.classificacao?.[field]]  // Múltipla escolha
      : formData.classificacao?.[field] || ''  // Escolha única
  }
  onChange={(e) => {
    const newValue = multiple ? e.target.value : e.target.value;
    setFormData((prev) => ({
      ...prev,
      classificacao: {
        ...prev.classificacao,
        [field]: newValue,
      },
    }));
  }}
  renderValue={(selected) => {
    if (multiple) {
      // Caso de seleção múltipla (array)
      const options = selected.map((id) => {
        const option = opcoesStatus[field]?.find((item) => item.id === id);
        return option ? option.nome : id; // Retorna o nome da opção
      });
      return options.join(', '); // Junta os nomes com vírgula
    } else {
      // Caso de seleção única (string)
      const option = opcoesStatus[field]?.find((item) => item.id === selected);
      return option ? option.nome : <em>Selecione {label}</em>; // Retorna o nome da opção
    }
  }}
  displayEmpty
>
  <MenuItem value="">
    <em>Selecione {label}</em>
  </MenuItem>
  {opcoesStatus[field]?.map((option) => (
    <MenuItem key={option.id} value={option.id}>
      {option.nome}
    </MenuItem>
  ))}
</Select>
        </Grid>
      ))}
    </Grid>
  </Box>
)}






{activeTab === 2 && (
  <Box sx={{ padding: 3 }}>
  {/* Verifique se este console é exibido */}
  {console.log('Renderizando a aba de Contatos')} 

<Typography 
 variant="h6" 
 gutterBottom 
 sx={{ color: 'black', marginBottom: 5 }} // Estilização adicional para garantir visibilidade
>
 Inscrições
</Typography>

    <Grid container spacing={2}>
      {formData.inscricoes?.map((inscricao, index) => (
        <Grid
          container
          spacing={2}
          alignItems="center"
          key={index}
          sx={{
            marginBottom: 3,
            backgroundColor: '#f5f5f5',
            padding: 2,
            borderRadius: 2,
          }}
        >
          {/* Campo para Tipo de Inscrição */}
          <Grid item xs={12} sm={3}>
            <Select
              value={inscricao.tipo || ''}
              onChange={(e) =>
                handleInscricaoChange(index, 'tipo', e.target.value)
              }
              displayEmpty
              fullWidth
            >
              <MenuItem value="">
                <em>Selecione o Tipo de Inscrição</em>
              </MenuItem>
              <MenuItem value="Inscrição Estadual">Inscrição Estadual</MenuItem>
              <MenuItem value="Inscrição Municipal">Inscrição Municipal</MenuItem>
              <MenuItem value="CEI">CEI</MenuItem>
              <MenuItem value="CAEPF">CAEPF</MenuItem>
              <MenuItem value="CNO">CNO</MenuItem>
              <MenuItem value="CCIR">CCIR</MenuItem>
              <MenuItem value="NIRF">NIRF</MenuItem>
              <MenuItem value="Outros">Outros</MenuItem>
            </Select>
          </Grid>

          {/* Campo para Número da Inscrição */}
          <Grid item xs={12} sm={3}>
            <TextField
              label="Número da Inscrição"
              value={inscricao.numero || ''}
              onChange={(e) =>
                handleInscricaoChange(index, 'numero', e.target.value)
              }
              fullWidth
            />
          </Grid>

          {/* Campo para Identificação */}
          <Grid item xs={12} sm={3}>
            <TextField
              label="Identificação"
              value={inscricao.identificacao || ''}
              onChange={(e) =>
                handleInscricaoChange(index, 'identificacao', e.target.value)
              }
              fullWidth
            />
          </Grid>

          {/* Campo para Status */}
          <Grid item xs={12} sm={2}>
            <Select
              value={inscricao.status || ''}
              onChange={(e) =>
                handleInscricaoChange(index, 'status', e.target.value)
              }
              fullWidth
            >
              <MenuItem value="ATIVO">ATIVO</MenuItem>
              <MenuItem value="BAIXADO">BAIXADO</MenuItem>
              <MenuItem value="SUSPENSO">SUSPENSO</MenuItem>
              <MenuItem value="INATIVO">INATIVO</MenuItem>
            </Select>
          </Grid>
          

         {/* Ações: Salvar e Excluir */}
         <Grid item xs={12} sm={5} sx={{ textAlign: 'left' }}>
  {/* Ícone para Salvar */}
  <IconButton
    color="primary"
    onClick={() => handleSaveInscricao(inscricao, index)}
    sx={{ marginRight: 1 }}
  >
    <SaveIcon />
  </IconButton>

  {/* Ícone para Excluir */}
  <IconButton
    color="error"
    onClick={() => handleRemoveInscricao(inscricao.id)}
  >
    <DeleteIcon />
  </IconButton>
</Grid>
        </Grid>
   

        
      ))}


      {/* Botão para Adicionar Nova Inscrição */}
      <Grid item xs={12} sx={{ textAlign: 'center' }}>
        <Button
          variant="contained"
          color="primary"
          startIcon={<AddIcon />}
          onClick={handleAddInscricao}
        >
          Adicionar Inscrição
        </Button>
      </Grid>
    </Grid>
  </Box>
)}


{activeTab === 3 && (
  <Box sx={{ padding: 3 }}>
    {/* Título da Aba */}
    <Typography 
      variant="h6" 
      gutterBottom 
      sx={{ color: 'black', marginBottom: 5 }}
    >
      Contatos
    </Typography>

    {/* Renderização dos Contatos */}
    <Grid container spacing={2} sx={{ marginBottom: 2 }}>
      {formData.contatos.length > 0 ? (
        formData.contatos.map((contato, index) => (
          <Grid
            container
            spacing={2}
            alignItems="center"
            key={contato.id || index} // Garante uma chave única
            sx={{
              marginBottom: 3,
              backgroundColor: '#f5f5f5',
              padding: 2,
              borderRadius: 2,
            }}
          >
            {/* Nome */}
            <Grid item xs={12} sm={3}>
              <TextField
                label="Nome"
                fullWidth
                value={contato.nome || ''}
                onChange={(e) => handleContatoChange(index, 'nome', e.target.value)}
                placeholder="Digite o nome do contato"
                required
              />
            </Grid>

            {/* Cargo */}
            <Grid item xs={12} sm={3}>
              <TextField
                label="Cargo"
                fullWidth
                value={contato.cargo || ''}
                onChange={(e) => handleContatoChange(index, 'cargo', e.target.value)}
                placeholder="Digite o cargo"
              />
            </Grid>

            {/* Celular */}
            <Grid item xs={12} sm={3}>
              <InputMask
                mask="(99) 9 9999-9999"
                value={contato.celular || ''}
                onChange={(e) => handleContatoChange(index, 'celular', e.target.value)}
              >
                {() => (
                  <TextField
                    label="Celular"
                    fullWidth
                    placeholder="Digite o número de celular"
                  />
                )}
              </InputMask>
            </Grid>

            {/* E-mail */}
            <Grid item xs={12} sm={3}>
              <TextField
                label="E-mail"
                fullWidth
                value={contato.email || ''}
                onChange={(e) => handleContatoChange(index, 'email', e.target.value)}
                placeholder="Digite o e-mail"
              />
            </Grid>

            {/* Tipo de E-mail */}
            <Grid item xs={12} sm={3}>
  <Select
    value={contato.departamento || ''}
    onChange={(e) => handleContatoChange(index, 'departamento', e.target.value)}
    displayEmpty
    fullWidth
  >
    <MenuItem value="">
      <em>Departamento</em>
    </MenuItem>
    <MenuItem value="Contábil">Contábil</MenuItem>
    <MenuItem value="Financeiro">Financeiro</MenuItem>
    <MenuItem value="Fiscal">Fiscal</MenuItem>
    <MenuItem value="Legalização">Legalização</MenuItem>
    <MenuItem value="Licitação">Licitação</MenuItem>
    <MenuItem value="Pessoal">Pessoal</MenuItem>
    <MenuItem value="Outro">Outro</MenuItem>
  </Select>
</Grid>


            {/* Anotações */}
            <Grid item xs={12} sm={6}>
              <TextField
                label="Anotações"
                fullWidth
                multiline
                rows={2}
                value={contato.anotacoes || ''}
                onChange={(e) => handleContatoChange(index, 'anotacoes', e.target.value)}
                placeholder="Digite anotações sobre o contato"
              />
            </Grid>

            {/* Botões de Ação */}
            <Grid item xs={12} sm={2} sx={{ display: 'flex', justifyContent: 'flex-end' }}>
              {/* Ícone para Salvar */}
              <IconButton
                color="primary"
                onClick={() => handleSaveContato(contato, index)}
                sx={{ marginRight: 1 }}
              >
                <SaveIcon />
              </IconButton>

              {/* Ícone para Excluir */}
              <IconButton color="error" onClick={() => handleRemoveContato(index)}>
                <DeleteIcon />
              </IconButton>
            </Grid>
          </Grid>
        ))
      ) : (
        <Typography variant="body1" color="textSecondary" sx={{ textAlign: 'center', width: '100%' }}>
          Nenhum contato cadastrado. Clique em "Adicionar Contato" para incluir.
        </Typography>
      )}
    </Grid>

    {/* Botão para Adicionar Contatos */}
    <Grid item xs={12} sx={{ textAlign: 'center' }}>
      <Button
        variant="contained"
        color="primary"
        startIcon={<AddIcon />}
        onClick={handleAddContato}
        sx={{ marginTop: 2 }}
      >
        Adicionar Contato
      </Button>
    </Grid>
  </Box>
)}




{activeTab === 4 && (
  <Box sx={{ padding: 3 }}>
    <Typography variant="h6" gutterBottom>
      Campos de Pesquisa
    </Typography>

    {/* Campo para Adicionar Novo Termo */}
    <TextField
      label="Digite um termo"
      fullWidth
      value={tagInput}
      onChange={(e) => setTagInput(e.target.value)}
      onKeyDown={(e) => {
        if (e.key === "Enter" && tagInput.trim() !== "") {
          handleAddTermo();
          e.preventDefault();
        }
      }}
      placeholder="Pressione Enter para adicionar"
      sx={{ marginBottom: 2 }}
    />

    {/* Lista de Termos Salvos */}
    <Box sx={{ display: "flex", flexWrap: "wrap", gap: 1 }}>
      {formData.termos.map((termo, index) => (
        <Chip
          key={termo.id || index}
          label={termo.termo}
          onDelete={() => handleDeleteTermo(termo.id, index)}
          color="primary"
        />
      ))}
    </Box>
  </Box>
)}

{activeTab === 50 && (
  <Box sx={{ padding: 3 }}>
    <Typography variant="h6" gutterBottom>
      Anotações
    </Typography>

    {(formData.anotacoes || []).length > 0 ? (

      formData.anotacoes.map((anotacao, index) => {
        // Formatar data e hora a partir do timestamp
        const dataHora = anotacao.data_registro 
          ? new Date(anotacao.data_registro)
          : new Date();

        return (
          <Grid
            container
            spacing={2}
            key={anotacao.id || `new-${index}`}
            sx={{
              marginBottom: 3,
              backgroundColor: "#f5f5f5",
              padding: 2,
              borderRadius: 2,
            }}
          >
            {/* Cabeçalho com Data/Hora e Controles */}
            <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
              <Box sx={{ display: 'flex', gap: 2 }}>
                <TextField
                  label="Data"
                  value={dataHora.toLocaleDateString('pt-BR')}
                  InputProps={{ readOnly: true }}
                  size="small"
                />
                <TextField
                  label="Hora"
                  value={dataHora.toLocaleTimeString('pt-BR', {
                    hour: '2-digit',
                    minute: '2-digit'
                  })}
                  InputProps={{ readOnly: true }}
                  size="small"
                />
              </Box>
              
              <Box sx={{ display: 'flex', gap: 1 }}>
                <Tooltip title="Salvar alterações">
                  <IconButton 
                    onClick={() => onAnotacaoSave(anotacao, index)}
                    color="primary"
                  >
                    <SaveIcon fontSize="small" />
                  </IconButton>
                </Tooltip>
                <Tooltip title="Excluir anotação">
                  <IconButton
                    onClick={() => onAnotacaoRemove(index)}
                    color="error"
                  >
                    <DeleteIcon fontSize="small" />
                  </IconButton>
                </Tooltip>
              </Box>
            </Grid>

            {/* Usuário */}
            <Grid item xs={12}>
              <TextField
                label="Responsável"
                value={anotacao.usuarioNome || "Usuário não identificado"}
                InputProps={{ readOnly: true }}
                fullWidth
                size="small"
              />
            </Grid>

            {/* Descrição */}
            <Grid item xs={12}>
              <TextField
                label="Detalhes da Anotação"
                multiline
                rows={4}
                value={anotacao.descricao || ""}
                onChange={(e) => handleAnotacaoChange(index, "descricao", e.target.value)}
                fullWidth
                variant="outlined"
                InputLabelProps={{ shrink: true }}
              />
            </Grid>

            {/* Anexos */}
            <Grid item xs={12}>
              <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
                <Button
                  variant="outlined"
                  component="label"
                  size="small"
                  startIcon={<AttachFileIcon />}
                >
                  Anexar Arquivo
                  <input
                    type="file"
                    hidden
                    onChange={(e) => handleUploadArquivo(index, e.target.files[0])}
                  />
                </Button>
                
                {anotacao.arquivo_url && (
                  <Chip
                    icon={<DescriptionIcon />}
                    label="Visualizar Anexo"
                    onClick={() => window.open(anotacao.arquivo_url, '_blank')}
                    variant="outlined"
                    sx={{ cursor: 'pointer' }}
                  />
                )}
              </Box>
            </Grid>

            {/* Status de Salvamento */}
            {anotacao.id && (
              <Grid item xs={12} sx={{ textAlign: 'right', mt: 1 }}>
                <Chip
                  label={`Salvo em ${dataHora.toLocaleString('pt-BR')}`}
                  color="success"
                  size="small"
                  variant="outlined"
                />
              </Grid>
            )}
          </Grid>
        );
      })
    ) : (
      <Typography variant="body1" color="textSecondary">
        Nenhuma anotação cadastrada
      </Typography>
    )}

    {/* Botão de Adição */}
    <Box sx={{ mt: 3, display: 'flex', justifyContent: 'center' }}>
              <Button
            variant="contained"
            color="primary"
            startIcon={<AddIcon />}
            onClick={() => {
              handleAddAnotacao();
              console.log("Botão clicado! Estado atualizado:", formData.anotacoes);
            }}
          >
            Adicionar Nova Anotação
          </Button>



          </Box>
        </Box>
      )}



{activeTab === 5 && (
  <Box sx={{ padding: 3 }}>
     {/* Verifique se este console é exibido */}
     {console.log('Renderizando a aba de Contatos')} 

<Typography 
  variant="h6" 
  gutterBottom 
  sx={{ color: 'black', marginBottom: 5 }} // Estilização adicional para garantir visibilidade
>
  Rede Social
</Typography>

    <Grid container spacing={2}>
      {formData.redesSociais?.map((rede, index) => (
        <Grid
          container
          spacing={2}
          alignItems="center"
          key={index}
          sx={{
            marginBottom: 3,
            backgroundColor: '#f5f5f5',
            padding: 2,
            borderRadius: 2,
          }}
        >
          {/* Seletor de Rede Social */}
          <Grid item xs={12} sm={5}>
            <Select
              value={rede.nome || ''}
              onChange={(e) =>
                handleRedeSocialChange(index, 'nome', e.target.value)
              }
              displayEmpty
              fullWidth
            >
              <MenuItem value="">
                <em>Selecione a Rede Social</em>
              </MenuItem>
              <MenuItem value="Facebook">Facebook</MenuItem>
              <MenuItem value="Instagram">Instagram</MenuItem>
              <MenuItem value="LinkedIn">LinkedIn</MenuItem>
              <MenuItem value="Twitter">Twitter</MenuItem>
              <MenuItem value="YouTube">YouTube</MenuItem>
              <MenuItem value="TikTok">TikTok</MenuItem>
              <MenuItem value="Outros">Outros</MenuItem>
            </Select>
          </Grid>

          {/* Campo de URL */}
          <Grid item xs={12} sm={5}>
            <TextField
              label="URL da Rede Social"
              value={rede.url || ''}
              onChange={(e) =>
                handleRedeSocialChange(index, 'url', e.target.value)
              }
              placeholder="Digite a URL completa (ex: https://...)"
              fullWidth
            />
          </Grid>

          {/* Botão para Remover Rede Social */}
          <Grid item xs={12} sm={2} sx={{ textAlign: 'center' }}>
            <IconButton
              color="error"
              onClick={() => handleRemoveRedeSocial(index)}
            >
              <DeleteIcon />
            </IconButton>
          </Grid>
        </Grid>
      ))}

      {/* Botão para Adicionar Nova Rede Social */}
      <Grid item xs={12} sx={{ textAlign: 'center' }}>
        <Button
          variant="contained"
          color="primary"
          startIcon={<AddIcon />}
          onClick={handleAddRedeSocial}
        >
          Adicionar Rede Social
        </Button>
      </Grid>
    </Grid>
  </Box>
)}

{activeTab === 6 && (
  <Box sx={{ padding: 3 }}>
    <Typography 
      variant="h6" 
      gutterBottom 
      sx={{ color: 'black', marginBottom: 5 }}
    >
      Responsável
    </Typography>

    <Grid container spacing={3}>
    {formData.responsaveis.map((responsavel, index) => (
  <Grid container spacing={2} alignItems="center" key={index}>
    <Grid item xs={5}>
      <Select
        value={responsavel.usuarioId || ''}
        onChange={(e) => handleResponsavelChange(index, 'usuarioId', e.target.value)}
        displayEmpty
        fullWidth
      >
        <MenuItem value="">
          <em>Selecione um usuário</em>
        </MenuItem>
        {usuarios.map((usuario) => (
          <MenuItem key={usuario.id} value={usuario.id}>
            {usuario.nome}
          </MenuItem>
        ))}
      </Select>
    </Grid>
    <Grid item xs={5}>
      <Select
        value={responsavel.departamentoId || ''}
        onChange={(e) => handleResponsavelChange(index, 'departamentoId', e.target.value)}
        displayEmpty
        fullWidth
      >
        <MenuItem value="">
          <em>Selecione um departamento</em>
        </MenuItem>
        {departamentos.map((departamento) => (
          <MenuItem key={departamento.id} value={departamento.id}>
            {departamento.nome}
          </MenuItem>
        ))}
      </Select>
    </Grid>
    <Grid item xs={2}>
      <IconButton
        color="error"
        onClick={() => handleRemoveResponsavel(index)}
      >
        <DeleteIcon />
      </IconButton>
    </Grid>
  </Grid>
))}
<Grid item xs={12}>
  <Button
    variant="contained"
    color="primary"
    startIcon={<AddIcon />}
    onClick={handleAddResponsavel}
  >
    Adicionar Novo Responsável
  </Button>
</Grid>

    </Grid>
  </Box>
)}



{activeTab === 7 && (
  <Box sx={{ padding: 3 }}>
    <Typography variant="h6" gutterBottom>
      Tarefas
    </Typography>

    <Button
      variant="contained"
      color="primary"
      startIcon={<AddIcon />}
      onClick={() => setOpenNovaVigencia(true)}
      sx={{ marginBottom: 2 }}
    >
      Adicionar Nova Vigência
    </Button>

    {/* Tabela para mostrar vigências cadastradas */}
    <TableContainer component={Paper}>
  <Table>
    <TableHead>
      <TableRow>
        <TableCell>Data do Cadastro</TableCell>
        <TableCell>Competência (Mês/Ano)</TableCell>
        <TableCell>Regime Tributário Federal</TableCell>
        <TableCell>Regime Tributário Estadual</TableCell>
        <TableCell>Regime Tributário Municipal</TableCell>
        <TableCell>Ações</TableCell> {/* Nova coluna para os botões */}
      </TableRow>
    </TableHead>
    <TableBody>
      {rows.map((row, index) => (
        <TableRow key={index}>
          <TableCell>{row.dataCadastro}</TableCell>
          <TableCell>{row.competencia}</TableCell>
          <TableCell>{row.regimeFederal}</TableCell>
          <TableCell>{row.regimeEstadual}</TableCell>
          <TableCell>{row.regimeMunicipal}</TableCell>
          <TableCell>
            {/* Ícones de ação */}
            <IconButton
              color="primary"
              onClick={() => handleEditRow(index)} // Função para editar
            >
              <EditIcon />
            </IconButton>
            <IconButton
              color="info"
              onClick={() => handleViewRow(index)} // Função para visualizar
            >
              <VisibilityIcon />
            </IconButton>
            <IconButton
              color="error"
              onClick={() => handleDeleteRow(index)} // Função para excluir
            >
              <DeleteIcon />
            </IconButton>
          </TableCell>
        </TableRow>
      ))}
    </TableBody>
  </Table>
</TableContainer>


    {/* Modal para Adicionar Nova Vigência */}
    <Dialog
  open={openNovaVigencia}
  onClose={() => setOpenNovaVigencia(false)}
  fullWidth
  maxWidth="lg" // Define o tamanho máximo da janela modal
>
  <DialogTitle>Nova Vigência - Cadastro</DialogTitle>
  <DialogContent>
    <Grid container spacing={2}>
      {/* Data do Cadastro (Editável) */}
      <Grid item xs={12} sm={6}>
        <TextField
          label="Data do Cadastro"
          type="date"
          defaultValue={new Date().toISOString().substring(0, 10)}
          fullWidth
        />
      </Grid>

      {/* Vigência referente à competência (Mês/Ano) */}
      <Grid item xs={12} sm={6}>
        <TextField
          label="Vigência (Competência Mês/Ano)"
          placeholder="mm/yyyy"
          fullWidth
          inputProps={{ maxLength: 7 }}
        />
      </Grid>

      {/* Regime Tributário Federal */}
      <Grid item xs={12}>
        <Select fullWidth displayEmpty>
          <MenuItem value="">
            <em>Selecione o Regime Tributário Federal</em>
          </MenuItem>
          <MenuItem value="Simples Nacional">Simples Nacional</MenuItem>
          <MenuItem value="Lucro Presumido">Lucro Presumido</MenuItem>
          <MenuItem value="Lucro Real">Lucro Real</MenuItem>
          <MenuItem value="Imunes e Isentas">Imunes e Isentas</MenuItem>
        </Select>
      </Grid>

      {/* Tabela Adicionada */}
      <Grid item xs={12}>
        <Box sx={{ flex: 2, marginTop: 3 }}>
          <Typography variant="subtitle1" sx={{ marginBottom: 2 }}>
            Obrigações 
          </Typography>
          <TableContainer component={Paper} sx={{ maxHeight: '40vh' }}>
            <Table stickyHeader>
              <TableHead>
                <TableRow>
                  <TableCell padding="checkbox">
                    <Checkbox />
                  </TableCell>
                  {[
                    'Código',
                    'Classificação',
                    'Nome da Obrigação',
                    'Mininome',
                    'Departamento',
                    'Tipo do Serviço',
                    'Grupo do Serviço',
                  ].map((header) => (
                    <TableCell key={header}>
                      {header}
                      <IconButton size="small">
                        <FilterListIcon />
                      </IconButton>
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {rows
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((row, index) => (
                    <TableRow key={index}>
                      <TableCell padding="checkbox">
                        <Checkbox />
                      </TableCell>
                      <TableCell>{row.codigo}</TableCell>
                      <TableCell>{row.classificacao}</TableCell>
                      <TableCell>{row.nome}</TableCell>
                      <TableCell>{row.mininome}</TableCell>
                      <TableCell>{row.departamento}</TableCell>
                      <TableCell>{row.tipo}</TableCell>
                      <TableCell>{row.grupo}</TableCell>
                    </TableRow>
                  ))}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            rowsPerPageOptions={[5, 10, 15]}
            component="div"
            count={rows.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Box>
      </Grid>
    </Grid>
  </DialogContent>

  <DialogActions>
    <Button onClick={() => setOpenNovaVigencia(false)}>Cancelar</Button>
    <Button variant="contained" color="primary" onClick={() => setOpenNovaVigencia(false)}>
      Salvar 
    </Button>
    <Button variant="outlined" color="secondary" onClick={() => setOpenNovaVigencia(false)}>
      Fechar
    </Button>
  </DialogActions>
</Dialog>

  </Box>
)}







          
<Box sx={{ display: 'flex', justifyContent: 'center', mt: 4 }}>
  {/* Botão Salvar - Aparece apenas se handleSave for definido */}
  {handleSave && (
    <Button
      variant="contained"
      color="primary"
      onClick={() => handleSave(formData)}
      disabled={isLoading} // Desativa enquanto carrega
    >
      {isLoading ? "Salvando..." : "Salvar"}
    </Button>
  )}

  {/* Botão Voltar - Sempre aparece */}
  <Button variant="contained" color="secondary" onClick={handleBack}>
    Voltar
  </Button>
</Box>


        </Grid>
      </Grid>

      {/* Modal para adicionar novos campos */}
      <Dialog open={openModal} onClose={() => setOpenModal(false)}>
    <DialogTitle>
      {campoSelecionado === 'escritorio' ? 'Adicionar Escritório' : 'Adicionar'}
    </DialogTitle>
    <DialogContent>
      {campoSelecionado === 'escritorio' ? (
        <>
          <TextField
            label="Nome do Escritório"
            fullWidth
            value={escritorioForm.nome}
            onChange={(e) => setEscritorioForm({ ...escritorioForm, nome: e.target.value })}
            sx={{ marginBottom: 2 }}
          />
          <TextField
            label="Endereço"
            fullWidth
            value={escritorioForm.endereco}
            onChange={(e) => setEscritorioForm({ ...escritorioForm, endereco: e.target.value })}
            sx={{ marginBottom: 2 }}
          />
          <InputMask
            mask="(99) 99999-9999"
            value={escritorioForm.telefone}
            onChange={(e) => setEscritorioForm({ ...escritorioForm, telefone: e.target.value })}
          >
            {(inputProps) => (
              <TextField
                {...inputProps}
                label="Telefone"
                fullWidth
                sx={{ marginBottom: 2 }}
              />
            )}
          </InputMask>
          <TextField
            label="Número do Escritório"
            type="number"
            fullWidth
            value={escritorioForm.numero_do_escritorio}
            onChange={(e) => setEscritorioForm({ ...escritorioForm, numero_do_escritorio: e.target.value })}
            sx={{ marginBottom: 2 }}
          />
        </>
      ) : (
        <TextField
          label="Nome"
          fullWidth
          value={novoCampo}
          onChange={(e) => setNovoCampo(e.target.value)}
        />
      )}
    </DialogContent>
    <DialogActions>
      <Button onClick={() => setOpenModal(false)}>Cancelar</Button>
      <Button variant="contained" onClick={handleAddCampo}>
        Adicionar
      </Button>
    </DialogActions>
  </Dialog>
  <Snackbar
  open={snackbarOpen}
  autoHideDuration={4000} // Tempo em milissegundos antes de fechar
  onClose={() => setSnackbarOpen(false)}
  anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
>
  <Alert 
    onClose={() => setSnackbarOpen(false)} 
    severity={snackbarSeverity} 
    sx={{ width: '100%' }}
  >
    {snackbarMessage}
  </Alert>
</Snackbar>






    </Box>
  );
};

export default NovoCliente;
