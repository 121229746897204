import React, { useEffect, useState } from 'react';
import {
  Box, Table, TableHead, TableRow, TableCell, TableBody, Button, Tooltip,
  IconButton, Modal, TextField, Typography, Alert, Snackbar
} from '@mui/material';
import { Add as AddIcon, Edit as EditIcon, Delete as DeleteIcon, Close as CloseIcon } from '@mui/icons-material';
import axios from 'axios';

const StatusCliente = () => {
  const [statusClientes, setStatusClientes] = useState([]);
  const [openModal, setOpenModal] = useState(false);
  const [modalType, setModalType] = useState(''); // 'add', 'edit'
  const [selectedStatus, setSelectedStatus] = useState(null); // Status selecionado para editar
  const [statusForm, setStatusForm] = useState({ nome: '' });
  const [errorMessage, setErrorMessage] = useState('');
  const [successMessage, setSuccessMessage] = useState('');
  const [showSnackbar, setShowSnackbar] = useState(false);

  // Carregar status de cliente com token JWT
  useEffect(() => {
    loadStatusClientes();
  }, []);

  const loadStatusClientes = () => {
    const token = localStorage.getItem('token');
    if (!token) {
      setErrorMessage('Token não encontrado. Faça login novamente.');
      return;
    }
    axios.get(`${process.env.REACT_APP_API_URL}/api/status-clientes`, {
      headers: {
        Authorization: `Bearer ${token}`
      }
    })
      .then(response => setStatusClientes(response.data))
      .catch(() => setErrorMessage('Erro ao carregar status de cliente.'));
  };

  // Função para abrir o modal
  const handleOpenModal = (type, status = null) => {
    setModalType(type);
    if (status) {
      setSelectedStatus(status);
      setStatusForm({ nome: status.nome });
    } else {
      setSelectedStatus(null);
      setStatusForm({ nome: '' }); // Limpar os campos ao abrir o modal para adicionar
    }
    setOpenModal(true);
  };

  // Função para fechar o modal
  const handleCloseModal = () => {
    setOpenModal(false);
    setSelectedStatus(null);
    setErrorMessage('');
  };

  // Função para adicionar ou editar status de cliente
  const handleSubmitForm = () => {
    const token = localStorage.getItem('token');
    if (!statusForm.nome) {
      setErrorMessage('O nome do status é obrigatório.');
      return;
    }

    const formData = {
      nome: statusForm.nome
    };

    if (modalType === 'edit' && selectedStatus) {
      axios.put(`${process.env.REACT_APP_API_URL}/api/status-clientes/${selectedStatus.id}`, formData, {
        headers: { Authorization: `Bearer ${token}` }
      })
        .then(() => {
          setSuccessMessage('Status de cliente atualizado com sucesso.');
          setShowSnackbar(true);
          handleCloseModal();
          loadStatusClientes();
        })
        .catch(() => setErrorMessage('Erro ao atualizar status de cliente.'));
    } else {
      axios.post(`${process.env.REACT_APP_API_URL}/api/status-clientes`, formData, {
        headers: { Authorization: `Bearer ${token}` }
      })
        .then(() => {
          setSuccessMessage('Status de cliente criado com sucesso.');
          setShowSnackbar(true);
          handleCloseModal();
          loadStatusClientes();
        })
        .catch(() => setErrorMessage('Erro ao criar status de cliente.'));
    }
  };

  // Função para deletar status de cliente
  const handleDeleteStatusCliente = (id) => {
    const token = localStorage.getItem('token');
    axios.delete(`${process.env.REACT_APP_API_URL}/api/status-clientes/${id}`, {
      headers: { Authorization: `Bearer ${token}` }
    })
      .then(() => {
        setSuccessMessage('Status de cliente deletado com sucesso.');
        setShowSnackbar(true);
        loadStatusClientes();
      })
      .catch(() => setErrorMessage('Erro ao deletar status de cliente.'));
  };

  return (
    <Box>

       <Typography
              variant="h6"
              sx={{
                mb: 2,
                fontWeight: 'bold',
                color: '#6a11cb',
                textAlign: 'left',
              }}
            >
           
            Status de cliente
          </Typography>
      <Button
        variant="contained"
        color="primary"
        startIcon={<AddIcon />}
        onClick={() => handleOpenModal('add')}
      >
        Adicionar Status de Cliente
      </Button>

      <Table>
        <TableHead>
          <TableRow>
            <TableCell>Nome do Status</TableCell>
            <TableCell>Ações</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {statusClientes.map(status => (
            <TableRow key={status.id}>
              <TableCell>{status.nome}</TableCell>
              <TableCell>
                <Tooltip title="Editar">
                  <IconButton color="primary" onClick={() => handleOpenModal('edit', status)}>
                    <EditIcon />
                  </IconButton>
                </Tooltip>
                <Tooltip title="Deletar">
                  <IconButton color="error" onClick={() => handleDeleteStatusCliente(status.id)}>
                    <DeleteIcon />
                  </IconButton>
                </Tooltip>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>

      <Modal open={openModal} onClose={handleCloseModal}>
        <Box sx={{
          padding: 4,
          backgroundColor: 'white',
          margin: 'auto',
          width: '50%',
          marginTop: '5%',
          maxHeight: '80vh',
          overflowY: 'auto',
          position: 'relative',
        }}>
          <IconButton sx={{ position: 'absolute', top: 8, right: 8 }} onClick={handleCloseModal}>
            <CloseIcon />
          </IconButton>

          <Typography variant="h6">
            {modalType === 'add' ? 'Adicionar Status de Cliente' : 'Editar Status de Cliente'}
          </Typography>

          {errorMessage && <Alert severity="error">{errorMessage}</Alert>}

          <TextField
            label="Nome do Status"
            fullWidth
            value={statusForm.nome}
            onChange={(e) => setStatusForm({ ...statusForm, nome: e.target.value })}
            sx={{ marginBottom: 2 }}
          />

          <Button variant="contained" color="primary" onClick={handleSubmitForm}>
            {modalType === 'add' ? 'Adicionar' : 'Salvar'}
          </Button>
        </Box>
      </Modal>

      <Snackbar
        open={showSnackbar}
        autoHideDuration={6000}
        onClose={() => setShowSnackbar(false)}
        message={successMessage}
      />
    </Box>
  );
};

export default StatusCliente;
