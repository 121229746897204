import React, { useState, useEffect } from 'react';
import { Box, Modal, Button, TextField, Select, MenuItem, Typography, Snackbar, Alert, IconButton, List, ListItem, ListItemText, Tooltip } from '@mui/material';
import { Edit as EditIcon, Delete as DeleteIcon, Visibility as ViewIcon, Close as CloseIcon } from '@mui/icons-material';
import Calendar from 'react-calendar';
import axios from 'axios';
import 'react-calendar/dist/Calendar.css';
import { CircularProgress } from '@mui/material';
import { useNavigate } from 'react-router-dom'; // 🔹 Importa a navegação

import './CalendarStyles.css';  // Arquivo CSS que contém o estilo para os dias com feriados

const Feriados = () => {
  const [feriados, setFeriados] = useState([]);
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [openModal, setOpenModal] = useState(false);
  const [modalType, setModalType] = useState('add'); // 'add', 'edit', 'view'
  const [feriadoForm, setFeriadoForm] = useState({ nome: '', tipo: 'Nacional' });
  const [selectedFeriado, setSelectedFeriado] = useState(null);
  const [successMessage, setSuccessMessage] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [showSnackbar, setShowSnackbar] = useState(false);

  const [selectedMonth, setSelectedMonth] = useState(new Date().getMonth());
const [selectedYear, setSelectedYear] = useState(new Date().getFullYear());


  const [feriadosNacionais, setFeriadosNacionais] = useState([]);
  const [loading, setLoading] = useState(true);
    const navigate = useNavigate(); // 🔹 Hook para navegação



  useEffect(() => {
    loadFeriados();
  }, []);

  useEffect(() => {
    fetchFeriadosNacionais();
  }, []);

  const loadFeriados = () => {
    const token = localStorage.getItem('token');
    axios.get(`${process.env.REACT_APP_API_URL}/api/feriados`, {
      headers: { Authorization: `Bearer ${token}` }
    })
      .then(response => {
        console.log('Feriados do banco:', response.data);
        setFeriados(response.data);
      })
      .catch(() => setErrorMessage('Erro ao carregar feriados.'));
  };
  
  
  const fetchFeriadosNacionais = async () => {
    setLoading(true);
    try {
      const year = new Date().getFullYear();
      const response = await axios.get(`https://date.nager.at/api/v3/PublicHolidays/${year}/BR`);
      console.log('Feriados Nacionais:', response.data); // Verifique os dados recebidos
      setFeriadosNacionais(response.data);
    } catch (error) {
      console.error('Erro ao buscar feriados nacionais:', error);
    } finally {
      setLoading(false);
    }
  };
  
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 3;
  
  // Calcular os itens da página atual
  const startIndex = (currentPage - 1) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;
  const currentItems = feriadosNacionais.slice(startIndex, endIndex);
  
  // Função para mudar de página
  const handlePageChange = (newPage) => {
    setCurrentPage(newPage);
  };
  
  
  

  // Formatar a data no formato dd/mm/yyyy
  const formatarData = (dataISO) => {
    const data = new Date(dataISO);
    const dia = String(data.getDate()).padStart(2, '0');
    const mes = String(data.getMonth() + 1).padStart(2, '0');
    const ano = data.getFullYear();
    return `${dia}/${mes}/${ano}`;
  };

  const handleDateClick = (date) => {
    setSelectedDate(date);
    setModalType('add');
    setOpenModal(true);
  };


  const getFeriadosDoMesAtual = () => {
    const dataAtual = new Date();
    const mesAtual = dataAtual.getMonth(); // Mês atual (0 = janeiro, 11 = dezembro)
    const anoAtual = dataAtual.getFullYear(); // Ano atual
  
    return feriadosNacionais.filter((feriado) => {
      const dataFeriado = new Date(feriado.date);
      return dataFeriado.getMonth() === mesAtual && dataFeriado.getFullYear() === anoAtual;
    });
  };

  const getFeriadosPorPeriodo = () => {
    return feriadosNacionais.filter((feriado) => {
      const dataFeriado = new Date(feriado.date);
      return (
        dataFeriado.getMonth() === selectedMonth &&
        dataFeriado.getFullYear() === selectedYear
      );
    });
  };
  

  const currentPageItems = () => {
    const feriadosDoPeriodo = getFeriadosPorPeriodo();
    const startIndex = (currentPage - 1) * itemsPerPage;
    const endIndex = startIndex + itemsPerPage;
    return feriadosDoPeriodo.slice(startIndex, endIndex);
  };
  
  const totalPages = Math.ceil(getFeriadosPorPeriodo().length / itemsPerPage);
  
  
  
  const handleOpenModal = (type, feriado = null) => {
    setModalType(type);
    setSelectedFeriado(feriado);
    if (feriado) {
      setFeriadoForm({ nome: feriado.nome, tipo: feriado.tipo });
      setSelectedDate(new Date(feriado.data));
    } else {
      setFeriadoForm({ nome: '', tipo: 'Nacional' });
    }
    setOpenModal(true);
  };

  const handleCloseModal = () => {
    setOpenModal(false);
    setFeriadoForm({ nome: '', tipo: 'Nacional' });
    setSelectedFeriado(null);
  };

  const handleSubmitForm = () => {
    const token = localStorage.getItem('token');
    if (!feriadoForm.nome || !feriadoForm.tipo) {
      setErrorMessage('Preencha todos os campos.');
      return;
    }

    const formData = { nome: feriadoForm.nome, tipo: feriadoForm.tipo, data: selectedDate.toISOString().split('T')[0] };

    if (modalType === 'edit' && selectedFeriado) {
      axios.put(`${process.env.REACT_APP_API_URL}/api/feriados/${selectedFeriado.id}`, formData, {
        headers: { Authorization: `Bearer ${token}` }
      })
        .then(() => {
          setSuccessMessage('Feriado atualizado com sucesso.');
          setShowSnackbar(true);
          handleCloseModal();
          loadFeriados();
        })
        .catch(() => setErrorMessage('Erro ao atualizar feriado.'));
    } else {
      axios.post(`${process.env.REACT_APP_API_URL}/api/feriados`, formData, {
        headers: { Authorization: `Bearer ${token}` }
      })
        .then(() => {
          setSuccessMessage('Feriado cadastrado com sucesso.');
          setShowSnackbar(true);
          handleCloseModal();
          loadFeriados();
        })
        .catch(() => setErrorMessage('Erro ao cadastrar feriado.'));
    }
  };

  const handleDeleteFeriado = (id) => {
    const token = localStorage.getItem('token');
    axios.delete(`${process.env.REACT_APP_API_URL}/api/feriados/${id}`, {
      headers: { Authorization: `Bearer ${token}` }
    })
      .then(() => {
        setSuccessMessage('Feriado deletado com sucesso.');
        setShowSnackbar(true);
        loadFeriados();
      })
      .catch(() => setErrorMessage('Erro ao deletar feriado.'));
  };

  // Função para marcar dias no calendário que possuem feriados
  const isFeriado = (date) => {
    const diaFormatado = date.toISOString().split('T')[0]; // Converte a data no formato ISO
    return feriados.some(feriado => feriado.data === diaFormatado); // Verifica se a data do calendário está na lista de feriados
  };
  


  return (
    <Box display="flex">
      
      {/* Calendário */}
      <Box flex="1" padding={2}>
 <Typography
        variant="h6"
        sx={{
          mb: 2,
          fontWeight: 'bold',
          color: '#6a11cb',
          textAlign: 'left',
        }}
      >
     
      Calendário de feriados
    </Typography>

<Calendar
  value={selectedDate}
  onClickDay={handleDateClick}
  tileClassName={({ date, view }) => {
    if (view === 'month') {
      const diaFormatado = date.toISOString().split('T')[0];
      const hoje = new Date().toISOString().split('T')[0];

      if (diaFormatado === hoje) {
        return 'tile-today'; // Data de hoje
      }

      if (isFeriado(date)) {
        return 'tile-holiday'; // Feriado do banco de dados
      }

      return 'tile-default'; // Outras datas
    }
    return null;
  }}
/>


<Box marginTop={3} width="100%" maxWidth="600px">
  <Typography variant="h6" textAlign="center" gutterBottom>
    Feriados Nacionais
  </Typography>

  {/* Controles de Seleção */}
  <Box display="flex" justifyContent="center" alignItems="center" marginBottom={2}>
    <Select
      value={selectedMonth}
      onChange={(e) => setSelectedMonth(e.target.value)}
      sx={{ marginRight: 2 }}
    >
      {Array.from({ length: 12 }, (_, i) => (
        <MenuItem key={i} value={i}>
          {new Date(0, i).toLocaleString('default', { month: 'long' })}
        </MenuItem>
      ))}
    </Select>
    <Select
      value={selectedYear}
      onChange={(e) => setSelectedYear(e.target.value)}
    >
      {Array.from({ length: 5 }, (_, i) => {
        const year = new Date().getFullYear() - 2 + i;
        return (
          <MenuItem key={year} value={year}>
            {year}
          </MenuItem>
        );
      })}
    </Select>

    
  </Box>



  {/* Lista de Feriados e Paginação */}
  {loading ? (
    <Box display="flex" justifyContent="center" alignItems="center" height="100px">
      <CircularProgress />
    </Box>
  ) : (
    <Box className="feriados-nacionais">
      {currentPageItems().map((feriado) => (
        <Box key={feriado.date} className="feriado-item">
          <Typography variant="body1" className="feriado-date">
            {formatarData(feriado.date)}
          </Typography>
          <Typography variant="body2" className="feriado-name">
            {feriado.localName}
          </Typography>
        </Box>
      ))}
      <Box className="pagination">
        {Array.from({ length: totalPages }).map((_, index) => (
          <button
            key={index}
            className={`page-button ${currentPage === index + 1 ? 'active' : ''}`}
            onClick={() => handlePageChange(index + 1)}
          >
            {index + 1}
          </button>
        ))}
      </Box>
    </Box>
  )}
</Box>
 {/* 🔹 Botão para voltar */}
 <Box sx={{ mt: 3 }}>
        <Button variant="contained" color="secondary" onClick={() => navigate(-1)}>
          Voltar
        </Button>
      </Box>









      </Box>

      {/* Lista de Feriados */}
      <Box flex="1" padding={2} style={{ maxHeight: '500px', overflowY: 'auto' }}>
        <Typography variant="h6">Lista de Feriados</Typography>
        <List>
          {feriados.map((feriado) => (
            <ListItem key={feriado.id}>
              <ListItemText primary={`${feriado.nome} (${feriado.tipo})`} secondary={formatarData(feriado.data)} />
              <Tooltip title="Visualizar">
                <IconButton color="primary" onClick={() => handleOpenModal('view', feriado)}>
                  <ViewIcon />
                </IconButton>
              </Tooltip>
              <Tooltip title="Editar">
                <IconButton color="primary" onClick={() => handleOpenModal('edit', feriado)}>
                  <EditIcon />
                </IconButton>
              </Tooltip>
              <Tooltip title="Remover">
                <IconButton color="error" onClick={() => handleDeleteFeriado(feriado.id)}>
                  <DeleteIcon />
                </IconButton>
              </Tooltip>
            </ListItem>
          ))}
        </List>

      </Box>



      {/* Modal para Adicionar/Editar/Visualizar */}
      <Modal open={openModal} onClose={handleCloseModal}>
        <Box sx={{
          padding: 4,
          backgroundColor: 'white',
          margin: 'auto',
          width: '50%',
          marginTop: '5%',
          maxHeight: '80vh',
          overflowY: 'auto',
          position: 'relative',
        }}>
          <IconButton sx={{ position: 'absolute', top: 8, right: 8 }} onClick={handleCloseModal}>
            <CloseIcon />
          </IconButton>

          <Typography variant="h6">
            {modalType === 'add' ? 'Adicionar Feriado' : modalType === 'edit' ? 'Editar Feriado' : 'Visualizar Feriado'}
          </Typography>

          {errorMessage && <Alert severity="error">{errorMessage}</Alert>}

          <TextField
            label="Nome do Feriado"
            fullWidth
            value={feriadoForm.nome}
            disabled={modalType === 'view'}
            onChange={(e) => setFeriadoForm({ ...feriadoForm, nome: e.target.value })}
            sx={{ marginBottom: 2 }}
          />

          <Select
            label="Tipo de Feriado"
            fullWidth
            value={feriadoForm.tipo}
            disabled={modalType === 'view'}
            onChange={(e) => setFeriadoForm({ ...feriadoForm, tipo: e.target.value })}
            sx={{ marginBottom: 2 }}
          >
            <MenuItem value="Nacional">Nacional</MenuItem>
            <MenuItem value="Estadual">Estadual</MenuItem>
            <MenuItem value="Municipal">Municipal</MenuItem>
          </Select>

          {modalType !== 'view' && (
            <Button variant="contained" color="primary" onClick={handleSubmitForm}>
              {modalType === 'add' ? 'Adicionar' : 'Salvar'}
            </Button>
          )}
        </Box>
      </Modal>

      {/* Snackbar */}
      <Snackbar
        open={showSnackbar}
        autoHideDuration={6000}
        onClose={() => setShowSnackbar(false)}
        message={successMessage}
      />

   

       
    </Box>
  );
};

export default Feriados;
