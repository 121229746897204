import React, { useState } from 'react';
import { Table, TableHead, TableRow, TableCell, TableBody, IconButton, Tooltip, Button } from '@mui/material';
import { Edit as EditIcon, Delete as DeleteIcon, FileCopy as DuplicateIcon, Add as AddIcon } from '@mui/icons-material';
import VigenciaModal from './VigenciaModal';

const VigenciaGrupoClienteTable = ({ vigencias, setVigencias }) => {
  const [openVigenciaModal, setOpenVigenciaModal] = useState(false);
  const [selectedVigencia, setSelectedVigencia] = useState(null);

  const handleAddVigencia = () => {
    setSelectedVigencia(null);
    setOpenVigenciaModal(true);
  };

  const handleEditVigencia = (vigencia) => {
    setSelectedVigencia(vigencia);
    setOpenVigenciaModal(true);
  };

  const handleDeleteVigencia = (id) => {
    setVigencias(prev => prev.filter(v => v.id !== id));
  };

  const handleSaveVigencia = (vigencia) => {
    if (vigencia.id) {
      setVigencias(prev => prev.map(v => (v.id === vigencia.id ? vigencia : v)));
    } else {
      setVigencias(prev => [...prev, { ...vigencia, id: Date.now() }]);
    }
    setOpenVigenciaModal(false);
  };

  return (
    <>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>Data Inicial</TableCell>
            <TableCell>Data Final</TableCell>
            <TableCell>Status</TableCell>
            <TableCell>Ações</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {vigencias.map(vigencia => (
            <TableRow key={vigencia.id}>
              <TableCell>{new Date(vigencia.dataInicial).toLocaleDateString('pt-BR')}</TableCell>
              <TableCell>{new Date(vigencia.dataFinal).toLocaleDateString('pt-BR')}</TableCell>
              <TableCell>{vigencia.status}</TableCell>
              <TableCell>
                <Tooltip title="Editar">
                  <IconButton color="primary" onClick={() => handleEditVigencia(vigencia)}>
                    <EditIcon />
                  </IconButton>
                </Tooltip>
                <Tooltip title="Duplicar">
                  <IconButton color="secondary" onClick={() => handleSaveVigencia({ ...vigencia, id: Date.now() })}>
                    <DuplicateIcon />
                  </IconButton>
                </Tooltip>
                <Tooltip title="Excluir">
                  <IconButton color="error" onClick={() => handleDeleteVigencia(vigencia.id)}>
                    <DeleteIcon />
                  </IconButton>
                </Tooltip>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>

      <Button
        variant="outlined"
        startIcon={<AddIcon />}
        sx={{ mt: 2 }}
        onClick={handleAddVigencia}
      >
        Adicionar Vigência
      </Button>

      <VigenciaModal
        open={openVigenciaModal}
        onClose={() => setOpenVigenciaModal(false)}
        vigencia={selectedVigencia}
        onSave={handleSaveVigencia}
      />
    </>
  );
};

export default VigenciaGrupoClienteTable;
