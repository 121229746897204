import React, { useState } from 'react';
import {
  Box,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Button,
  Modal,
  TextField,
  Tooltip,
  IconButton,
  Paper,
  Divider,
  List,
  ListItem,
  ListItemText,
  Checkbox,
  Grid,
  MenuItem,
  TablePagination,
} from '@mui/material';
import {
  Add as AddIcon,
  Edit as EditIcon,
  Delete as DeleteIcon,
  Close as CloseIcon,
  FilterList as FilterListIcon,
} from '@mui/icons-material';
import { Visibility as VisibilityIcon } from '@mui/icons-material'; // Import do ícone
import { useEffect } from 'react'; 
import axios from 'axios'; 




const ModeloRegimeTributario = () => {
  const [regimesFederal, setRegimesFederal] = useState([]);
  const [regimesEstadual, setRegimesEstadual] = useState([]);
  const [regimesMunicipal, setRegimesMunicipal] = useState([]);
  const [openModal, setOpenModal] = useState(false);
  const [selectedRegime, setSelectedRegime] = useState(null);
  const [tarefas, setTarefas] = useState({});
  const [novaTarefa, setNovaTarefa] = useState('');
  const [selectedTarefas, setSelectedTarefas] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);

 

  useEffect(() => {
    const fetchRegimesTributarios = async () => {
      try {
        const response = await axios.get('/api/regimes-tributarios');
        const regimes = response.data;
  
        // Filtrar por esfera
        setRegimesFederal(regimes.filter((r) => r.esfera === 'Federal'));
        setRegimesEstadual(regimes.filter((r) => r.esfera === 'Estadual'));
        setRegimesMunicipal(regimes.filter((r) => r.esfera === 'Municipal'));
      } catch (error) {
        console.error('Erro ao buscar regimes tributários:', error);
      }
    };
  
    fetchRegimesTributarios();
  }, []);

  useEffect(() => {
    const fetchRegimesTributarios = async () => {
      try {
        const token = localStorage.getItem('token');
        if (!token) {
          console.error('Token não encontrado. Faça login novamente.');
          return;
        }
  
        const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/regimes-tributarios`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
  
        const regimes = response.data;
  
        // Filtrar por esfera
        setRegimesFederal(regimes.filter((r) => r.esfera === 'Federal'));
        setRegimesEstadual(regimes.filter((r) => r.esfera === 'Estadual'));
        setRegimesMunicipal(regimes.filter((r) => r.esfera === 'Municipal'));
      } catch (error) {
        console.error('Erro ao buscar regimes tributários:', error);
      }
    };
  
    fetchRegimesTributarios();
  }, []);
  
  

  const rows = Array.from({ length: 15 }, (_, index) => ({
    codigo: `00${index + 1}`,
    classificacao: index % 2 === 0 ? 'A' : 'B',
    nome: `Nome da Obrigação ${index + 1}`,
    mininome: `Mini${index + 1}`,
    departamento: index % 2 === 0 ? 'Financeiro' : 'RH',
    tipo: index % 3 === 0 ? 'Urgente' : 'Regular',
    grupo: index % 2 === 0 ? 'Administrativo' : 'Recursos Humanos',
  }));

  const handleOpenModal = (regime) => {
    setSelectedRegime(regime);
    setSelectedTarefas(tarefas[regime.id] || []);
    setOpenModal(true);
  };

  const handleCloseModal = () => {
    setOpenModal(false);
    setSelectedRegime(null);
    setSelectedTarefas([]);
    setNovaTarefa('');
  };

  const handleAddTarefa = () => {
    if (novaTarefa.trim()) {
      setSelectedTarefas((prev) => [...prev, novaTarefa.trim()]);
      setNovaTarefa('');
    }
  };

  const handleRemoveTarefa = (tarefa) => {
    setSelectedTarefas((prev) => prev.filter((t) => t !== tarefa));
  };

  const handleSaveTarefas = () => {
    setTarefas((prev) => ({ ...prev, [selectedRegime.id]: selectedTarefas }));
    handleCloseModal();
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  return (
    <Box sx={{ padding: 4 }}>
      <Typography
             variant="h6"
             sx={{
               mb: 2,
               fontWeight: 'bold',
               color: '#6a11cb',
               textAlign: 'left',
             }}
           >
          
           Modelo de regime tributário
         </Typography>

  {/* Botão Adicionar */}
  <Box sx={{ display: 'flex', justifyContent: 'flex-start', marginBottom: 2 }}>
  <Button
      variant="contained"
      color="primary"
      startIcon={<AddIcon />}
      onClick={() => handleOpenModal({})} // Chama a função para abrir o modal
    >
      Adicionar Regime
    </Button>
  </Box>

      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Nome do Regime</TableCell>
              <TableCell>Esfera Federal</TableCell>
              <TableCell>Esfera Estadual</TableCell>
              <TableCell>Esfera Municipal</TableCell>
              <TableCell align="center">Ações</TableCell>
            </TableRow>
          </TableHead>


          <TableBody>
  {[...regimesFederal, ...regimesEstadual, ...regimesMunicipal].map((regime) => (
    <TableRow key={regime.id}>
      <TableCell>{regime.nome}</TableCell>
      <TableCell>{regime.esfera === 'Federal' ? regime.nome : ''}</TableCell>
      <TableCell>{regime.esfera === 'Estadual' ? regime.nome : ''}</TableCell>
      <TableCell>{regime.esfera === 'Municipal' ? regime.nome : ''}</TableCell>
      <TableCell align="center">
        <Tooltip title="Editar">
          <IconButton onClick={() => handleOpenModal(regime)}>
            <EditIcon color="primary" />
          </IconButton>
        </Tooltip>
        <Tooltip title="Visualizar">
          <IconButton onClick={() => handleOpenModal(regime)}>
            <VisibilityIcon color="action" />
          </IconButton>
        </Tooltip>
        <Tooltip title="Deletar">
          <IconButton>
            <DeleteIcon color="error" />
          </IconButton>
        </Tooltip>
      </TableCell>
    </TableRow>
  ))}
</TableBody>



        </Table>
      </TableContainer>

      {selectedRegime && (
        <Modal open={openModal} onClose={handleCloseModal}>
          <Box
            sx={{
              padding: 4,
              backgroundColor: 'white',
              margin: 'auto',
              width: '80%',
              height: '90vh',
              borderRadius: 2,
              boxShadow: 24,
              overflow: 'hidden',
              position: 'relative',
              display: 'flex',
              flexDirection: 'column',
              resize: 'both',
            }}
          >
            <IconButton
              sx={{ position: 'absolute', top: 8, right: 8 }}
              onClick={handleCloseModal}
            >
              <CloseIcon />
            </IconButton>

            <Typography variant="h6" gutterBottom>
              Gerenciar Regime - {selectedRegime.nome}
            </Typography>
            <Divider sx={{ marginBottom: 2 }} />

            <Grid container spacing={3}>
  {/* Campos organizados lado a lado */}
  <Grid item xs={12} md={3}>
    <TextField
      label="Nome do Regime"
      value={novaTarefa}
      onChange={(e) => setNovaTarefa(e.target.value)}
      fullWidth
    />
  </Grid>
  <Grid item xs={12} md={3}>
  <TextField
  select
  label="Federal"
  fullWidth
  value={selectedRegime?.federal || ''}
  onChange={(e) =>
    setSelectedRegime({
      ...selectedRegime,
      federal: e.target.value,
    })
  }
>
  {regimesFederal.map((regime) => (
    <MenuItem key={regime.id} value={regime.nome}>
      {regime.nome}
    </MenuItem>
  ))}
</TextField>
  </Grid>
  <Grid item xs={12} md={3}>
  <TextField
  select
  label="Estadual"
  fullWidth
  value={selectedRegime?.estadual || ''}
  onChange={(e) =>
    setSelectedRegime({
      ...selectedRegime,
      estadual: e.target.value,
    })
  }
>
  {regimesEstadual.map((regime) => (
    <MenuItem key={regime.id} value={regime.nome}>
      {regime.nome}
    </MenuItem>
  ))}
</TextField>
  </Grid>
  <Grid item xs={12} md={3}>
  <TextField
  select
  label="Municipal"
  fullWidth
  value={selectedRegime?.municipal || ''}
  onChange={(e) =>
    setSelectedRegime({
      ...selectedRegime,
      municipal: e.target.value,
    })
  }
>
  {regimesMunicipal.map((regime) => (
    <MenuItem key={regime.id} value={regime.nome}>
      {regime.nome}
    </MenuItem>
  ))}
</TextField>
  </Grid>

  {/* Botão Adicionar */}


 
</Grid>



            <Box sx={{ flex: 2, marginTop: 3 }}>
              <Typography variant="subtitle1" sx={{ marginBottom: 2 }}>
                Tabela de Dados
              </Typography>
              <TableContainer component={Paper} sx={{ maxHeight: '40vh' }}>
                <Table stickyHeader>
                  <TableHead>
                    <TableRow>
                      <TableCell padding="checkbox">
                        <Checkbox />
                      </TableCell>
                      {[
                        'Código',
                        'Classificação',
                        'Nome da Obrigação',
                        'Mininome',
                        'Departamento',
                        'Tipo do Serviço',
                        'Grupo do Serviço',
                      ].map((header) => (
                        <TableCell key={header}>
                          {header}
                          <IconButton size="small">
                            <FilterListIcon />
                          </IconButton>
                        </TableCell>
                      ))}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {rows
                      .slice(
                        page * rowsPerPage,
                        page * rowsPerPage + rowsPerPage
                      )
                      .map((row, index) => (
                        <TableRow key={index}>
                          <TableCell padding="checkbox">
                            <Checkbox />
                          </TableCell>
                          <TableCell>{row.codigo}</TableCell>
                          <TableCell>{row.classificacao}</TableCell>
                          <TableCell>{row.nome}</TableCell>
                          <TableCell>{row.mininome}</TableCell>
                          <TableCell>{row.departamento}</TableCell>
                          <TableCell>{row.tipo}</TableCell>
                          <TableCell>{row.grupo}</TableCell>
                        </TableRow>
                      ))}
                  </TableBody>
                </Table>
              </TableContainer>
              <TablePagination
                rowsPerPageOptions={[5, 10, 15]}
                component="div"
                count={rows.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
              />
            </Box>

            <Button
              variant="contained"
              color="primary"
              fullWidth
              sx={{ marginTop: 3 }}
              onClick={handleSaveTarefas}
            >
              Salvar e Fechar
            </Button>
          </Box>
        </Modal>
      )}
    </Box>
  );
};

export default ModeloRegimeTributario;
