import React, { useState, useMemo } from 'react';
import {
  Box,
  Button,
  Modal,
  Typography,
  TextField,
} from '@mui/material';
import { MaterialReactTable } from 'material-react-table';
import { NearMeOutlined } from '@mui/icons-material';

const generateObligationsData = (numClientes) => {
  const obligations = ['Imposto de Renda', 'ISS', 'ICMS', 'Folha de Pagamento', 'Contribuições'];
  return Array.from({ length: numClientes }).map((_, i) => ({
    id: i + 1,
    cliente: `Cliente ${i + 1}`,
    obrigacao: obligations[i % obligations.length],
    vencimento: `2024-11-${(i % 30) + 1}`,
    status: i % 2 === 0 ? 'Pendente' : 'Concluído',
  }));
};

const GerarRecorrentes = () => {
  const [data] = useState(generateObligationsData(10));  // Simula dados para 10 clientes
  const [modalGerarOpen, setModalGerarOpen] = useState(false);
  const [rowSelection, setRowSelection] = useState({});
  const [newTask, setNewTask] = useState({ tarefa: '' });

  const selectedCount = useMemo(() => Object.keys(rowSelection).length, [rowSelection]);

  // Abre a modal e define tarefa com base nas linhas selecionadas
  const handleGerarOpen = () => {
    const selectedRows = Object.keys(rowSelection).map((key) => data[key]);
    const tarefaNome = selectedRows.map((row) => row.obrigacao).join(', ');
    setNewTask({ tarefa: tarefaNome });
    setModalGerarOpen(true);
  };

  const handleGerarClose = () => {
    setModalGerarOpen(false);
    setNewTask({ tarefa: '' });
  };

  const columns = useMemo(() => [
    { accessorKey: 'id', header: 'ID' },
    { accessorKey: 'cliente', header: 'Cliente' },
    { accessorKey: 'obrigacao', header: 'Obrigação' },
    { accessorKey: 'vencimento', header: 'Vencimento' },
    { accessorKey: 'status', header: 'Status' },
  ], []);

  return (
    <Box sx={{ padding: 3, backgroundColor: '#f9f9f9', minHeight: '100vh' }}>
      {/* Botão Gerar */}
      <Box sx={{ display: 'flex', justifyContent: 'flex-end', mb: 3 }}>
        <Button
          variant="contained"
          color="primary"
          onClick={handleGerarOpen}
          startIcon={<NearMeOutlined />}
          disabled={selectedCount === 0}  // Desabilita se nenhuma linha estiver selecionada
          sx={{
            textTransform: 'none',
            fontWeight: 'bold',
          }}
        >
          Gerar
        </Button>
      </Box>

      {/* Tabela */}
      <MaterialReactTable
        columns={columns}
        data={data}
        enableRowSelection
        onRowSelectionChange={setRowSelection}
        state={{ rowSelection }}
      />

      {/* Modal de Geração */}
      <Modal open={modalGerarOpen} onClose={handleGerarClose}>
        <Box
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: '90%',
            maxWidth: 600,
            bgcolor: 'background.paper',
            boxShadow: 24,
            p: 4,
            borderRadius: 2,
            textAlign: 'center',
          }}
        >
          <Typography variant="h6" mb={3}>
            Gerar Nova Tarefa
          </Typography>

          <Typography variant="body1" mb={3}>
            Campos aqui para definir as configurações da nova tarefa.
          </Typography>

          <TextField
            fullWidth
            label="Nome da Tarefa"
            value={newTask.tarefa}
            InputProps={{ readOnly: true }}
            sx={{ mb: 3 }}
          />

          <Box sx={{ display: 'flex', justifyContent: 'flex-end', gap: 2 }}>
            <Button variant="outlined" color="secondary" onClick={handleGerarClose}>
              Fechar
            </Button>
            <Button
              variant="contained"
              color="primary"
              onClick={() => {
                console.log('Tarefa Gerada:', newTask.tarefa);
                handleGerarClose();
              }}
            >
              Confirmar
            </Button>
          </Box>
        </Box>
      </Modal>
    </Box>
  );
};

export default GerarRecorrentes;
