import React, { useState, useMemo } from 'react';
import {
  Box,
  Typography,
  Button,
  TextField,
  MenuItem,
  Grid,
  Paper,
} from '@mui/material';
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  LineChart,
  Line,
  ResponsiveContainer,
} from 'recharts';
import * as XLSX from 'xlsx';
import jsPDF from 'jspdf';
import 'jspdf-autotable';

// Simulação de dados
const generateReportData = () => {
  const obligations = ['IRPF', 'ISS', 'ICMS', 'Folha de Pagamento'];
  const statusOptions = ['Pendente', 'Em andamento', 'Concluído'];
  const usuarios = ['Ana Silva', 'Carlos Santos', 'Mariana Souza'];

  return Array.from({ length: 12 }).map((_, i) => ({
    id: i + 1,
    cliente: `Cliente ${i + 1}`,
    obrigacao: obligations[i % obligations.length],
    usuario: usuarios[i % usuarios.length],
    departamento: 'Financeiro',
    vencimento: `2024-11-${(i % 30) + 1}`,
    status: statusOptions[i % statusOptions.length],
    valor: Math.floor(Math.random() * 5000) + 1000,  // Valores simulados
  }));
};

const Relatorios = () => {
  const [data, setData] = useState(generateReportData());
  const [filtros, setFiltros] = useState({
    cliente: '',
    status: '',
    usuario: '',
  });

  // Aplica filtros nos dados da tabela e gráficos
  const dadosFiltrados = useMemo(() => {
    return data.filter((item) => {
      return (
        (filtros.cliente === '' || item.cliente === filtros.cliente) &&
        (filtros.status === '' || item.status === filtros.status) &&
        (filtros.usuario === '' || item.usuario === filtros.usuario)
      );
    });
  }, [data, filtros]);

  // Exportar para Excel
  const exportExcel = () => {
    const worksheet = XLSX.utils.json_to_sheet(dadosFiltrados);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, 'Relatório');
    XLSX.writeFile(workbook, 'relatorio.xlsx');
  };

  // Exportar para PDF
  const exportPDF = () => {
    const doc = new jsPDF();
    doc.text('Relatório de Obrigações', 14, 10);
    const tableData = dadosFiltrados.map((row) => [
      row.cliente,
      row.obrigacao,
      row.status,
      formatDate(row.vencimento),
      row.valor,
    ]);
    doc.autoTable({
      head: [['Cliente', 'Obrigação', 'Status', 'Vencimento', 'Valor']],
      body: tableData,
    });
    doc.save('relatorio.pdf');
  };

  // Formatar data para dd/mm/yyyy
  const formatDate = (dateString) => {
    const [year, month, day] = dateString.split('-');
    return `${day}/${month}/${year}`;
  };

  return (
    <Box sx={{ padding: 1, backgroundColor: '#fff', minHeight: '100vh' }}>
      <Typography variant="h5" fontWeight="bold" mb={3}>
        Relatórios de Obrigações
      </Typography>

      {/* Filtros */}
      <Paper sx={{ p: 3, mb: 4 }}>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={4}>
            <TextField
              label="Cliente"
              select
              fullWidth
              value={filtros.cliente}
              onChange={(e) => setFiltros({ ...filtros, cliente: e.target.value })}
            >
              <MenuItem value="">Todos</MenuItem>
              {data.map((item) => (
                <MenuItem key={item.id} value={item.cliente}>
                  {item.cliente}
                </MenuItem>
              ))}
            </TextField>
          </Grid>

          <Grid item xs={12} sm={4}>
            <TextField
              label="Status"
              select
              fullWidth
              value={filtros.status}
              onChange={(e) => setFiltros({ ...filtros, status: e.target.value })}
            >
              <MenuItem value="">Todos</MenuItem>
              <MenuItem value="Pendente">Pendente</MenuItem>
              <MenuItem value="Em andamento">Em andamento</MenuItem>
              <MenuItem value="Concluído">Concluído</MenuItem>
            </TextField>
          </Grid>

          <Grid item xs={12} sm={4}>
            <TextField
              label="Usuário"
              select
              fullWidth
              value={filtros.usuario}
              onChange={(e) => setFiltros({ ...filtros, usuario: e.target.value })}
            >
              <MenuItem value="">Todos</MenuItem>
              <MenuItem value="Ana Silva">Ana Silva</MenuItem>
              <MenuItem value="Carlos Santos">Carlos Santos</MenuItem>
              <MenuItem value="Mariana Souza">Mariana Souza</MenuItem>
            </TextField>
          </Grid>
        </Grid>
      </Paper>

      {/* Gráficos */}
      <Grid container spacing={3}>
        <Grid item xs={12} md={6}>
          <Paper sx={{ p: 3 }}>
            <Typography variant="h6" mb={2}>Gráfico de Valores</Typography>
            <ResponsiveContainer width="100%" height={300}>
              <BarChart data={dadosFiltrados}>
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="cliente" />
                <YAxis />
                <Tooltip />
                <Legend />
                <Bar dataKey="valor" fill="#1976d2" />
              </BarChart>
            </ResponsiveContainer>
          </Paper>
        </Grid>

        <Grid item xs={12} md={6}>
          <Paper sx={{ p: 3 }}>
            <Typography variant="h6" mb={2}>Gráfico de Obrigações</Typography>
            <ResponsiveContainer width="100%" height={300}>
              <LineChart data={dadosFiltrados}>
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="cliente" />
                <YAxis />
                <Tooltip />
                <Legend />
                <Line type="monotone" dataKey="valor" stroke="#82ca9d" />
              </LineChart>
            </ResponsiveContainer>
          </Paper>
        </Grid>
      </Grid>

      {/* Botões de Exportação */}
      <Box sx={{ display: 'flex', justifyContent: 'flex-end', mt: 4, gap: 2 }}>
        <Button variant="contained" color="primary" onClick={exportExcel}>
          Exportar Excel
        </Button>
        <Button variant="contained" color="secondary" onClick={exportPDF}>
          Exportar PDF
        </Button>
      </Box>
    </Box>
  );
};

export default Relatorios;
