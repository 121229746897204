import React, { useEffect, useState } from 'react';
import {
  Box, Table, TableHead, TableRow, TableCell, TableBody, Button, Tooltip,
  IconButton, Modal, TextField, Typography, Alert, Snackbar
} from '@mui/material';
import { Add as AddIcon, Edit as EditIcon, Delete as DeleteIcon, Close as CloseIcon } from '@mui/icons-material';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';

const EmailConfig = () => {
  const [configs, setConfigs] = useState([]);
  const [openModal, setOpenModal] = useState(false);
  const [modalType, setModalType] = useState(''); // 'add', 'edit'
  const [selectedConfig, setSelectedConfig] = useState(null);
  const [emailForm, setEmailForm] = useState({
    email_remetente: '',
    nome_remetente: '',
    host_smtp: '',
    porta_smtp: '',
    criptografia: 'tls',
    usuario_smtp: '',
    senha_smtp: ''
  });
  const [errorMessage, setErrorMessage] = useState('');
  const [successMessage, setSuccessMessage] = useState('');
  const [showSnackbar, setShowSnackbar] = useState(false);
  const navigate = useNavigate();

  // Carregar configurações de e-mail ao montar o componente
  useEffect(() => {
    loadConfigs();
  }, []);

  const loadConfigs = () => {
    const token = localStorage.getItem('token');
    if (!token) {
      setErrorMessage('Token não encontrado. Faça login novamente.');
      return;
    }
    axios.get(`${process.env.REACT_APP_API_URL}/email-config`, {
      headers: { Authorization: `Bearer ${token}` }
    })
      .then(response => setConfigs(response.data))
      .catch(() => setErrorMessage('Erro ao carregar configurações de e-mail.'));
  };

  // Abrir o modal para adicionar ou editar
  const handleOpenModal = (type, config = null) => {
    setModalType(type);
    if (config) {
      setSelectedConfig(config);
      setEmailForm({
        email_remetente: config.email_remetente,
        nome_remetente: config.nome_remetente,
        host_smtp: config.host_smtp,
        porta_smtp: config.porta_smtp,
        criptografia: config.criptografia,
        usuario_smtp: config.usuario_smtp,
        senha_smtp: ''
      });
    } else {
      setSelectedConfig(null);
      setEmailForm({
        email_remetente: '',
        nome_remetente: '',
        host_smtp: '',
        porta_smtp: '',
        criptografia: 'tls',
        usuario_smtp: '',
        senha_smtp: ''
      });
    }
    setOpenModal(true);
  };

  // Fechar modal
  const handleCloseModal = () => {
    setOpenModal(false);
    setSelectedConfig(null);
    setErrorMessage('');
  };

  // Adicionar ou editar configuração
  const handleSubmitForm = () => {
    const token = localStorage.getItem('token');
    if (!emailForm.email_remetente || !emailForm.nome_remetente || !emailForm.host_smtp ||
        !emailForm.porta_smtp || !emailForm.criptografia || !emailForm.usuario_smtp || !emailForm.senha_smtp) {
      setErrorMessage('Todos os campos são obrigatórios.');
      return;
    }

    if (modalType === 'edit' && selectedConfig) {
      axios.put(`${process.env.REACT_APP_API_URL}/email-config/${selectedConfig.id}`, emailForm, {
        headers: { Authorization: `Bearer ${token}` }
      })
        .then(() => {
          setSuccessMessage('Configuração de e-mail atualizada com sucesso.');
          setShowSnackbar(true);
          handleCloseModal();
          loadConfigs();
        })
        .catch(() => setErrorMessage('Erro ao atualizar configuração de e-mail.'));
    } else {
      axios.post(`${process.env.REACT_APP_API_URL}/email-config`, emailForm, {
        headers: { Authorization: `Bearer ${token}` }
      })
        .then(() => {
          setSuccessMessage('Configuração de e-mail criada com sucesso.');
          setShowSnackbar(true);
          handleCloseModal();
          loadConfigs();
        })
        .catch(() => setErrorMessage('Erro ao criar configuração de e-mail.'));
    }
  };

  // Deletar configuração
  const handleDeleteConfig = (id) => {
    const token = localStorage.getItem('token');
    axios.delete(`${process.env.REACT_APP_API_URL}/email-config/${id}`, {
      headers: { Authorization: `Bearer ${token}` }
    })
      .then(() => {
        setSuccessMessage('Configuração de e-mail deletada com sucesso.');
        setShowSnackbar(true);
        loadConfigs();
      })
      .catch(() => setErrorMessage('Erro ao deletar configuração de e-mail.'));
  };

  return (
    <Box>
      <Typography variant="h6" sx={{ mb: 2, fontWeight: 'bold', color: '#6a11cb', textAlign: 'left' }}>
        Configuração de E-mail
      </Typography>
      
      <Button
        variant="contained"
        color="primary"
        startIcon={<AddIcon />}
        onClick={() => handleOpenModal('add')}
      >
        Adicionar Configuração
      </Button>

      <Table>
        <TableHead>
          <TableRow>
            <TableCell>Remetente</TableCell>
            <TableCell>Host SMTP</TableCell>
            <TableCell>Porta</TableCell>
            <TableCell>Criptografia</TableCell>
            <TableCell>Ações</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {configs.map(config => (
            <TableRow key={config.id}>
              <TableCell>{config.nome_remetente} ({config.email_remetente})</TableCell>
              <TableCell>{config.host_smtp}</TableCell>
              <TableCell>{config.porta_smtp}</TableCell>
              <TableCell>{config.criptografia}</TableCell>
              <TableCell>
                <Tooltip title="Editar">
                  <IconButton color="primary" onClick={() => handleOpenModal('edit', config)}>
                    <EditIcon />
                  </IconButton>
                </Tooltip>
                <Tooltip title="Deletar">
                  <IconButton color="error" onClick={() => handleDeleteConfig(config.id)}>
                    <DeleteIcon />
                  </IconButton>
                </Tooltip>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>

      {/* Modal */}
      <Modal open={openModal} onClose={handleCloseModal}>
        <Box sx={{ padding: 4, backgroundColor: 'white', margin: 'auto', width: '50%', marginTop: '5%', position: 'relative' }}>
          <IconButton sx={{ position: 'absolute', top: 8, right: 8 }} onClick={handleCloseModal}>
            <CloseIcon />
          </IconButton>

          <Typography variant="h6">{modalType === 'add' ? 'Adicionar Configuração' : 'Editar Configuração'}</Typography>

          {errorMessage && <Alert severity="error">{errorMessage}</Alert>}

          {Object.keys(emailForm).map((field) => (
            <TextField
              key={field}
              label={field.replace('_', ' ').toUpperCase()}
              fullWidth
              value={emailForm[field]}
              onChange={(e) => setEmailForm({ ...emailForm, [field]: e.target.value })}
              sx={{ marginBottom: 2 }}
              type={field === 'senha_smtp' ? 'password' : 'text'}
            />
          ))}

          <Button variant="contained" color="primary" onClick={handleSubmitForm}>
            {modalType === 'add' ? 'Adicionar' : 'Salvar'}
          </Button>
        </Box>
      </Modal>

      {/* Botão de voltar */}
      <Box sx={{ mt: 3 }}>
        <Button variant="contained" color="secondary" onClick={() => navigate(-1)}>
          Voltar
        </Button>
      </Box>

      <Snackbar open={showSnackbar} autoHideDuration={6000} onClose={() => setShowSnackbar(false)} message={successMessage} />
    </Box>
  );
};

export default EmailConfig;
