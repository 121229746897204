import React, { useState, useEffect } from 'react';
import {
  Box, Grid, TextField, Button, Select, MenuItem, Typography, Paper,
  FormControl, InputLabel, Checkbox, Divider, Snackbar, Alert
} from '@mui/material';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { FormControlLabel } from '@mui/material';


const API_BASE_URL = process.env.REACT_APP_API_URL;

const NovaOrdemServico = () => {
  const [formData, setFormData] = useState({
    empresa_id: '', cliente_id: '', servico_id: '', codigo: '',
    descricao: '', data_solicitacao: '', data_inicio: '', data_prazo: '',
    data_conclusao: '', responsavel_id: '', status: 'pendente', observacoes: '',
    valor: 0,  // 👈 Aqui

    
    is_parcelado: false, numero_parcelas: 1
  });
  const [clientes, setClientes] = useState([]);
  const [servicos, setServicos] = useState([]);
  const [responsaveis, setResponsaveis] = useState([]);
  const [showSnackbar, setShowSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [snackbarSeverity, setSnackbarSeverity] = useState('success');

  const navigate = useNavigate();

  const handleChange = (e) => {
    const { name, value } = e.target;
  
    if (name === "valor") {
      // Remove qualquer caractere não numérico, exceto vírgula e ponto
      let rawValue = value.replace(/[^\d,]/g, "");
      
      // Converte para formato monetário
      let numericValue = parseFloat(rawValue.replace(",", "."));
  
      // Se for um número válido, formata para "R$ 0,00"
      let formattedValue = isNaN(numericValue) ? "" : `R$ ${numericValue.toFixed(2).replace(".", ",")}`;
  
      setFormData({ ...formData, valor: formattedValue });
    } else {
      setFormData({ ...formData, [name]: value });
    }
  };
  
  
  

  const handleSubmit = async () => {
    try {
      const token = localStorage.getItem('token');
      const headers = { Authorization: `Bearer ${token}`, 'Content-Type': 'application/json' };
  
      await axios.post(`${API_BASE_URL}/api/ordens-servico`, formData, { headers });
  
      setSnackbarMessage("Ordem de Serviço cadastrada com sucesso!");
      setSnackbarSeverity("success");
      setShowSnackbar(true);
      navigate('/ordens-servico');
    } catch (error) {
      console.error("❌ Erro completo no frontend:", error.response ? error.response.data : error.message);
  
      setSnackbarMessage(
        error.response && error.response.data && error.response.data.message
          ? error.response.data.message
          : "Erro desconhecido ao cadastrar a ordem de serviço."
      );
      setSnackbarSeverity("error");
      setShowSnackbar(true);
    }
  };
  
  
  

  useEffect(() => {
    const fetchOptions = async () => {
      const token = localStorage.getItem('token');
      const headers = { Authorization: `Bearer ${token}` };

      try {
        const [clientesRes, servicosRes, responsaveisRes] = await Promise.all([
          axios.get(`${API_BASE_URL}/api/clientes`, { headers }),
          axios.get(`${API_BASE_URL}/api/servicos`, { headers }),
          axios.get(`${API_BASE_URL}/api/funcionarios`, { headers })
        ]);

        setClientes(clientesRes.data);
        setServicos(servicosRes.data);
        setResponsaveis(responsaveisRes.data);
      } catch (error) {
        console.error("Erro ao carregar opções:", error);
      }
    };

    fetchOptions();
  }, []);

  return (
    <Box sx={{ padding: 4, maxWidth: 1400, margin: 'auto', backgroundColor: '#ffffff' }}>
      <Typography variant="h6" gutterBottom sx={{ textAlign: 'center' }}>Cadastrar Nova Ordem de Serviço</Typography>
      <Paper elevation={3} sx={{ padding: 3, borderRadius: 3 }}>
        <Grid container spacing={2}>
          {/* Dados Gerais */}
          <Grid item xs={12} sm={6}>
            <FormControl fullWidth>
              <InputLabel>Cliente</InputLabel>
              <Select name="cliente_id" value={formData.cliente_id} onChange={handleChange}>
                {clientes.map(cliente => (
                  <MenuItem key={cliente.id} value={cliente.id}>{cliente.nome}</MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormControl fullWidth>
              <InputLabel>Serviço</InputLabel>
              <Select name="servico_id" value={formData.servico_id} onChange={handleChange}>
                {servicos.map(servico => (
                  <MenuItem key={servico.id} value={servico.id}>{servico.nome}</MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField label="Código" name="codigo" fullWidth value={formData.codigo} onChange={handleChange} />
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormControl fullWidth>
              <InputLabel>Responsável</InputLabel>
              <Select name="responsavel_id" value={formData.responsavel_id} onChange={handleChange}>
                {responsaveis.map(responsavel => (
                  <MenuItem key={responsavel.id} value={responsavel.id}>{responsavel.nome}</MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12}>
            <TextField label="Descrição" name="descricao" fullWidth multiline rows={4} value={formData.descricao} onChange={handleChange} />
          </Grid>
          <Grid item xs={12}>
            <TextField label="Observações" name="observacoes" fullWidth multiline rows={2} value={formData.observacoes} onChange={handleChange} />
          </Grid>

          {/* Datas */}
          <Grid item xs={12} sm={6}>
            <TextField label="Data Solicitação" type="date" name="data_solicitacao" fullWidth value={formData.data_solicitacao} onChange={handleChange} InputLabelProps={{ shrink: true }} />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField label="Data Início" type="date" name="data_inicio" fullWidth value={formData.data_inicio} onChange={handleChange} InputLabelProps={{ shrink: true }} />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField label="Data Prazo" type="date" name="data_prazo" fullWidth value={formData.data_prazo} onChange={handleChange} InputLabelProps={{ shrink: true }} />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField label="Data Conclusão" type="date" name="data_conclusao" fullWidth value={formData.data_conclusao} onChange={handleChange} InputLabelProps={{ shrink: true }} />
          </Grid>
          

          {/* Status e Valor */}
          <Grid item xs={12} sm={6}>
            <FormControl fullWidth>
              <InputLabel>Status</InputLabel>
              <Select name="status" value={formData.status} onChange={handleChange}>
                <MenuItem value="pendente">Pendente</MenuItem>
                <MenuItem value="em andamento">Em Andamento</MenuItem>
                <MenuItem value="concluido">Concluído</MenuItem>
                <MenuItem value="cancelado">Cancelado</MenuItem>
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={6}>
          <TextField
            label="Valor"
            name="valor"
            type="text" // Alterado para texto
            fullWidth
            value={formData.valor}
            onChange={handleChange}
            placeholder="R$ 0,00"
          />


          </Grid>

          {/* Parcelamento */}
          <Grid item xs={12}>
            <FormControlLabel
              control={
                <Checkbox checked={formData.is_parcelado} onChange={handleChange} name="is_parcelado" />
              }
              label="Este serviço possui parcelamento"
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            {formData.is_parcelado && (
              <TextField
                label="Número de Parcelas"
                name="numero_parcelas"
                type="number"
                fullWidth
                value={formData.numero_parcelas}
                onChange={handleChange}
              />
            )}
          </Grid>
        </Grid>

        {/* Botões */}
        <Divider sx={{ my: 3 }} />
        <Grid container justifyContent="flex-end" spacing={2}>
          <Grid item>
            <Button variant="contained" color="primary" onClick={handleSubmit}>Salvar</Button>
          </Grid>
          <Grid item>
            <Button variant="outlined" color="secondary" onClick={() => navigate(-1)}>Voltar</Button>
          </Grid>
        </Grid>

        {/* Snackbar */}
        <Snackbar open={showSnackbar} autoHideDuration={6000} onClose={() => setShowSnackbar(false)}>
          <Alert onClose={() => setShowSnackbar(false)} severity={snackbarSeverity}>
            {snackbarMessage}
          </Alert>
        </Snackbar>
      </Paper>
    </Box>
  );
};

export default NovaOrdemServico;
