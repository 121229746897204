import React, { useState } from 'react';
import {
  Box, Typography, Modal, TextField, IconButton, Button, Snackbar, Alert, MenuItem, Card, CardContent, CardActionArea
} from '@mui/material';
import { WhatsApp as WhatsAppIcon, Email as EmailIcon, Close as CloseIcon } from '@mui/icons-material';

const modalStyle = {
  backgroundColor: 'white',
  padding: 3,
  width: 400,
  maxWidth: '100%',
  margin: 'auto',
  marginTop: '10%',
  borderRadius: 2,
  position: 'relative',
  boxShadow: 24,
};

const Integracoes = () => {
  const [openModal, setOpenModal] = useState(null);
  const [form, setForm] = useState({ field1: '', field2: '', field3: '' });
  const [successMessage, setSuccessMessage] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [showSnackbar, setShowSnackbar] = useState(false);

  const handleOpenModal = (type) => {
    setOpenModal(type);
    setForm({ field1: '', field2: '', field3: '' });
  };

  const handleCloseModal = () => {
    setOpenModal(null);
    setErrorMessage('');
  };

  const handleSave = () => {
    if (!form.field1 || !form.field2) {
      setErrorMessage('Preencha todos os campos obrigatórios.');
      return;
    }
    setSuccessMessage('Configuração salva com sucesso!');
    setShowSnackbar(true);
    handleCloseModal();
  };

  return (
    <Box sx={{ padding: 1 }}>
      <Typography variant="h6" sx={{ mb: 2, fontWeight: 'bold', color: '#6a11cb' }}>
        Integrações
      </Typography>

      {/* Cards de Integração alinhados à esquerda */}
      <Box sx={{ display: 'flex', gap: 4, flexWrap: 'wrap', alignItems: 'flex-start', pl: 0 }}>
        <Card sx={{ width: 150, textAlign: 'center', borderRadius: 2, boxShadow: 3 }}>
          <CardActionArea onClick={() => handleOpenModal('whatsapp')} sx={{ padding: 2 }}>
            <CardContent>
              <WhatsAppIcon fontSize="large" color="success" />
              <Typography variant="h6" sx={{ mt: 1 }}>
                WhatsApp
              </Typography>
            </CardContent>
          </CardActionArea>
        </Card>

        <Card sx={{ width: 150, textAlign: 'center', borderRadius: 2, boxShadow: 3 }}>
          <CardActionArea onClick={() => handleOpenModal('email')} sx={{ padding: 2 }}>
            <CardContent>
              <EmailIcon fontSize="large" color="primary" />
              <Typography variant="h6" sx={{ mt: 1 }}>
                E-mail
              </Typography>
            </CardContent>
          </CardActionArea>
        </Card>
      </Box>

      {/* Modal de Configuração do WhatsApp */}
      <Modal open={openModal === 'whatsapp'} onClose={handleCloseModal}>
        <Box sx={modalStyle}>
          <IconButton sx={{ position: 'absolute', top: 8, right: 8 }} onClick={handleCloseModal}>
            <CloseIcon />
          </IconButton>
          <Typography variant="h6" gutterBottom>
            Configurar WhatsApp
          </Typography>

          {errorMessage && <Alert severity="error" sx={{ mb: 2 }}>{errorMessage}</Alert>}

          <TextField
            label="Token de Autenticação"
            fullWidth
            sx={{ mb: 2 }}
            value={form.field1}
            onChange={(e) => setForm({ ...form, field1: e.target.value })}
          />
          <TextField
            label="Endpoint"
            fullWidth
            sx={{ mb: 2 }}
            value={form.field2}
            onChange={(e) => setForm({ ...form, field2: e.target.value })}
          />
         {/* pergunta */}
         <TextField
            select
            label="Recursos"
            fullWidth
            sx={{ mb: 2 }}
            value={form.sslType}
            onChange={(e) => setForm({ ...form, sslType: e.target.value })}
          >
            <MenuItem value="ssl">Mensagem de texto</MenuItem>
            <MenuItem value="tls">Mensagemd e texto, imagem e arquivos</MenuItem>
          </TextField>

          <Button variant="contained" color="success" fullWidth onClick={handleSave}>
            Salvar
          </Button>
        </Box>
      </Modal>

      {/* Modal de Configuração do E-mail */}
      <Modal open={openModal === 'email'} onClose={handleCloseModal}>
        <Box sx={modalStyle}>
          <IconButton sx={{ position: 'absolute', top: 8, right: 8 }} onClick={handleCloseModal}>
            <CloseIcon />
          </IconButton>
          <Typography variant="h6" gutterBottom>
            Configurar E-mail
          </Typography>

          {errorMessage && <Alert severity="error" sx={{ mb: 2 }}>{errorMessage}</Alert>}

          <TextField
            label="Servidor SMTP"
            fullWidth
            sx={{ mb: 2 }}
            value={form.field1}
            onChange={(e) => setForm({ ...form, field1: e.target.value })}
          />
          <TextField
            label="E-mail"
            fullWidth
            sx={{ mb: 2 }}
            value={form.field3}
            onChange={(e) => setForm({ ...form, field3: e.target.value })}
          />
          <TextField
            label="Senha"
            type="password"
            fullWidth
            sx={{ mb: 2 }}
            value={form.field2}
            onChange={(e) => setForm({ ...form, field2: e.target.value })}
          />
          <TextField
            label="Porta"
            fullWidth
            sx={{ mb: 2 }}
            value={form.field3}
            onChange={(e) => setForm({ ...form, field3: e.target.value })}
          />
           {/* Select para SSL/TLS/Sem Certificado */}
           <TextField
            select
            label="Segurança"
            fullWidth
            sx={{ mb: 2 }}
            value={form.sslType}
            onChange={(e) => setForm({ ...form, sslType: e.target.value })}
          >
            <MenuItem value="ssl">SSL</MenuItem>
            <MenuItem value="tls">TLS</MenuItem>
            <MenuItem value="none">Sem Certificado</MenuItem>
          </TextField>
          

          <Button variant="contained" color="primary" fullWidth onClick={handleSave}>
            Salvar
          </Button>
        </Box>
      </Modal>

      {/* Snackbar para Feedback */}
      <Snackbar
        open={showSnackbar}
        autoHideDuration={4000}
        onClose={() => setShowSnackbar(false)}
      >
        <Alert onClose={() => setShowSnackbar(false)} severity="success" sx={{ width: '100%' }}>
          {successMessage}
        </Alert>
      </Snackbar>
    </Box>
  );
};

export default Integracoes;
