import React, { useState, useEffect } from 'react';
import { Grid, TextField, Box, Typography, CircularProgress, Alert, Button  } from '@mui/material';
import axios from 'axios';

import { useNavigate } from 'react-router-dom'; // 🔹 Importa a navegação

const EmpresaPerfil = () => {
  const [empresa, setEmpresa] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState('');
  const navigate = useNavigate(); // 🔹 Hook para navegação

  useEffect(() => {
    const fetchEmpresa = async () => {
      try {
        const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/empresa-perfil`, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('token')}`,
          },
        });
  
        console.log("Dados da empresa recebidos:", response.data); // Debug
        setEmpresa(response.data);
      } catch (error) {
        console.error("Erro ao buscar os dados da empresa:", error.response || error);
        setError('Erro ao carregar os dados da empresa.');
      } finally {
        setLoading(false);
      }
    };
  
    fetchEmpresa();
  }, []);
  
  

  if (loading) {
    return <CircularProgress />;
  }

  if (error) {
    return <Alert severity="error">{error}</Alert>;
  }

  return (
    <Box sx={{ p: 3 }}>
       <Typography
              variant="h6"
              sx={{
                mb: 2,
                fontWeight: 'bold',
                color: '#6a11cb',
                textAlign: 'left',
              }}
            >
              Dados da empresa
            </Typography>
      <Grid container spacing={3}>
        <Grid item xs={12} sm={6}>
          <TextField label="Nome" value={empresa?.nome || ''} fullWidth disabled />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField label="CNPJ" value={empresa?.cnpj || ''} fullWidth disabled />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField label="Endereço" value={empresa?.endereco || ''} fullWidth disabled />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField label="Telefone" value={empresa?.telefone || ''} fullWidth disabled />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField label="Email" value={empresa?.email || ''} fullWidth disabled />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField label="Data de Criação" value={empresa?.data_criacao || ''} fullWidth disabled />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField label="Início da Assinatura" value={empresa?.data_inicio_assinatura || ''} fullWidth disabled />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField label="Fim da Assinatura" value={empresa?.data_fim_assinatura || ''} fullWidth disabled />
        </Grid>
      </Grid>
       {/* 🔹 Botão para voltar */}
       <Box sx={{ mt: 3 }}>
        <Button variant="contained" color="secondary" onClick={() => navigate(-1)}>
          Voltar
        </Button>
      </Box>
    </Box>
  );
};

export default EmpresaPerfil;
