import React, { useState, useEffect } from 'react';
import {
  Box, Grid, TextField, Button, Select, MenuItem, Typography, Paper,
  FormControl, InputLabel, Checkbox, Collapse, Divider, Snackbar, Alert, Tabs, Tab
} from '@mui/material';
import { Add, Description, List, Settings } from '@mui/icons-material';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { FormControlLabel } from '@mui/material';
import { NumericFormat } from 'react-number-format';



const API_BASE_URL = process.env.REACT_APP_API_URL || 'http://localhost:3980';


const NovoServico = () => {
  
  const [activeTab, setActiveTab] = useState(0);
  const [formData, setFormData] = useState({
      empresa_id: '',  // <-- Adicionado

    nome: '', 
    codigo: '', 
    classificacao: '', 
    descricao: '',
    departamento_id: '', 
    tipo_servico_id: '', 
    grupo_servico_id: '', 
    esfera: '',
    observacao: '', 
    is_parcelado: false, 
    quantidade_parcelas: 1,  // <- ALTERADO
    valor_parcela: '',  // <- ALTERADO
    data_primeira_parcela: ''  // <- ALTERADO
  });
  
  const [parcelas, setParcelas] = useState([{ numero_parcela: 1, valor: '', data_vencimento: '' }]);
  const [departamentos, setDepartamentos] = useState([]);
  const [tiposServico, setTiposServico] = useState([]);
  const [gruposServico, setGruposServico] = useState([]);
  const [showSnackbar, setShowSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [snackbarSeverity, setSnackbarSeverity] = useState('success');

  const navigate = useNavigate();

  useEffect(() => {
    const fetchOptions = async () => {
      const token = localStorage.getItem('token');
      const headers = { Authorization: `Bearer ${token}` };

      try {
        const [departamentoRes, tipoServicoRes, grupoServicoRes] = await Promise.all([
          axios.get(`${API_BASE_URL}/api/departamento`, { headers }),
          axios.get(`${API_BASE_URL}/api/tipo-servico`, { headers }),
          axios.get(`${API_BASE_URL}/api/grupo-servicos`, { headers })
        ]);

        setDepartamentos(departamentoRes.data);
        setTiposServico(tipoServicoRes.data);
        setGruposServico(grupoServicoRes.data);
      } catch (error) {
        console.error('Erro ao carregar opções:', error);
      }
    };

    fetchOptions();
  }, []);

  const handleChange = (e) => {
    const { name, value } = e.target;

    if (name === "classificacao") {
      // Remove qualquer caractere que não seja número
      const numericValue = value.replace(/\D/g, "");
      setFormData({ ...formData, [name]: numericValue });
      return;
    }
  
    // Se for valor_parcela, remove a formatação antes de salvar
    if (name === "valor_parcela") {
      const valorFormatado = value.replace(/\D/g, ""); // Remove tudo que não for número
      setFormData({ ...formData, [name]: valorFormatado ? parseFloat(valorFormatado) / 100 : "" });
      return;
    }
  
    // Se for data_primeira_parcela, formata para DD/MM/YYYY
    if (name === "data_primeira_parcela") {
      if (!value) {
        setFormData({ ...formData, [name]: "" });
        return;
      }
      if (value.includes("-")) {
        const [year, month, day] = value.split("-");
        setFormData({ ...formData, [name]: `${day}/${month}/${year}` });
        return;
      }
    }
  
    setFormData({ ...formData, [name]: value });
  };
  
  

  const handleTabChange = (event, newValue) => {
    setActiveTab(newValue);
  };

  const handleParcelamentoChange = () => {
    const { quantidade_parcelas, valor_parcela, data_primeira_parcela } = formData;
  
    // Verifica se os campos obrigatórios estão preenchidos
    if (!quantidade_parcelas || !valor_parcela || !data_primeira_parcela) {
      return;
    }
  
    const parcelasGeradas = [];
    const valorParcelaNum = parseFloat(valor_parcela) / quantidade_parcelas;
    let data = new Date(data_primeira_parcela);
  
    for (let i = 1; i <= quantidade_parcelas; i++) {
      parcelasGeradas.push({
        numero_parcela: i,
        valor: valorParcelaNum.toFixed(2),
        data_vencimento: data.toISOString().split('T')[0]
      });
  
      // Avança um mês para a próxima parcela
      data.setMonth(data.getMonth() + 1);
    }
  
    setParcelas(parcelasGeradas);
  };
  

  const limparParcelamento = () => {
    setParcelas([]);
    setFormData({ ...formData, quantidadeParcelas: 1, valorParcela: '', dataPrimeiraParcela: '' });
  };

  const handleSubmit = async () => {
    console.log("📌 Dados enviados para API antes da conversão:", formData);
  
    try {
      const token = localStorage.getItem("token");
      const headers = { Authorization: `Bearer ${token}` };

       // Converter classificação para número inteiro
    const classificacaoConvertida = formData.classificacao ? parseInt(formData.classificacao, 10) : null;

  
      // Converter o valor para número antes de enviar
      const valorConvertido = parseFloat(formData.valor_parcela);
  
      // Converter a data para o formato YYYY-MM-DD
      let dataFormatadaSQL = null;
      if (formData.data_primeira_parcela.includes("/")) {
        const [dia, mes, ano] = formData.data_primeira_parcela.split("/");
        dataFormatadaSQL = `${ano}-${mes}-${dia}`;
      } else {
        dataFormatadaSQL = formData.data_primeira_parcela;
      }
  
      const dadosFormatados = {
        ...formData,
        classificacao: classificacaoConvertida, // Enviando apenas números

        valor_parcela: isNaN(valorConvertido) ? null : valorConvertido,
        data_primeira_parcela: dataFormatadaSQL || null,
      };
  
      console.log("📌 Dados formatados para envio:", dadosFormatados);
  
      // Enviando para a API
      const servicoResponse = await axios.post(
        `${API_BASE_URL}/api/ordens-servico`,
        dadosFormatados,
        { headers }
      );
  
      console.log("✅ Serviço cadastrado com sucesso:", servicoResponse.data);
  
      setSnackbarMessage("Serviço cadastrado com sucesso!");
      setSnackbarSeverity("success");
      setShowSnackbar(true);
      navigate("/servicos");
    } catch (error) {
      console.error(
        "❌ Erro ao cadastrar o serviço:",
        error.response?.data || error.message
      );
      setSnackbarMessage(
        error.response?.data?.message || "Erro ao cadastrar o serviço."
      );
      setSnackbarSeverity("error");
      setShowSnackbar(true);
    }
  };
  
  
  
  

  return (
    <Box sx={{ padding: 4, maxWidth: 1400, margin: 'auto' }}>
    {/* Título da página */}
   <Typography
           variant="h6"
           sx={{
             mb: 2,
             fontWeight: 'bold',
             color: '#6a11cb',
             textAlign: 'left',
           }}
         >
           Nova Atividade
         </Typography>

    <Box sx={{ display: 'flex', height: '100%' }}>
      
      {/* Tabs Laterais */}
      <Grid container>
        <Grid item xs={3}>
          <Tabs
            orientation="vertical"
            value={activeTab}
            onChange={handleTabChange}
            sx={{
              borderRight: 1,
              borderColor: 'divider',
              backgroundColor: '#f5f5f5', // Fundo cinza claro
              borderRadius: '8px', // Cantos arredondados
              boxShadow: '0px 4px 12px rgba(0, 0, 10, 0.1)', // Efeito de sombra
              '& .MuiTab-root': {
                textTransform: 'none', // Manter o texto normal
                justifyContent: 'flex-start', // Alinhar texto à esquerda
                alignItems: 'center', // Centralizar conteúdo verticalmente
                padding: '12px 24px', // Espaçamento interno das abas
                '&:hover': {
                  backgroundColor: '#e0e0e0', // Mudança de cor ao passar o mouse
                },
              },
              '& .Mui-selected': {
                backgroundColor: '#f5f5f5', // Cor de fundo da aba ativa
                color: '#8c52ff', // Cor do texto na aba ativa
                fontWeight: 'bold', // Negrito na aba ativa
              },
            }}
            
          >
            <Tab icon={<Description />} iconPosition="start" label="Dados Gerais" />
            <Tab icon={<Add />} iconPosition="start" label="Parcelamento" />
            <Tab icon={<List />} iconPosition="start" label="Descrição" />
            <Tab icon={<Settings />} iconPosition="start" label="Configurações" />
          </Tabs>
        </Grid>

        {/* Conteúdo das Abas */}
        <Grid item xs={9}>
          {activeTab === 0 && (
            <Box sx={{ padding: 4 }}>
              <Typography variant="h6" gutterBottom>Dados Gerais</Typography>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={6}>
                  <TextField label="Nome" name="nome" fullWidth value={formData.nome} onChange={handleChange} />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField label="Código" name="codigo" fullWidth value={formData.codigo} onChange={handleChange} />
                </Grid>
                <Grid item xs={12} sm={6}>
                <TextField
    label="Classificação"
    name="classificacao"
    fullWidth
    value={formData.classificacao}
    onChange={handleChange}
    inputProps={{ inputMode: "numeric", pattern: "[0-9]*" }} // Apenas números
  />            </Grid>
                <Grid item xs={12} sm={6}>
                  <FormControl fullWidth>
                    <InputLabel>Departamento</InputLabel>
                    <Select name="departamento_id" value={formData.departamento_id} onChange={handleChange}>
                      {departamentos.map(departamento => (
                        <MenuItem key={departamento.id} value={departamento.id}>{departamento.nome}</MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <FormControl fullWidth>
                    <InputLabel>Tipo do Serviço</InputLabel>
                    <Select name="tipo_servico_id" value={formData.tipo_servico_id} onChange={handleChange}>
                      {tiposServico.map(tipo => (
                        <MenuItem key={tipo.id} value={tipo.id}>{tipo.nome}</MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <FormControl fullWidth>
                    <InputLabel>Grupo do Serviço</InputLabel>
                    <Select name="grupo_servico_id" value={formData.grupo_servico_id} onChange={handleChange}>
                      {gruposServico.map(grupo => (
                        <MenuItem key={grupo.id} value={grupo.id}>{grupo.nome}</MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
              </Grid>
            </Box>
          )}

          {activeTab === 1 && (
            <Box sx={{ padding: 4 }}>
              <Typography variant="h6" gutterBottom>Parcelamento</Typography>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={formData.is_parcelado}
                    onChange={(e) => setFormData({ ...formData, is_parcelado: e.target.checked })}
                  />
                }
                label="Este serviço possui parcelamento"
              />
              <Collapse in={formData.is_parcelado}>
                <Grid container spacing={2} sx={{ mt: 2 }}>
                  <Grid item xs={12} sm={4}>
                    <TextField
                      label="Quantidade de Parcelas"
                      type="number"
                      name="quantidade_parcelas"
                      fullWidth
                      value={formData.quantidade_parcelas}
                      onChange={(e) => setFormData({ ...formData, quantidade_parcelas: parseInt(e.target.value) || 1 })}
                    />
                  </Grid>
                  <Grid item xs={12} sm={4}>
                  <NumericFormat
                      label="Valor da parcela"
                      type="text"
                      name="valor_parcela"
                      value={formData.valor_parcela}
                      thousandSeparator="."
                      decimalSeparator=","
                      prefix="R$ "
                      decimalScale={2}
                      fixedDecimalScale
                      allowNegative={false}
                      customInput={TextField}
                      fullWidth
                      onValueChange={(values) => {
                        setFormData((prevState) => ({
                          ...prevState,
                          valor_parcela: values.floatValue || "",
                        }));
                      }}
                    />
                      </Grid>
                        <Grid item xs={12} sm={4}>
                          <TextField
                            label="Data Primeira Parcela"
                            type="date"
                            name="data_primeira_parcela"
                            fullWidth
                            value={formData.data_primeira_parcela.split('/').reverse().join('-')}
                            onChange={handleChange}
                            InputLabelProps={{ shrink: true }}
                          />
                        </Grid>
                </Grid>
              </Collapse>
            </Box>
          )}

          {activeTab === 2 && (
            <Box sx={{ padding: 4 }}>
              <Typography variant="h6" gutterBottom>Descrição</Typography>
              <TextField
                label="Descrição"
                name="descricao"
                fullWidth
                multiline
                rows={4}
                value={formData.descricao}
                onChange={handleChange}
              />
            </Box>
          )}

          {activeTab === 3 && (
            <Box sx={{ padding: 4 }}>
              <Typography variant="h6" gutterBottom>Configurações</Typography>
              <TextField
                label="Esfera"
                name="esfera"
                fullWidth
                value={formData.esfera}
                onChange={handleChange}
              />
              <TextField
                label="Observação"
                name="observacao"
                fullWidth
                multiline
                rows={2}
                value={formData.observacao}
                onChange={handleChange}
                sx={{ mt: 2 }}
              />
            </Box>
          )}

          {/* Botões */}
          <Box sx={{ display: 'flex', justifyContent: 'flex-end', mt: 4 }}>
            <Button variant="outlined" color="secondary" onClick={() => navigate(-1)} sx={{ mr: 2 }}>
              Voltar
            </Button>
            <Button variant="contained" color="primary" onClick={handleSubmit}>
              Salvar
            </Button>
          </Box>
        </Grid>
      </Grid>

      {/* Snackbar */}
      <Snackbar open={showSnackbar} autoHideDuration={6000} onClose={() => setShowSnackbar(false)}>
        <Alert onClose={() => setShowSnackbar(false)} severity={snackbarSeverity}>
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </Box>
    </Box>
  );
};

export default NovoServico;
