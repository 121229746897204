import React, { useState, useEffect } from 'react';
import {
  Box, Tabs, Tab, Table, TableHead, TableRow, TableCell, TableBody, Button, Modal,
  Typography, TextField, IconButton, Tooltip, TableContainer, Paper, Divider, Grid, Snackbar, Alert
} from '@mui/material';
import { Add as AddIcon, Edit as EditIcon, Delete as DeleteIcon, Close as CloseIcon } from '@mui/icons-material';
import axios from 'axios';

const CadastroRegimeTributario = () => {
  const [openModal, setOpenModal] = useState(false);
  const [modalEsfera, setModalEsfera] = useState('');
  const [form, setForm] = useState({ nome: '', descricao: '', esfera: '' });
  const [activeTab, setActiveTab] = useState(0);
  const [registros, setRegistros] = useState({ Federal: [], Estadual: [], Municipal: [] });
  const [snackbar, setSnackbar] = useState({ open: false, message: '', severity: 'success' });

  const esferas = ['Federal', 'Estadual', 'Municipal'];

  // Carregar registros do backend
  const fetchRegistros = async () => {
    try {
      const token = localStorage.getItem('token');
      const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/regimes-tributarios`, {
        headers: { Authorization: `Bearer ${token}` },
      });
      const dados = { Federal: [], Estadual: [], Municipal: [] };

      response.data.forEach((reg) => {
        dados[reg.esfera].push(reg);
      });

      setRegistros(dados);
    } catch (error) {
      console.error('Erro ao carregar regimes tributários:', error);
      setSnackbar({ open: true, message: 'Erro ao carregar dados.', severity: 'error' });
    }
  };

  useEffect(() => {
    fetchRegistros();
  }, []);

  const handleOpenModal = (esfera, registro = null) => {
    setModalEsfera(esfera);
    setForm(registro || { nome: '', descricao: '', esfera });
    setOpenModal(true);
  };

  const handleCloseModal = () => {
    setOpenModal(false);
    setForm({ nome: '', descricao: '', esfera: '' });
  };

  // Função para salvar (adicionar ou atualizar)
  const handleSave = async () => {
    const token = localStorage.getItem('token');

    if (!form.nome || !modalEsfera) {
      setSnackbar({ open: true, message: 'Nome e esfera são obrigatórios.', severity: 'warning' });
      return;
    }

    try {
      if (form.id) {
        await axios.put(`${process.env.REACT_APP_API_URL}/api/regimes-tributarios/${form.id}`, form, {
          headers: { Authorization: `Bearer ${token}` },
        });
        setSnackbar({ open: true, message: 'Regime tributário atualizado com sucesso.', severity: 'success' });
      } else {
        await axios.post(`${process.env.REACT_APP_API_URL}/api/regimes-tributarios`, { ...form, esfera: modalEsfera }, {
          headers: { Authorization: `Bearer ${token}` },
        });
        setSnackbar({ open: true, message: 'Regime tributário adicionado com sucesso.', severity: 'success' });
      }
      fetchRegistros();
      handleCloseModal();
    } catch (error) {
      console.error('Erro ao salvar regime tributário:', error);
      setSnackbar({ open: true, message: 'Erro ao salvar regime tributário.', severity: 'error' });
    }
  };

  const handleDelete = async (id) => {
    const token = localStorage.getItem('token');
    try {
      await axios.delete(`${process.env.REACT_APP_API_URL}/api/regimes-tributarios/${id}`, {
        headers: { Authorization: `Bearer ${token}` },
      });
      setSnackbar({ open: true, message: 'Regime tributário deletado com sucesso.', severity: 'success' });
      fetchRegistros();
    } catch (error) {
      console.error('Erro ao deletar regime tributário:', error);
      setSnackbar({ open: true, message: 'Erro ao deletar regime tributário.', severity: 'error' });
    }
  };

  const handleTabChange = (event, newValue) => {
    setActiveTab(newValue);
  };

  const closeSnackbar = () => {
    setSnackbar({ ...snackbar, open: false });
  };

  return (
    <Box sx={{ padding: 3 }}>

         <Typography
        variant="h6"
        sx={{
          mb: 2,
          fontWeight: 'bold',
          color: '#6a11cb',
          textAlign: 'left',
        }}
      >
     
      Regime Tributário
    </Typography>
      <Grid container spacing={2}>
        <Grid item xs={3}>
          <Tabs
            orientation="vertical"
            value={activeTab}
            onChange={handleTabChange}
            sx={{
              borderRight: 1,
              borderColor: 'divider',
              backgroundColor: '#f5f5f5',
              borderRadius: '8px',
              boxShadow: '0px 4px 12px rgba(0, 0, 10, 0.1)',
              '& .MuiTab-root': {
                textTransform: 'none',
                justifyContent: 'flex-start',
                padding: '12px 24px',
                '&:hover': { backgroundColor: '#e0e0e0' },
              },
              '& .Mui-selected': { backgroundColor: '#f5f5f5', color: '#8c52ff', fontWeight: 'bold' },
            }}
          >
            {esferas.map((esfera, index) => (
              <Tab key={esfera} label={esfera} />
            ))}
          </Tabs>
        </Grid>

        <Grid item xs={9}>
          {esferas.map((esfera, index) => (
            activeTab === index && (
              <Box key={esfera} sx={{ padding: 3 }}>
                <Typography variant="h6" gutterBottom>{esfera} - Regime Tributário</Typography>
                <Grid container justifyContent="space-between" alignItems="center" sx={{ marginBottom: 2 }}>
                  <Button
                    variant="contained"
                    color="primary"
                    startIcon={<AddIcon />}
                    onClick={() => handleOpenModal(esfera)}
                  >
                    Adicionar {esfera}
                  </Button>
                </Grid>
                <TableContainer component={Paper}>
                  <Table>
                    <TableHead>
                      <TableRow>
                        <TableCell>Nome do Regime</TableCell>
                        <TableCell>Descrição</TableCell>
                        <TableCell align="center">Ações</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {registros[esfera].map((reg) => (
                        <TableRow key={reg.id}>
                          <TableCell>{reg.nome}</TableCell>
                          <TableCell>{reg.descricao}</TableCell>
                          <TableCell align="center">
                            <Tooltip title="Editar">
                              <IconButton onClick={() => handleOpenModal(esfera, reg)}>
                                <EditIcon color="primary" />
                              </IconButton>
                            </Tooltip>
                            <Tooltip title="Deletar">
                              <IconButton onClick={() => handleDelete(reg.id)}>
                                <DeleteIcon color="error" />
                              </IconButton>
                            </Tooltip>
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
                <Divider sx={{ marginTop: 3, marginBottom: 3 }} />
              </Box>
            )
          ))}
        </Grid>
      </Grid>

      <Modal open={openModal} onClose={handleCloseModal}>
        <Box
          sx={{
            padding: 4,
            backgroundColor: 'white',
            margin: 'auto',
            width: '40%',
            marginTop: '5%',
            maxHeight: '80vh',
            overflowY: 'auto',
            position: 'relative',
          }}
        >
          <IconButton sx={{ position: 'absolute', top: 8, right: 8 }} onClick={handleCloseModal}>
            <CloseIcon />
          </IconButton>
          <Typography variant="h6">{modalEsfera} - {form.id ? 'Editar' : 'Adicionar'} Regime</Typography>
          <TextField
            label="Nome do Regime"
            fullWidth
            value={form.nome}
            onChange={(e) => setForm({ ...form, nome: e.target.value })}
            sx={{ marginBottom: 2 }}
          />
          <TextField
            label="Descrição"
            fullWidth
            multiline
            value={form.descricao}
            onChange={(e) => setForm({ ...form, descricao: e.target.value })}
            sx={{ marginBottom: 2 }}
          />
          <Button variant="contained" color="primary" onClick={handleSave} fullWidth>
            {form.id ? 'Salvar' : 'Adicionar'}
          </Button>
        </Box>
      </Modal>

      <Snackbar
        open={snackbar.open}
        autoHideDuration={6000}
        onClose={closeSnackbar}
      >
        <Alert onClose={closeSnackbar} severity={snackbar.severity}>
          {snackbar.message}
        </Alert>
      </Snackbar>
    </Box>
  );
};

export default CadastroRegimeTributario;
