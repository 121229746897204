import React, { useEffect, useState } from 'react';
import {
  Box, Table, TableHead, TableRow, TableCell, TableBody, Button,
  Tooltip, IconButton, Typography, Snackbar
} from '@mui/material';
import { Add as AddIcon, Edit as EditIcon, Delete as DeleteIcon } from '@mui/icons-material';
import axios from 'axios';
import GrupoClienteModal from './GrupoClienteModal';

const GrupoCliente = () => {
  const [grupos, setGrupos] = useState([]);
  const [openModal, setOpenModal] = useState(false);
  const [modalType, setModalType] = useState(''); // 'add', 'edit'
  const [selectedGrupo, setSelectedGrupo] = useState(null);
  const [grupoForm, setGrupoForm] = useState({ nome: '' });
  const [errorMessage, setErrorMessage] = useState('');
  const [successMessage, setSuccessMessage] = useState('');
  const [showSnackbar, setShowSnackbar] = useState(false);

  useEffect(() => {
    loadGruposClientes();
  }, []);

  const loadGruposClientes = () => {
    const token = localStorage.getItem('token');
    if (!token) {
      setErrorMessage('Token não encontrado. Faça login novamente.');
      return;
    }
    axios.get(`${process.env.REACT_APP_API_URL}/api/grupo-clientes`, {
      headers: { Authorization: `Bearer ${token}` }
    })
      .then(response => setGrupos(response.data))
      .catch(() => setErrorMessage('Erro ao carregar grupos de clientes.'));
  };

  const handleOpenModal = (type, grupo = null) => {
    setModalType(type);
    setSelectedGrupo(grupo);
    setGrupoForm(grupo ? { nome: grupo.nome } : { nome: '' });
    setErrorMessage('');
    setOpenModal(true);
  };

  const handleCloseModal = () => {
    setOpenModal(false);
    setSelectedGrupo(null);
    setErrorMessage('');
  };

  const handleSubmitForm = () => {
    const token = localStorage.getItem('token');
    if (!grupoForm.nome) {
      setErrorMessage('O nome do grupo é obrigatório.');
      return;
    }

    const formData = { nome: grupoForm.nome };

    if (modalType === 'edit' && selectedGrupo) {
      axios.put(`${process.env.REACT_APP_API_URL}/api/grupo-clientes/${selectedGrupo.id}`, formData, {
        headers: { Authorization: `Bearer ${token}` }
      })
        .then(() => {
          setSuccessMessage('Grupo de cliente atualizado com sucesso.');
          setShowSnackbar(true);
          handleCloseModal();
          loadGruposClientes();
        })
        .catch(() => setErrorMessage('Erro ao atualizar grupo de cliente.'));
    } else {
      axios.post(`${process.env.REACT_APP_API_URL}/api/grupo-clientes`, formData, {
        headers: { Authorization: `Bearer ${token}` }
      })
        .then(() => {
          setSuccessMessage('Grupo de cliente criado com sucesso.');
          setShowSnackbar(true);
          handleCloseModal();
          loadGruposClientes();
        })
        .catch(() => setErrorMessage('Erro ao criar grupo de cliente.'));
    }
  };

  const handleDeleteGrupoCliente = (id) => {
    const token = localStorage.getItem('token');
    axios.delete(`${process.env.REACT_APP_API_URL}/api/grupo-clientes/${id}`, {
      headers: { Authorization: `Bearer ${token}` }
    })
      .then(() => {
        setSuccessMessage('Grupo de cliente deletado com sucesso.');
        setShowSnackbar(true);
        loadGruposClientes();
      })
      .catch(() => setErrorMessage('Erro ao deletar grupo de cliente.'));
  };

  return (
    <Box>
      <Typography variant="h6" sx={{ mb: 2, fontWeight: 'bold', color: '#6a11cb', textAlign: 'left' }}>
        Grupo de Cliente
      </Typography>

      <Button
        variant="contained"
        color="primary"
        startIcon={<AddIcon />}
        onClick={() => handleOpenModal('add')}
      >
        Adicionar Grupo de Cliente
      </Button>

      <Table sx={{ mt: 2 }}>
        <TableHead>
          <TableRow>
            <TableCell>Nome do Grupo</TableCell>
            <TableCell>Ações</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {grupos.map(grupo => (
            <TableRow key={grupo.id}>
              <TableCell>{grupo.nome}</TableCell>
              <TableCell>
                <Tooltip title="Editar">
                  <IconButton color="primary" onClick={() => handleOpenModal('edit', grupo)}>
                    <EditIcon />
                  </IconButton>
                </Tooltip>
                <Tooltip title="Deletar">
                  <IconButton color="error" onClick={() => handleDeleteGrupoCliente(grupo.id)}>
                    <DeleteIcon />
                  </IconButton>
                </Tooltip>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>

      <GrupoClienteModal
        open={openModal}
        onClose={handleCloseModal}
        modalType={modalType}
        grupoForm={grupoForm}
        setGrupoForm={setGrupoForm}
        onSubmit={handleSubmitForm}
        errorMessage={errorMessage}
      />

      <Snackbar
        open={showSnackbar}
        autoHideDuration={6000}
        onClose={() => setShowSnackbar(false)}
        message={successMessage}
      />
    </Box>
  );
};

export default GrupoCliente;
