import React from 'react';
import { Link } from 'react-router-dom';
import {
  Box, Typography, Grid, Paper, List, ListItemButton, ListItemIcon, ListItemText, ThemeProvider, createTheme
} from '@mui/material';
import {
  Business, People, Settings, AccountBalance, Event, Mail, WhatsApp, Sync
} from '@mui/icons-material';

// Definindo um tema personalizado
const theme = createTheme({
  palette: {
    primary: {
      main: '#6a11cb',
    },
    secondary: {
      main: '#19857b',
    },
  },
  typography: {
    h6: {
      fontWeight: 'bold',
    },
    subtitle1: {
      fontWeight: 'bold',
    },
  },
});

const sections = [
  {
    title: "Empresa",
    icon: <Business />,
    items: [
      { name: "Dados Cadastrais", link: "/configuracoes/empresa/dados-cadastrais" },
      { name: "Escritório", link: "/configuracoes/empresa/escritorio" }
    ]
  },
  {
    title: "Usuários",
    icon: <People />,
    items: [
      { name: "Usuários e Permissões", link: "/configuracoes/usuarios/usuarios-permissoes" },
      { name: "Horário de Acessos", link: "/configuracoes/usuarios/horarios-acesso" }
    ]
  },
  {
    title: "Parâmetros das Tarefas",
    icon: <Settings />,
    items: [
      { name: "Grupo do Serviço", link: "/tarefas/grupo" },
      { name: "Tipo do Serviço", link: "/tarefas/tipo" },
      { name: "Esfera do Serviço", link: "/tarefas/esfera" }
    ]
  },
  {
    title: "Parâmetros dos Clientes",
    icon: <Settings />,
    items: [
      { name: "Status", link: "/tarefas/grupo" },
      { name: "Status Complementar", link: "/tarefas/tipo" },
      { name: "Periodicidade do cliente", link: "/tarefas/esfera" },
      { name: "Envio correspondência", link: "/tarefas/esfera" },
      { name: "Regime tributário federal", link: "/tarefas/esfera" },
      { name: "Regime tributário estadual", link: "/tarefas/esfera" },
      { name: "Regime tributário municipal", link: "/tarefas/esfera" },
      { name: "Tipo do cliente", link: "/tarefas/esfera" },
      { name: "Categoria do cliente", link: "/tarefas/esfera" },
      { name: "Segmento", link: "/tarefas/esfera" },
      { name: "Sede do cliente", link: "/tarefas/esfera" },
      { name: "Modalidade fechamento contábil", link: "/tarefas/esfera" },
      { name: "Modalidade fechamento fiscal", link: "/tarefas/esfera" },
      { name: "Modalidade fechamento DP", link: "/tarefas/esfera" },
      { name: "Distribuição de lucros", link: "/tarefas/esfera" },
      { name: "Localização do Cliente", link: "/tarefas/esfera" },
      { name: "Adiantamento da Folha", link: "/tarefas/esfera" },
      { name: "Serviços extraordinários", link: "/tarefas/esfera" },
      { name: "Controles", link: "/tarefas/esfera" },
      { name: "Parcelamentos", link: "/tarefas/esfera" }
      


    ]
  },
  {
    
    title: "Extras",
    icon: <Event />,
    items: [
      { name: "Calendário de Feriados", link: "/configuracoes/extras/calendario" },
      { name: "Grupo de Cliente", link: "/extras/grupo-cliente" }
    ]
  },
  {
    title: "E-mail",
    icon: <Mail />,
    items: [
      { name: "Templates", link: "/configuracoes/email/templates" },
      { name: "E-mail Personalizado", link: "/configuracoes/email/personalizado" }
    ]
  },
  {
    title: "WhatsApp",
    icon: <WhatsApp />,
    items: [
      { name: "Templates", link: "/configuracoes/whatsapp/templates" },
      { name: "Número Personalizado", link: "/whatsapp/numero" }
    ]
  },
  {
    title: "Integrações",
    icon: <Sync />,
    items: [
      { name: "Atalk", link: "/integracoes/google-agenda" },
      { name: "ZapFly", link: "/integracoes/outlook" },
      { name: "ERP", link: "/integracoes/monday" }
    ]
  }
];

const SectionCard = ({ title, icon, items }) => (
  <Paper sx={{ padding: 2, borderRadius: 2, boxShadow: 3, height: '100%' }}>
    <Typography variant="subtitle1" sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
      <ListItemIcon sx={{ color: 'primary.main' }}>{icon}</ListItemIcon>
      {title}
    </Typography>
    <List>
      {items.map((item, idx) => (
        <ListItemButton 
          key={idx} 
          sx={{ borderRadius: 1, '&:hover': { backgroundColor: 'action.hover' } }}
          component={Link} 
          to={item.link}
        >
          <ListItemText primary={item.name} />
        </ListItemButton>
      ))}
    </List>
  </Paper>
);

const Configuracoes = () => {
  return (
    <ThemeProvider theme={theme}>
      <Box sx={{ padding: 2, maxWidth: 'auto', margin: 'auto' }}>
        <Typography variant="h6" sx={{ mb: 3, color: 'primary.main' }}>
          Configurações
        </Typography>
        <Grid container spacing={3}>
          {sections.map((section, index) => (
            <Grid item xs={12} sm={6} md={4} key={index}>
              <SectionCard {...section} />
            </Grid>
          ))}
        </Grid>
      </Box>
    </ThemeProvider>
  );
};

export default Configuracoes;
