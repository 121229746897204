import React, { useEffect, useState } from 'react';
import {
  Box, Grid, Card, CardContent, Typography, Chip, IconButton, Snackbar, Avatar, Button, Modal, Table, TableBody, TableCell, TableHead, TableRow, Paper, Divider
} from '@mui/material';
import {
  CheckCircle as CheckCircleIcon,
  HourglassEmpty as HourglassIcon,
  PlayArrow as PlayIcon,
  Pause as PauseIcon,
  Cancel as CancelIcon,
  ViewList as ViewListIcon,
  ViewModule as ViewModuleIcon,
  Close as CloseIcon,
  Assignment as AssignmentIcon,
 
} from '@mui/icons-material';
import { List, ListItem, ListItemIcon, Checkbox, TextField } from '@mui/material';
import { CalendarToday as CalendarIcon, EventNote as EventNoteIcon, Timer as TimerIcon } from '@mui/icons-material';



const obrigacoesExemplo = [
  {
    id: 1,
    titulo: 'Envio de Declaração de Imposto de Renda',
    descricao: 'Envio de documentos e declarações fiscais.',
    status: 'pendente',
    cliente: { nome: 'Empresa XYZ', iniciales: 'EX' },
    detalhes: 'Documentos necessários para envio até 15/04.'
  },
  {
    id: 2,
    titulo: 'Atualização de Folha de Pagamento',
    descricao: 'Processamento da folha mensal.',
    status: 'em_execucao',
    cliente: { nome: 'Indústria ABC', iniciales: 'IA' },
    detalhes: 'Revisão de folha com vencimento dia 10/01.'
  },
  {
    id: 3,
    titulo: 'Entrega de Relatório Fiscal',
    descricao: 'Consolidação de relatórios do último trimestre.',
    status: 'concluida',
    cliente: { nome: 'Transportadora LMN', iniciales: 'TL' },
    detalhes: 'Relatório entregue e aprovado pelo cliente.'
  }
];



const meses = [
  'Janeiro', 'Fevereiro', 'Março', 'Abril', 'Maio', 'Junho',
  'Julho', 'Agosto', 'Setembro', 'Outubro', 'Novembro', 'Dezembro'
];

const PainelDeObrigacoes = () => {
  const [obrigacoes, setObrigacoes] = useState([]);
  const [filtroStatus, setFiltroStatus] = useState('todas');
  const [obrigacaoSelecionada, setObrigacaoSelecionada] = useState(null);
  const [modalOpen, setModalOpen] = useState(false);
  const [successMessage, setSuccessMessage] = useState('');
  const [showSnackbar, setShowSnackbar] = useState(false);
  const [modoVisualizacao, setModoVisualizacao] = useState('cards');
  const [checked, setChecked] = useState([]);  // Declarado fora do componente

// Estado do Cronômetro - 🚨 Coloque dentro do componente
const [time, setTime] = useState(0);
const [running, setRunning] = useState(false);

// Hook para controlar o cronômetro
useEffect(() => {
  let interval;
  if (running) {
    interval = setInterval(() => {
      setTime((prevTime) => prevTime + 1);
    }, 1000);
  } else {
    clearInterval(interval);
  }
  return () => clearInterval(interval);
}, [running]);

// Função para formatar o tempo
const formatTime = (seconds) => {
  const mins = Math.floor(seconds / 60);
  const secs = seconds % 60;
  return `${mins}:${secs < 10 ? '0' : ''}${secs}`;
};

  useEffect(() => {
    setObrigacoes(obrigacoesExemplo);
  }, []);

   

  const obrigacoesFiltradas = obrigacoes.filter(obr => filtroStatus === 'todas' || obr.status === filtroStatus);

  const handleStatusChange = (id, status) => {
    const novasObrigacoes = obrigacoes.map(obr =>
      obr.id === id ? { ...obr, status } : obr
    );
    setObrigacoes(novasObrigacoes);
    setSuccessMessage('Status atualizado com sucesso!');
    setShowSnackbar(true);
  };

  const handleOpenModal = (obr) => {
    setObrigacaoSelecionada(obr);
    setModalOpen(true);
  };

  const handleCloseModal = () => {
    setModalOpen(false);
    setObrigacaoSelecionada(null);
  };




  const statusChips = {
    'pendente': <Chip icon={<HourglassIcon />} label="Pendente" color="warning" />,
    'em_execucao': <Chip icon={<PlayIcon />} label="Em Execução" color="info" />,
    'concluida': <Chip icon={<CheckCircleIcon />} label="Concluída" color="success" />,
    'cancelada': <Chip icon={<CancelIcon />} label="Cancelada" color="error" />,
  };
  const [observacao, setObservacao] = useState('');

  
const handleToggle = (value) => {
  const currentIndex = checked.indexOf(value);
  const newChecked = [...checked];
  if (currentIndex === -1) {
    newChecked.push(value);
  } else {
    newChecked.splice(currentIndex, 1);
  }
  setChecked(newChecked);
};


  const modalContent = obrigacaoSelecionada && (
    <Box
  p={4}
  sx={{
    width: '100%',
    maxWidth: '800px',
    maxHeight: '90vh',
    overflowY: 'auto',
    bgcolor: 'background.paper',
    borderRadius: '16px',
    boxShadow: 24,
    position: 'relative',
    '&::-webkit-scrollbar': {
      width: '0px',
    },
    '&::-webkit-scrollbar-thumb': {
      backgroundColor: '#1976d2',  // Cor do botão principal (azul)
      borderRadius: '10px',
    },
    '&::-webkit-scrollbar-track': {
      backgroundColor: '#f0f0f0',
    }
  }}
>
  <Box
    display="flex"
    justifyContent="space-between"
    alignItems="center"
    mb={3}
    sx={{
      borderBottom: '1px solid #fff',
      pb: 2,
    }}
  >
    <Typography variant="h5" fontWeight="bold" color="primary.dark">
      Detalhes da Obrigação
    </Typography>

    <IconButton
      onClick={handleCloseModal}
      sx={{
        color: 'error.main',
        '&:hover': {
          bgcolor: 'rgba(255,0,0,0.1)',
        }
      }}
    >
      <CloseIcon />
    </IconButton>
  </Box>

      <Box mt={3} p={2} sx={{ borderRadius: 2, boxShadow: 1, bgcolor: 'background.paper' }}>
  <Typography variant="h6" fontWeight="bold" gutterBottom>Informações Gerais</Typography>
  
  <Grid container spacing={3} sx={{ mt: 1 }}>
    <Grid item xs={6}>
      <TextField
        label="Código"
        value={obrigacaoSelecionada.id}
        fullWidth
        disabled
      />
    </Grid>
    <Grid item xs={6}>
      <TextField
        label="Classificação"
        value="000/000/000"
        fullWidth
        disabled
      />
    </Grid>
    <Grid item xs={6}>
      <TextField
        label="Mininome"
        value={obrigacaoSelecionada.cliente.iniciales}
        fullWidth
        disabled
      />
    </Grid>
    <Grid item xs={6}>
      <TextField
        label="Nome da Obrigação"
        value={obrigacaoSelecionada.titulo}
        fullWidth
        disabled
      />
    </Grid>
    <Grid item xs={6}>
      <TextField
        label="Departamento"
        value="Contábil"
        fullWidth
        disabled
      />
    </Grid>
    <Grid item xs={6}>
      <TextField
        label="Tipo de Serviço"
        value="Declaração Fiscal"
        fullWidth
        disabled
      />
    </Grid>
    <Grid item xs={6}>
      <TextField
        label="Grupo do Serviço"
        value="Fiscalizações"
        fullWidth
        disabled
      />
    </Grid>
  </Grid>
</Box>
      <Divider sx={{ my: 2 }} />

      <Box mt={3} p={2} sx={{ borderRadius: 2, boxShadow: 1, bgcolor: 'background.paper' }}>
  <Typography variant="h6" fontWeight="bold" gutterBottom>Cliente</Typography>
  
  <Grid container spacing={3} sx={{ mt: 1 }}>
    <Grid item xs={6}>
      <TextField
        label="Código ERP"
        value={obrigacaoSelecionada.id}
        fullWidth
        disabled
      />
    </Grid>
    <Grid item xs={6}>
      <TextField
        label="Nome"
        value="Teste"
        fullWidth
        disabled
      />
    </Grid>
    <Grid item xs={6}>
      <TextField
        label="Nome Fantasia"
        value={obrigacaoSelecionada.cliente.iniciales}
        fullWidth
        disabled
      />
    </Grid>
    <Grid item xs={6}>
      <TextField
        label="CNPJ/CPF"
        value={obrigacaoSelecionada.titulo}
        fullWidth
        disabled
      />
    </Grid>
  </Grid>
</Box>

      <Box>
      
    </Box>
     
      <Divider sx={{ my: 2 }} />

      <Box sx={{ mt: 3, p: 2, borderRadius: 2, boxShadow: 1, bgcolor: 'background.paper' }}>
  <Typography variant="h6" fontWeight="bold" gutterBottom>Detalhes</Typography>
  
  <Grid container spacing={3} sx={{ mt: 1 }}>
    <Grid item xs={3}>
      <TextField
        label="Dias para anteceder"
        value="10"
        fullWidth
        disabled
        InputProps={{
          startAdornment: <TimerIcon sx={{ mr: 1, color: 'primary.main' }} />,
        }}
      />
    </Grid>
    <Grid item xs={3}>
      <TextField
        label="Dias para iniciar"
        value="5"
        fullWidth
        disabled
        InputProps={{
          startAdornment: <EventNoteIcon sx={{ mr: 1, color: 'primary.main' }} />,
        }}
      />
    </Grid>
    <Grid item xs={3}>
      <TextField
        label="Tipo dos dias Antes"
        value="2"
        fullWidth
        disabled
        InputProps={{
          startAdornment: <EventNoteIcon sx={{ mr: 1, color: 'primary.main' }} />,
        }}
      />
    </Grid>
    <Grid item xs={3}>
      <TextField
        label="Prazos fixos em dias não úteis"
        value="Sim"
        fullWidth
        disabled
        InputProps={{
          startAdornment: <EventNoteIcon sx={{ mr: 1, color: 'primary.main' }} />,
        }}
      />
    </Grid>
    <Grid item xs={3}>
      <TextField
        label="Sábado é dia útil"
        value="Não"
        fullWidth
        disabled
        InputProps={{
          startAdornment: <EventNoteIcon sx={{ mr: 1, color: 'primary.main' }} />,
        }}
      />
    </Grid>
    <Grid item xs={3}>
      <TextField
        label="Competência referente a"
        value="Abril"
        fullWidth
        disabled
        InputProps={{
          startAdornment: <EventNoteIcon sx={{ mr: 1, color: 'primary.main' }} />,
        }}
      />
    </Grid>
  </Grid>


</Box>


    <Divider sx={{ my: 3 }} />
            {/* Checklist Interativo */}
            <Box>
  <Typography variant="h6" fontWeight="bold" gutterBottom>Checklist</Typography>
  <List sx={{ width: '100%', bgcolor: 'background.paper', borderRadius: 2, boxShadow: 1 }}>
    {['Acessar portal gov.br', 'Realizar login', 'Preencher dados fiscais', 'Enviar declaração'].map((item, index) => (
      <ListItem
        key={index}
        button
        onClick={() => handleToggle(index)}
        sx={{
          transition: 'background-color 0.3s',
          '&:hover': { bgcolor: '#f0f0f0' },
          borderBottom: index !== 3 ? '1px solid #ddd' : 'none',
        }}
      >
        <ListItemIcon>
          <Checkbox
            edge="start"
            checked={checked.indexOf(index) !== -1}
            tabIndex={-1}
            disableRipple
            sx={{
              color: checked.indexOf(index) !== -1 ? 'success.main' : 'primary.main',
            }}
          />
        </ListItemIcon>
        <Typography
          sx={{
            textDecoration: checked.indexOf(index) !== -1 ? 'line-through' : 'none',
            color: checked.indexOf(index) !== -1 ? '#888' : 'inherit',
            fontWeight: checked.indexOf(index) !== -1 ? 500 : 600,
            transition: 'color 0.3s, text-decoration 0.3s',
          }}
        >
          {item}
        </Typography>
      </ListItem>
    ))}
  </List>
</Box>


<Divider sx={{ my: 2 }} />

<Box mt={3} p={3} sx={{ borderRadius: 2, boxShadow: 1, bgcolor: 'background.paper' }}>
  <Typography variant="h6" fontWeight="bold" gutterBottom>Envio de Documentos</Typography>

  <Button
    variant="contained"
    component="label"
    sx={{ mt: 2 }}
  >
    Selecione Arquivos
    <input
      type="file"
      multiple
      hidden
      onChange={(e) => console.log(e.target.files)}
    />
  </Button>

  <Typography variant="body2" sx={{ mt: 1, color: 'gray' }}>
    É possível enviar múltiplos arquivos ao mesmo tempo. Formatos suportados: PDF, DOCX, XLSX, PNG, JPG.
  </Typography>
</Box>

<Divider sx={{ my: 2 }} />
<Box mt={3} p={3} sx={{ borderRadius: 2, boxShadow: 1, bgcolor: 'background.paper' }}>
  <Typography variant="h6" fontWeight="bold" gutterBottom>Observações</Typography>

  <TextField
    label="Adicione observações"
    multiline
    rows={4}
    fullWidth
    value={observacao}
    onChange={(e) => setObservacao(e.target.value)}
    inputProps={{ maxLength: 500 }}
    sx={{ mt: 2 }}
  />

  <Typography variant="body2" align="right" sx={{ mt: 1 }}>
    {observacao.length}/500 caracteres
  </Typography>
</Box>


<Divider sx={{ my: 2 }} />
<Box mt={3} p={3} sx={{ borderRadius: 2, boxShadow: 1, bgcolor: 'background.paper', textAlign: 'center' }}>
  <Typography variant="h6" fontWeight="bold" gutterBottom>Cronômetro</Typography>

  <Box
    sx={{
      width: 120,
      height: 120,
      borderRadius: '50%',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      bgcolor: running ? 'primary.light' : 'grey.200',
      boxShadow: running ? 3 : 1,
      mx: 'auto',
      mb: 2,
    }}
  >
    <Typography variant="h4" fontWeight="bold">
      {formatTime(time)}
    </Typography>
  </Box>

  <Box display="flex" justifyContent="center" gap={2}>
    <Button
      variant="contained"
      color={running ? 'error' : 'primary'}
      onClick={() => setRunning(!running)}
    >
      {running ? 'Pausar' : 'Iniciar'}
    </Button>

    <Button
      variant="outlined"
      color="secondary"
      onClick={() => {
        setRunning(false);
        setTime(0);
      }}
    >
      Resetar
    </Button>
  </Box>
</Box>

<Box
  display="flex"
  justifyContent="space-between"
  alignItems="center"
  sx={{
    mt: 4,
    p: 3,
    borderTop: '4px solid #1565c0',  // Borda superior azul para destaque
    bgcolor: 'background.paper',
    borderRadius: '0 0 16px 16px',
    boxShadow: 3  // Sombra sutil
  }}
>
  <Box display="flex" alignItems="center" gap={2}>
    <Button
      variant="contained"
      color="primary"
      size="large"
      onClick={() => {
        console.log('Salvar dados');
      }}
      sx={{
        fontWeight: 'bold',
        minWidth: '120px'
      }}
    >
      Salvar
    </Button>

    <Button
      variant="outlined"
      color="secondary"
      size="large"
      onClick={() => {
        console.log('Limpar campos');
      }}
      sx={{
        minWidth: '120px'
      }}
    >
      Limpar
    </Button>
  </Box>

  <Button
    variant="text"
    color="error"
    size="large"
    onClick={handleCloseModal}
    sx={{
      fontWeight: 'bold',
      minWidth: '100px'
    }}
  >
    Fechar
  </Button>
</Box>



    </Box>
  );

  return (
    <Box p={1} sx={{ backgroundColor: '#fff', minHeight: '100vh' }}>
      <Box display="flex" justifyContent="space-between" alignItems="center" mb={4}>
        <Typography variant="h4" fontWeight="bold">Painel de Monitoramento</Typography>
        <IconButton onClick={() => setModoVisualizacao(modoVisualizacao === 'cards' ? 'tabela' : 'cards')}>
          {modoVisualizacao === 'cards' ? <ViewListIcon /> : <ViewModuleIcon />}
        </IconButton>
      </Box>

      <Box display="flex" mb={3}>
        {['todas', 'pendente', 'em_execucao', 'concluida'].map(status => (
          <Button
            key={status}
            variant={filtroStatus === status ? 'contained' : 'outlined'}
            onClick={() => setFiltroStatus(status)}
            sx={{ mr: 2 }}
          >
            {status.charAt(0).toUpperCase() + status.slice(1)}
          </Button>
        ))}
      </Box>

      {modoVisualizacao === 'cards' ? (
        <Grid container spacing={3}>
          {obrigacoesFiltradas.map((obr) => (
            <Grid item xs={12} sm={6} md={4} key={obr.id}>
              <Card
                onClick={() => handleOpenModal(obr)}
                sx={{
                  borderRadius: '16px',
                  boxShadow: '0 6px 15px rgba(0, 0, 0, 0.1)',
                  transition: '0.3s',
                  '&:hover': { transform: 'scale(1.02)' },
                }}
              >
                <CardContent>
                  <Box display="flex" alignItems="center" mb={2}>
                    <AssignmentIcon fontSize="large" sx={{ mr: 2 }} />
                    <Typography variant="h6" fontWeight="bold">{obr.titulo}</Typography>
                  </Box>
                  <Typography color="textSecondary">{obr.descricao}</Typography>
                  <Box mt={2} display="flex" justifyContent="space-between" alignItems="center">
                    {statusChips[obr.status]}
                    <Avatar>{obr.cliente.iniciales}</Avatar>
                  </Box>
                </CardContent>
              </Card>
            </Grid>
          ))}
        </Grid>
      ) : (
        <Table component={Paper} sx={{ mt: 2 }}>
          <TableHead>
            <TableRow>
              <TableCell>Título</TableCell>
              <TableCell>Cliente</TableCell>
              <TableCell>Status</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {obrigacoesFiltradas.map(obr => (
              <TableRow key={obr.id} onClick={() => handleOpenModal(obr)}>
                <TableCell>{obr.titulo}</TableCell>
                <TableCell>{obr.cliente.nome}</TableCell>
                <TableCell>{statusChips[obr.status]}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      )}

      <Modal open={modalOpen} onClose={handleCloseModal}>
        <Box display="flex" justifyContent="center" alignItems="center" height="100vh">
          {modalContent}
        </Box>
      </Modal>

      <Snackbar open={showSnackbar} autoHideDuration={6000} onClose={() => setShowSnackbar(false)} message={successMessage} />
    </Box>
  );
};

export default PainelDeObrigacoes;
