import React, { useState, useMemo } from 'react';
import {
  Box,
  Button,
  Modal,
  Typography,
  TextField,
  FormGroup,
  FormControlLabel,
  Checkbox,
} from '@mui/material';
import { MaterialReactTable } from 'material-react-table';
import { NearMeOutlined, Print } from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';

const generateObligationsData = (numClientes) => {
  const obligations = ['Imposto de Renda', 'ISS', 'ICMS', 'Folha de Pagamento', 'Contribuições'];
  return Array.from({ length: numClientes }).map((_, i) => ({
    id: i + 1,
    cliente: `Cliente ${i + 1}`,
    obrigacao: obligations[i % obligations.length],
    vencimento: `2024-11-${(i % 30) + 1}`,
    status: i % 2 === 0 ? 'Pendente' : 'Concluído',
  }));
};

const Obrigacoes = () => {
  const [data] = useState([]); // Inicializar com array vazio
  const [modalGerarOpen, setModalGerarOpen] = useState(false);
  const [rowSelection, setRowSelection] = useState({});
  const navigate = useNavigate();

  const [selectedFilters, setSelectedFilters] = useState({
    obrigacoes: false,
    controle: false,
    ordemServico: false,
    obrigacoesEOrdem: false,
    parcelamento: false,
  });

  const [newTask, setNewTask] = useState({
    tarefa: '',
    periodoInicial: '',
    periodoFinal: '',
    renovarNaoIniciadas: false,
    alterarVencimento: false,
    novaDataVencimento: '',
    transferir: false,
    transferirPara: '',
  });

  const handleFilterChange = (event) => {
    setSelectedFilters({
      ...selectedFilters,
      [event.target.name]: event.target.checked,
    });
  };

  const handleGerarOpen = () => {
    const selectedRows = Object.keys(rowSelection).map((key) => data[key]);
    const tarefaNome = selectedRows.map((row) => row.obrigacao).join(', ');
    setNewTask({ ...newTask, tarefa: tarefaNome });
    setModalGerarOpen(true);
  };

  const handleGerarClose = () => {
    setModalGerarOpen(false);
    setNewTask({
      tarefa: '',
      periodoInicial: '',
      periodoFinal: '',
      renovarNaoIniciadas: false,
      alterarVencimento: false,
      novaDataVencimento: '',
      transferir: false,
      transferirPara: '',
    });
  };

  const columns = useMemo(() => [
    { accessorKey: 'id', header: 'ID' },
    { accessorKey: 'cliente', header: 'Cliente' },
    { accessorKey: 'obrigacao', header: 'Obrigação' },
    { accessorKey: 'vencimento', header: 'Vencimento' },
    { accessorKey: 'status', header: 'Status' },
  ], []);

  return (
    <Box sx={{ minHeight: '100vh', width: '100%' }}>
      {/* Cabeçalho */}
      <Typography
        variant="h6"
        sx={{
          mb: 2,
          fontWeight: 'bold',
          color: '#6a11cb',
          textAlign: 'left',
        }}
      >
        Gestão de Tarefas
      </Typography>

      {/* Caixa de Filtros */}
      <Box
        sx={{
          mb: 4,
          p: 3,
          backgroundColor: '#ffffff',
          borderRadius: 2,
          boxShadow: '0 4px 16px rgba(0, 0, 0, 0.2)',
        }}
      >
        <Typography variant="h6" sx={{ mb: 2, fontWeight: 'bold', color: '#333' }}>
          Filtros
        </Typography>
        <Typography variant="body2" sx={{ mb: 2, color: 'gray' }}>
          Selecione os filtros abaixo para ajustar as obrigações exibidas na tabela:
        </Typography>
        <FormGroup row>
          {Object.keys(selectedFilters).map((key) => (
            <FormControlLabel
              key={key}
              control={
                <Checkbox
                  name={key}
                  checked={selectedFilters[key]}
                  onChange={handleFilterChange}
                  color="primary"
                />
              }
              label={key.charAt(0).toUpperCase() + key.slice(1).replace(/([A-Z])/g, ' $1')}
            />
          ))}
        </FormGroup>
      </Box>

      {/* Botões */}
      <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 3 }}>
        <Box sx={{ display: 'flex', gap: 2 }}>
          <Button
            variant="outlined"
            color="primary"
            onClick={() => navigate('/nova-obrigacoes')}
            startIcon={<NearMeOutlined />}
            sx={{
              textTransform: 'none',
              backgroundColor: '#fff',
              color: '#333',
              ':hover': { backgroundColor: '#f1f1f1' },
            }}
          >
            Adicionar
          </Button>
          <Button
            variant="contained"
            color="secondary"
            onClick={handleGerarOpen}
            startIcon={<NearMeOutlined />}
            sx={{
              textTransform: 'none',
              boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)',
              ':hover': { backgroundColor: '#5e35b1' },
            }}
          >
            Gerar
          </Button>
        </Box>
        <Button
          variant="contained"
          color="secondary"
          onClick={() => console.log('Export PDF')}
          startIcon={<Print />}
          sx={{
            textTransform: 'none',
            boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)',
            ':hover': { backgroundColor: '#5e35b1' },
          }}
        >
          Imprimir
        </Button>
      </Box>

      {/* Tabela */}
      <Box
        sx={{
          borderRadius: '8px',
          boxShadow: '0 4px 16px rgba(0, 0, 0, 0.2)',
        }}
      >
        <MaterialReactTable
          columns={columns}
          data={data}
          enableGlobalFilter
          enableColumnFilters
          enableSorting
          enablePagination
          initialState={{
            pagination: { pageSize: 10 }, // Exibe 10 resultados por página
          }}
          muiTableBodyRowProps={{
            sx: {
              '&:nth-of-type(odd)': { backgroundColor: '#f9f9f9' },
              '&:hover': { backgroundColor: '#f1f1f1' },
            },
          }}
          muiTableHeadCellProps={{
            sx: { backgroundColor: '#6a11cb', color: '#fff', fontWeight: 'bold' },
          }}
        />
      </Box>

      {/* Modal */}
      <Modal open={modalGerarOpen} onClose={handleGerarClose}>
        <Box
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: '90%',
            maxWidth: 600,
            bgcolor: '#fff',
            boxShadow: '0 4px 16px rgba(0, 0, 0, 0.3)',
            p: 4,
            borderRadius: 2,
          }}
        >
          <Typography variant="h6" mb={2}>
            Gerar Recorrente
          </Typography>
          <Box sx={{ display: 'flex', gap: 2, mb: 2 }}>
            <TextField
              type="date"
              label="Período Inicial"
              value={newTask.periodoInicial}
              onChange={(e) => setNewTask({ ...newTask, periodoInicial: e.target.value })}
              InputLabelProps={{ shrink: true }}
              fullWidth
            />
            <TextField
              type="date"
              label="Período Final"
              value={newTask.periodoFinal}
              onChange={(e) => setNewTask({ ...newTask, periodoFinal: e.target.value })}
              InputLabelProps={{ shrink: true }}
              fullWidth
            />
          </Box>
          <Box sx={{ display: 'flex', justifyContent: 'flex-end', gap: 2, mt: 4 }}>
            <Button variant="outlined" color="secondary" onClick={handleGerarClose}>
              Fechar
            </Button>
            <Button variant="contained" color="primary">
              Gerar
            </Button>
          </Box>
        </Box>
      </Modal>
    </Box>
  );
};

export default Obrigacoes;
