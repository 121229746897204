import React, { useState } from 'react';
import {
  Box,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Button,
  Tooltip,
  IconButton,
  Modal,
  TextField,
  Typography,
  Alert,
  Snackbar,
} from '@mui/material';
import {
  Add as AddIcon,
  Delete as DeleteIcon,
  Close as CloseIcon,
  PictureAsPdf as PdfIcon,
  Visibility as VisibilityIcon,
  Edit as EditIcon,
} from '@mui/icons-material';

const TemplaneDocumento = () => {
  const [documentos, setDocumentos] = useState([
    { id: 1, nome: 'Documento Exemplo 1', arquivo: 'https://via.placeholder.com/150', preview: null },
    { id: 2, nome: 'Documento Exemplo 2', arquivo: 'https://via.placeholder.com/150', preview: null },
  ]); // Dados simulados
  const [openModal, setOpenModal] = useState(false);
  const [documentoForm, setDocumentoForm] = useState({ id: null, nome: '', arquivo: null });
  const [selectedPreview, setSelectedPreview] = useState(null); // Visualização do arquivo
  const [errorMessage, setErrorMessage] = useState('');
  const [successMessage, setSuccessMessage] = useState('');
  const [showSnackbar, setShowSnackbar] = useState(false);

  const handleOpenModal = () => {
    setDocumentoForm({ id: null, nome: '', arquivo: null });
    setSelectedPreview(null);
    setOpenModal(true);
  };

  const handleCloseModal = () => {
    setOpenModal(false);
    setErrorMessage('');
  };

  const handleFileUpload = (e) => {
    const file = e.target.files[0];
    if (file) {
      setDocumentoForm({ ...documentoForm, arquivo: file });
      setSelectedPreview(URL.createObjectURL(file));
    }
  };

  const handleSubmitForm = () => {
    if (!documentoForm.nome || !documentoForm.arquivo) {
      setErrorMessage('Todos os campos são obrigatórios.');
      return;
    }

    if (documentoForm.id) {
      // Editar documento existente
      setDocumentos((prevDocs) =>
        prevDocs.map((doc) =>
          doc.id === documentoForm.id
            ? { ...doc, nome: documentoForm.nome }
            : doc
        )
      );
      setSuccessMessage('Documento atualizado com sucesso.');
    } else {
      // Adicionar novo documento
      setDocumentos((prevDocs) => [
        ...prevDocs,
        {
          id: Date.now(),
          nome: documentoForm.nome,
          arquivo: documentoForm.arquivo.name,
          preview: selectedPreview,
        },
      ]);
      setSuccessMessage('Documento adicionado com sucesso.');
    }

    setShowSnackbar(true);
    handleCloseModal();
  };

  const handleDeleteDocumento = (id) => {
    setDocumentos((prevDocs) => prevDocs.filter((doc) => doc.id !== id));
    setSuccessMessage('Documento removido com sucesso.');
    setShowSnackbar(true);
  };

  const handleEditDocumento = (doc) => {
    setDocumentoForm({ id: doc.id, nome: doc.nome, arquivo: null });
    setOpenModal(true);
  };

  const handlePreviewDocumento = (preview) => {
    setSelectedPreview(preview);
  };

  return (
    <Box>
       <Typography
              variant="h6"
              sx={{
                mb: 2,
                fontWeight: 'bold',
                color: '#6a11cb',
                textAlign: 'left',
              }}
            >
           
            Template de documentos
          </Typography>
      <Button
        variant="contained"
        color="primary"
        startIcon={<AddIcon />}
        onClick={handleOpenModal}
      >
        Adicionar Documento
      </Button>

      <Table sx={{ mt: 3 }}>
        <TableHead>
          <TableRow>
            <TableCell>Nome do Documento</TableCell>
            <TableCell>Ações</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {documentos.map((doc) => (
            <TableRow key={doc.id}>
              <TableCell>{doc.nome}</TableCell>
              <TableCell>
                {/* Botão de visualizar */}
                <Tooltip title="Visualizar">
                  <IconButton
                    color="primary"
                    onClick={() => handlePreviewDocumento(doc.preview || doc.arquivo)}
                  >
                    <VisibilityIcon />
                  </IconButton>
                </Tooltip>
                {/* Botão de editar */}
                <Tooltip title="Editar">
                  <IconButton
                    color="secondary"
                    onClick={() => handleEditDocumento(doc)}
                  >
                    <EditIcon />
                  </IconButton>
                </Tooltip>
                {/* Botão de deletar */}
                <Tooltip title="Deletar">
                  <IconButton
                    color="error"
                    onClick={() => handleDeleteDocumento(doc.id)}
                  >
                    <DeleteIcon />
                  </IconButton>
                </Tooltip>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>

      {/* Modal para Adicionar ou Editar Documento */}
      <Modal open={openModal} onClose={handleCloseModal}>
        <Box
          sx={{
            padding: 4,
            backgroundColor: 'white',
            margin: 'auto',
            width: '50%',
            marginTop: '5%',
            maxHeight: '80vh',
            overflowY: 'auto',
            position: 'relative',
            '&::-webkit-scrollbar': {
              width: '10px',
            },
            '&::-webkit-scrollbar-thumb': {
              backgroundColor: '#673ab7',
              borderRadius: '8px',
            },
            '&::-webkit-scrollbar-track': {
              backgroundColor: '#f1f1f1',
            },
          }}
        >
          <IconButton
            sx={{ position: 'absolute', top: 8, right: 8 }}
            onClick={handleCloseModal}
          >
            <CloseIcon />
          </IconButton>

          <Typography variant="h6" sx={{ mb: 2 }}>
            {documentoForm.id ? 'Editar Documento' : 'Adicionar Documento'}
          </Typography>

          {errorMessage && (
            <Alert severity="error" sx={{ mb: 2 }}>
              {errorMessage}
            </Alert>
          )}

          <TextField
            label="Nome do Documento"
            fullWidth
            value={documentoForm.nome}
            onChange={(e) =>
              setDocumentoForm({ ...documentoForm, nome: e.target.value })
            }
            sx={{ marginBottom: 3 }}
          />

          {!documentoForm.id && (
            <Button
              variant="contained"
              component="label"
              startIcon={<PdfIcon />}
              sx={{
                backgroundColor: '#0288d1',
                color: 'white',
                '&:hover': { backgroundColor: '#01579b' },
                padding: '10px 16px',
                marginBottom: 4,
              }}
            >
              Upload de Arquivo
              <input type="file" hidden onChange={handleFileUpload} />
            </Button>
          )}

          {selectedPreview && (
            <Box sx={{ mt: 2 }}>
              <Typography variant="body1">Pré-visualização do Documento:</Typography>
              <iframe
                src={selectedPreview}
                title="Preview"
                width="100%"
                height="200px"
                style={{ border: '1px solid #ddd' }}
              />
            </Box>
          )}

          <Button
            variant="contained"
            color="primary"
            onClick={handleSubmitForm}
            sx={{
              mt: 3,
              display: 'block',
              margin: 'auto',
            }}
          >
            Salvar
          </Button>
        </Box>
      </Modal>

      {/* Modal de Visualização */}
      {selectedPreview && (
        <Modal
          open={!!selectedPreview}
          onClose={() => setSelectedPreview(null)}
        >
          <Box
            sx={{
              width: '80%',
              height: '80%',
              margin: 'auto',
              marginTop: '5%',
              backgroundColor: 'white',
              padding: 2,
              position: 'relative',
            }}
          >
            <iframe
              src={selectedPreview}
              title="Visualização do Documento"
              width="100%"
              height="100%"
              style={{ border: '1px solid #ddd' }}
            />
            <IconButton
              sx={{ position: 'absolute', top: 8, right: 8 }}
              onClick={() => setSelectedPreview(null)}
            >
              <CloseIcon />
            </IconButton>
          </Box>
        </Modal>
      )}

      <Snackbar
        open={showSnackbar}
        autoHideDuration={6000}
        onClose={() => setShowSnackbar(false)}
        message={successMessage}
      />
    </Box>
  );
};

export default TemplaneDocumento;
