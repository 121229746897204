import React, { useState, useEffect } from 'react';
import {
  Box, Table, TableHead, TableRow, TableCell, TableBody, Tooltip, IconButton, Modal, TextField, Typography, Snackbar, Alert, Button
} from '@mui/material';
import { Add as AddIcon, Edit as EditIcon, Delete as DeleteIcon, Close as CloseIcon } from '@mui/icons-material';
import axios from 'axios';
import { useNavigate } from 'react-router-dom'; // 🔹 Importa a navegação


const UsuariosPermissoes = () => {
  const [usuarios, setUsuarios] = useState([]);
  const [openModal, setOpenModal] = useState(false);
  const [modalType, setModalType] = useState(''); // 'add' or 'edit'
  const [editingUsuario, setEditingUsuario] = useState(null);
  const [usuarioForm, setUsuarioForm] = useState({ nome: '', email: '', senha_hash: '', tipo_usuario: 'usuario' });
  const [errorMessage, setErrorMessage] = useState('');
  const [successMessage, setSuccessMessage] = useState('');
  const [showSnackbar, setShowSnackbar] = useState(false);
    const navigate = useNavigate(); // 🔹 Hook para navegação
  


  // Carregar usuários com token JWT
  useEffect(() => {
    loadUsuarios();
  }, []);

  const loadUsuarios = () => {
    const token = localStorage.getItem('token');
    axios.get(`${process.env.REACT_APP_API_URL}/api/usuarios`, {
      headers: {
        Authorization: `Bearer ${token}`
      }
    })
      .then(response => setUsuarios(response.data))
      .catch(() => setErrorMessage('Erro ao carregar usuários.'));
  };

  // Abrir modal para adicionar ou editar usuário
  const handleOpenModal = (type, usuario = null) => {
    setModalType(type);
    if (usuario) {
      setEditingUsuario(usuario);
      setUsuarioForm({ nome: usuario.nome, email: usuario.email, tipo_usuario: usuario.tipo_usuario });
    } else {
      setEditingUsuario(null);
      setUsuarioForm({ nome: '', email: '', senha_hash: '', tipo_usuario: 'usuario' });
    }
    setOpenModal(true);
  };

  // Fechar modal
  const handleCloseModal = () => {
    setOpenModal(false);
    setErrorMessage('');
  };

  // Adicionar ou editar usuário
  const handleSubmitForm = () => {
    const token = localStorage.getItem('token');
    if (!usuarioForm.nome || !usuarioForm.email) {
      setErrorMessage('Nome e Email são obrigatórios.');
      return;
    }
  
    const formData = {
      nome: usuarioForm.nome,
      email: usuarioForm.email,
      senha_hash: usuarioForm.senha_hash || undefined, // Apenas envie se necessário
      tipo_usuario: usuarioForm.tipo_usuario,
    };
  
    if (modalType === 'edit' && editingUsuario) {
      axios.put(`${process.env.REACT_APP_API_URL}/api/usuarios/${editingUsuario.id}`, formData, {
        headers: { Authorization: `Bearer ${token}` },
      })
        .then(() => {
          setSuccessMessage('Usuário atualizado com sucesso.');
          setShowSnackbar(true);
          handleCloseModal();
          loadUsuarios(); // Atualiza a lista de usuários após edição
        })
        .catch((error) => {
          console.error('Erro ao atualizar usuário:', error.response?.data || error.message);
          setErrorMessage(error.response?.data?.message || 'Erro ao atualizar usuário.');
        });
    } else {
      axios.post(`${process.env.REACT_APP_API_URL}/api/usuarios`, formData, {
        headers: { Authorization: `Bearer ${token}` },
      })
        .then(() => {
          setSuccessMessage('Usuário adicionado com sucesso.');
          setShowSnackbar(true);
          handleCloseModal();
          loadUsuarios(); // Atualiza a lista de usuários após adição
        })
        .catch((error) => {
          console.error('Erro ao adicionar usuário:', error.response?.data || error.message);
          setErrorMessage(error.response?.data?.message || 'Erro ao adicionar usuário.');
        });
    }
  };
  

  // Deletar usuário e atualizar a lista
  const handleDeleteUsuario = (id) => {
    console.log("ID do usuário a ser deletado:", id);  // Verifique se este ID está correto
    const token = localStorage.getItem('token');
    axios.delete(`${process.env.REACT_APP_API_URL}/api/usuarios/${id}`, {
      headers: { Authorization: `Bearer ${token}` }
    })
    .then(() => {
      setSuccessMessage('Usuário deletado com sucesso.');
      setShowSnackbar(true);
      loadUsuarios(); // Chama a função de carregamento após a exclusão
    })
    .catch((error) => {
      console.error('Erro ao deletar usuário:', error);  // Exibir erro completo no console
      setErrorMessage('Erro ao deletar usuário.');
      setShowSnackbar(true);
    });
  };
  

  const handleCloseSnackbar = () => {
    setShowSnackbar(false);
    setErrorMessage('');  // Limpa a mensagem de erro após fechar o Snackbar
    setSuccessMessage(''); // Limpa a mensagem de sucesso após fechar o Snackbar
  };

  return (
    <Box>
       <Typography
              variant="h6"
              sx={{
                mb: 2,
                fontWeight: 'bold',
                color: '#6a11cb',
                textAlign: 'left',
              }}
            >
           
            Usuarios Permissões
          </Typography>
      <Button
        variant="contained"
        color="primary"
        startIcon={<AddIcon />}
        onClick={() => handleOpenModal('add')}
        sx={{ marginBottom: 2 }}
      >
        Adicionar Usuário
      </Button>

      <Table>
        <TableHead>
          <TableRow>
            <TableCell>Nome</TableCell>
            <TableCell>Email</TableCell>
            <TableCell>Tipo de Usuário</TableCell>
            <TableCell>Ações</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {usuarios.map((usuario) => (
            <TableRow key={usuario.id}>
              <TableCell>{usuario.nome}</TableCell>
              <TableCell>{usuario.email}</TableCell>
              <TableCell>{usuario.tipo_usuario}</TableCell>
              <TableCell>
                <Tooltip title="Editar">
                  <IconButton color="primary" onClick={() => handleOpenModal('edit', usuario)}>
                    <EditIcon />
                  </IconButton>
                </Tooltip>
                <Tooltip title="Deletar">
                  <IconButton color="error" onClick={() => handleDeleteUsuario(usuario.id)}>
                    <DeleteIcon />
                  </IconButton>
                </Tooltip>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>

      {/* Modal */}
      <Modal open={openModal} onClose={handleCloseModal}>
        <Box sx={{
          padding: 4,
          backgroundColor: 'white',
          margin: 'auto',
          width: '50%',
          marginTop: '5%',
          maxHeight: '80vh',
          overflowY: 'auto',
          position: 'relative',
        }}>
          <IconButton sx={{ position: 'absolute', top: 8, right: 8 }} onClick={handleCloseModal}>
            <CloseIcon />
          </IconButton>

          <Typography variant="h6">
            {modalType === 'add' ? 'Adicionar Usuário' : 'Editar Usuário'}
          </Typography>

          {errorMessage && <Alert severity="error">{errorMessage}</Alert>}

          <TextField
            label="Nome"
            fullWidth
            value={usuarioForm.nome}
            onChange={(e) => setUsuarioForm({ ...usuarioForm, nome: e.target.value })}
            sx={{ marginBottom: 2 }}
          />

          <TextField
            label="Email"
            fullWidth
            value={usuarioForm.email}
            disabled={modalType === 'edit'} // E-mail desabilitado no modo de edição
            onChange={(e) => setUsuarioForm({ ...usuarioForm, email: e.target.value })}
            sx={{ marginBottom: 2 }}
          />

          {modalType === 'add' && (
            <TextField
              label="Senha"
              fullWidth
              type="password"
              value={usuarioForm.senha_hash}
              onChange={(e) => setUsuarioForm({ ...usuarioForm, senha_hash: e.target.value })}
              sx={{ marginBottom: 2 }}
            />
          )}

          <TextField
            label="Tipo de Usuário"
            select
            SelectProps={{ native: true }}
            fullWidth
            value={usuarioForm.tipo_usuario}
            onChange={(e) => setUsuarioForm({ ...usuarioForm, tipo_usuario: e.target.value })}
            sx={{ marginBottom: 2 }}
          >
            <option value="admin">Admin</option>
            <option value="gerente">Gerente</option>
            <option value="usuario">Usuário</option>
          </TextField>

          <Button variant="contained" color="primary" onClick={handleSubmitForm}>
            {modalType === 'add' ? 'Adicionar' : 'Salvar Alterações'}
          </Button>
        </Box>
      </Modal>


      <Snackbar
        open={showSnackbar}
        autoHideDuration={6000}
        onClose={handleCloseSnackbar}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      >
        {successMessage ? (
          <Alert onClose={handleCloseSnackbar} severity="success">{successMessage}</Alert>
        ) : (
          <Alert onClose={handleCloseSnackbar} severity="error">{errorMessage}</Alert>
        )}
      </Snackbar>

       {/* 🔹 Botão para voltar */}
 <Box sx={{ mt: 3 }}>
        <Button variant="contained" color="secondary" onClick={() => navigate(-1)}>
          Voltar
        </Button>
      </Box>
    </Box>
  );
};

export default UsuariosPermissoes;
