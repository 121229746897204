import React, { useMemo, useState, useEffect } from 'react';
import {
  Box,
  Button,
  IconButton,
  Tooltip,
  Snackbar,
  Alert,
  Menu,
  MenuItem,
  Typography,
  CircularProgress,
} from '@mui/material';
import { MaterialReactTable } from 'material-react-table';
import {
  Delete,
  Edit,
  SaveAlt,
  Visibility,
} from '@mui/icons-material';
import jsPDF from 'jspdf';
import 'jspdf-autotable';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';

const Clientes = () => {
  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [snackbarSeverity, setSnackbarSeverity] = useState('success');
  const navigate = useNavigate();

  // Função para buscar dados
  const fetchData = async () => {
    try {
      setIsLoading(true);
      const token = localStorage.getItem('token');
      if (!token) {
        console.error('Token não encontrado. Faça login novamente.');
        setIsLoading(false);
        return;
      }

      const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/clientes`, {
        headers: { Authorization: `Bearer ${token}` },
      });

      setData(response.data.clientes || []);
    } catch (error) {
      console.error('Erro ao buscar os dados:', error);
      setSnackbarMessage('Erro ao carregar os dados.');
      setSnackbarSeverity('error');
      setSnackbarOpen(true);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchData(); // Carrega os dados ao montar o componente
  }, []);

  // Função para deletar cliente
  const handleDelete = async (clienteId) => {
    try {
      const token = localStorage.getItem('token');
      if (!token) {
        setSnackbarMessage('Token não encontrado. Faça login novamente.');
        setSnackbarSeverity('error');
        setSnackbarOpen(true);
        return;
      }

      await axios.delete(`${process.env.REACT_APP_API_URL}/api/clientes/${clienteId}`, {
        headers: { Authorization: `Bearer ${token}` },
      });

      setData((prevData) => prevData.filter((cliente) => cliente.id !== clienteId));
      setSnackbarMessage('Cliente removido com sucesso!');
      setSnackbarSeverity('success');
      setSnackbarOpen(true);
    } catch (error) {
      setSnackbarMessage('Erro ao remover cliente.');
      setSnackbarSeverity('error');
      setSnackbarOpen(true);
    }
  };

  // Exportação para CSV
  const exportCSV = () => {
    const csvData = data.map((cliente) => ({
      Nome: cliente.nome,
      Email: cliente.email,
      Telefone: cliente.telefone,
      Status: cliente.status,
    }));

    const csvString = [
      ['Nome', 'Email', 'Telefone', 'Status'],
      ...csvData.map((row) => [row.Nome, row.Email, row.Telefone, row.Status]),
    ]
      .map((e) => e.join(','))
      .join('\n');

    const blob = new Blob([csvString], { type: 'text/csv;charset=utf-8;' });
    const link = document.createElement('a');
    link.href = URL.createObjectURL(blob);
    link.download = 'clientes.csv';
    link.click();
  };

  // Exportação para PDF
  const exportPDF = () => {
    const doc = new jsPDF();
    doc.text('Lista de Clientes', 20, 10);
    doc.autoTable({
      head: [['Nome', 'Email', 'Telefone', 'Status']],
      body: data.map((cliente) => [cliente.nome, cliente.email, cliente.telefone, cliente.status]),
    });
    doc.save('clientes.pdf');
  };

  const columns = useMemo(() => [
    { accessorKey: 'nome', header: 'Nome' },
    { accessorKey: 'email', header: 'E-mail' },
    { accessorKey: 'telefone', header: 'Telefone' },
    {
      header: 'Ações',
      size: 150,
      Cell: ({ row }) => (
        <Box sx={{ display: 'flex', justifyContent: 'flex-end', gap: '8px' }}>
          <Tooltip title="Visualizar">
           
          </Tooltip>
          <Tooltip title="Editar">
            <IconButton
              color="primary"
              onClick={() => navigate(`/EditarCliente/${row.original.id}`)}
            >
              <Edit />
            </IconButton>
          </Tooltip>
          <Tooltip title="Remover">
            <IconButton color="error" onClick={() => handleDelete(row.original.id)}>
              <Delete />
            </IconButton>
          </Tooltip>
        </Box>
      ),
    },
  ], [navigate]);

  return (
    <Box sx={{ minHeight: '100vh', width: '100%' }}>
      {/* Cabeçalho */}
      <Typography
        variant="h6"
        sx={{
          mb: 2,
          fontWeight: 'bold',
          color: '#6a11cb',
          textAlign: 'left',
        }}
      >
        Gestão de Clientes
      </Typography>

      {/* Barra de ações */}
      <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 3 }}>
        <Button
          variant="contained"
          color="success"
          onClick={() => navigate('/NovoCliente')}
          sx={{
            backgroundColor: '#4caf50',
            ':hover': { backgroundColor: '#45a045' },
            textTransform: 'none',
          }}
        >
          Adicionar Cliente
        </Button>
        <Box sx={{ display: 'flex', gap: '8px' }}>
          <Button variant="contained" color="info" onClick={exportCSV} startIcon={<SaveAlt />}>
            Exportar CSV
          </Button>
          <Button variant="contained" color="secondary" onClick={exportPDF} startIcon={<SaveAlt />}>
            Exportar PDF
          </Button>
        </Box>
      </Box>

      {/* Tabela com paginação de 150 resultados por página */}
      <Box
        sx={{
          borderRadius: '8px',
          boxShadow: '0 4px 16px rgba(0, 0, 0, 0.2)',
        }}
      >
        <MaterialReactTable
          columns={columns}
          data={data}
          enableGlobalFilter
          enableColumnFilters
          enableSorting
          enablePagination // Ativa a paginação
          initialState={{
            pagination: { pageSize: 150 }, // Define o número de resultados por página
          }}
          localization={{
            actions: 'Ações',
            cancel: 'Cancelar',
            save: 'Salvar',
            search: 'Pesquisar',
            searchPlaceholder: 'Digite para pesquisar...',
            showHideColumns: 'Mostrar/Ocultar Colunas',
            noResultsFound: 'Nenhum resultado encontrado',
            rowsPerPage: 'Linhas por página',
            nextPage: 'Próxima página',
            previousPage: 'Página anterior',
          }}
        />
      </Box>

      {/* Indicador de carregamento */}
      {isLoading && (
        <Box sx={{ display: 'flex', justifyContent: 'center', mt: 2 }}>
          <CircularProgress size={24} />
        </Box>
      )}

      {/* Snackbar */}
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={3000}
        onClose={() => setSnackbarOpen(false)}
      >
        <Alert severity={snackbarSeverity}>{snackbarMessage}</Alert>
      </Snackbar>
    </Box>
  );
};

export default Clientes;
